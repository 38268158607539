<div class="theme-container detail-list-container quote-listing-page">
  <div class="page-title">
    <div class="w-100">
      <div class="page-heading-container">
        <h3 class="head-lg heading text-uppercase">SEARCH</h3>
      </div>
      <div class="breadcrum-container">
        <ul class="breadcrum">
          <li class="head-sm">Home</li>
          <li class="head-sm">Search</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row g-0">
    <div class="col-md-12 mt-mobile">
      <div class="theme-card w-100 brdr-bottom">
        <div class="card-content">
          <div class="row d-flex align-items-center">
            <div
              [ngClass]="{ complete: searchText }"
              class="col-sm-10 col-md-8 col-lg-6 form-field mb-0 mb-input-container"
            >
              <label for="quotenumber" class="head-sm">Search By</label>
              <ng-select
                placeholder="Select"
                class="d-sm-none mb-20"
                (change)="selectSearchOption($event)"
              >
                <ng-option
                  *ngFor="let option of searchOptions"
                  [value]="option"
                >
                  {{ option }}
                </ng-option>
              </ng-select>
              <div class="input-group">
                <div
                  class="input-group-prepend rupee-refer-btn d-none d-sm-flex"
                >
                  <button
                    class="form-control dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{
                      slctdSearchElem
                        ? slctdSearchElem
                        : ("commonText.select.placeholder" | translate)
                    }}
                  </button>
                  <ul class="dropdown-menu">
                    <li *ngFor="let option of searchOptions">
                      <a
                        class="dropdown-item"
                        (click)="selectSearchOption(option)"
                      >
                        {{ option }}
                      </a>
                    </li>
                  </ul>
                </div>
                <input
                  class="form-control mob-placeholder-align mb-0"
                  id=""
                  placeholder="Search"
                  [(ngModel)]="searchText"
                  aria-label="Input group"
                  (input)="applyFilter()"
                  type="text"
                />
              </div>
            </div>

            <div class="col-md-4 mt-5">
              <button
                type="button"
                class="btn sm primary-btn theme-lg-btn mr-5 active"
                (click)="applyFilter()"
              >
                {{ "commonText.search" | translate }}
              </button>
              <button
                type="button"
                class="btn sm primary-btn theme-lg-btn mr-0"
                (click)="reset()"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form class="theme-form">
    <div class="row">
      <div class="col-md-12 d-flex-container pull-right align-items-center mt-5">
        <button type="button" class="pull-right quotelist-btn btn quotelist-btn mb-0">
          <svg class="svg-xs">
            <use xlink:href="#ICON_Download" />
          </svg>
        </button>
        <div class="head-sm mb-0">
          <!-- *ngIf="checklist.length != 0" -->
          <button
            type="button"
            class="btn pull-right quotelist-btn p-3 grid-filter d-none d-md-flex grid-list-filter"
          >
            <svg
              class="svg-size grid-list-button"
              [ngClass]="{ 'icons-active': gridItem }"
              (click)="grid()"
            >
              <use xlink:href="#ICON_Grid" />
            </svg>
            <svg class="svg-size svg-three-dots">
              <use xlink:href="#ICON_Three_Dots" />
            </svg>
            <svg
              class="svg-size grid-list-button"
              [ngClass]="{ 'icons-active': listItem }"
              (click)="list()"
            >
              <use xlink:href="#ICON_List" />
            </svg>
          </button>
        </div>
      </div>
      <div class="col-md-12" *ngIf="checklist?.length > 0">
        <!-- Original Card UI -->
        <div
          class="grid premium-card-container quote-list-card mt-0 mt-md-5"
          [ngClass]="{ 'd-md-block': gridItem, 'd-md-none': !gridItem }"
        >
          <div class="row pt-5">
            <div
              class="col-md-6 col-lg-4 col-xl-3 mb-4"
              *ngFor="
                let item of checklist
                  | paginate
                    : {
                        itemsPerPage: itemsPerPageGrid,
                        currentPage: page,
                        id: 'pagination1'
                      }
              "
            >
              <div class="tata-card-container">
                <label class="tata-card" for="{{ item.id }}">
                  <div class="tata-card-header">
                    <div class="header-title">
                      {{ item.product }}
                    </div>
                  </div>
                  <div class="tata-card-body">
                    <div class="premium-section">
                      <label class="premium-label">Base Quote premium</label>
                      <span class="premium-value">
                        <i class="fa fa-inr"></i
                        >{{ item.base_quote_premium }}</span
                      >
                    </div>
                    <div class="info-section">
                      <label class="theme-value">
                        Quote Id<span class="theme-label">{{
                          item.quote_id
                        }}</span></label
                      >
                      <label class="theme-value">
                        Client Name<span class="theme-label">{{
                          item.client_name
                        }}</span></label
                      >
                      <label class="theme-value"
                        >Status<span class="theme-label">{{
                          item.status
                        }}</span></label
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

       <table
          mat-table
          [dataSource]="
            checklist
              | paginate
                : {
                    itemsPerPage: itemsPerPageList,
                    currentPage: page,
                    id: 'pagination2'
                  }
          "
          class="preview-details border-bottom w-100 card-top-spacing d-none"
          [ngClass]="{ 'd-md-table': listItem }"
          multiTemplateDataRows
        >
          <div matColumnDef="quoteId">
            <th mat-header-cell *matHeaderCellDef>
              <span class="theme-label">Quote Id</span>
            </th>
            <td mat-cell *matCellDef="let element" class="brdr-none">
              <span class="theme-value">{{ element.quote_id || "-" }}</span>
            </td>
          </div>
          <div matColumnDef="product">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="d-md-table-cell d-none d-sm-table-cell d-sm-none"
            >
              <span class="theme-label">Product</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="brdr-none d-md-table-cell d-none d-sm-none"
            >
              <span class="theme-value">{{ element.product_name || "-" }}</span>
            </td>
          </div>
          <div matColumnDef="clientName">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="d-md-table-cell d-none d-sm-table-cell d-sm-none"
            >
              <span class="theme-label">Client Name</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="brdr-none d-md-table-cell d-none d-sm-none"
            >
              <span class="theme-value">{{ element.client_name || "-" }}</span>
            </td>
          </div>

          <div matColumnDef="BaseQuotePremium">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="d-md-table-cell d-sm-table-cell d-sm-none d-md-none d-lg-table-cell d-none"
            >
              <span class="theme-label">Base Quote Premium</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="brdr-none d-md-table-cell d-sm-table-cell d-sm-none d-md-none d-lg-table-cell d-none"
            >
              <span class="theme-value"
                ><i class="fa fa-inr"></i
                >{{ element.base_quote_premium || "-" }}</span
              >
            </td>
          </div>

          <div matColumnDef="status">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="d-none d-md-table-cell d-md-none d-lg-table-cell"
            >
              <span class="theme-label">Status</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="brdr-none d-md-table-cell d-none d-md-none d-lg-table-cell"
            >
              <span class="theme-value">{{ element.status || "-" }}</span>
            </td>
          </div>

          <div matColumnDef="action">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="d-none d-md-table-cell d-md-none d-lg-table-cell"
            >
              <span class="theme-label">Action</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              class="brdr-none d-md-table-cell d-none d-md-none d-lg-table-cell"
            >
              <span class="theme-value">{{ "" }}</span>
              <button type="button" class="p-2 quotelist-btn btn quotelist-btn mb-0">
                <svg class="svg-xs ml-4">
                <use xlink:href="#ICON_Three_Dots" />
              </svg>
            </button>
            </td>
          </div>

          <div matColumnDef="quicklinks">
            <th mat-header-cell *matHeaderCellDef class="d-xl-none"></th>
            <td
              mat-cell
              *matCellDef="let element"
              class="border-none d-xl-none"
            >
              <button
                (click)="
                  collapseAll(element); element.isExpanded = !element.isExpanded
                "
                class="btn quotelist-btn m-0"
              >
                <svg
                  *ngIf="element.isExpanded"
                  style="height: 12px; width: 12px"
                >
                  <use xlink:href="#ICON_Cross" />
                </svg>
                <svg
                  *ngIf="!element.isExpanded"
                  style="height: 20px; width: 20px"
                >
                  <use xlink:href="#ICON_Eye" />
                </svg>
              </button>
            </td>
          </div>

          <div matColumnDef="expandedDetail" style="margin-bottom: 2rem">
            <td
              mat-cell
              *matCellDef="let element"
              [attr.colspan]="headerList.length"
              class="brdr-none"
            >
              <div
                class="expandable-row-detail"
                *ngIf="element.isExpanded"
                [ngClass]="{
                  'background-expanded': element.isExpanded,
                  'border-display': element.isExpanded
                }"
                [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'"
              >
                <mat-list class="w-100 pl-4 pb-4">
                  <div class="row">
                    <div
                      class="col-12 col-sm-6 col-md-4 col-lg-3 d-lg-none d-md-block"
                    >
                      <div class="theme-label">Base Quote Premium</div>
                      <div class="theme-value">
                        <i class="fa fa-inr"></i
                        >{{ element.base_quote_premium || "-" }}
                      </div>
                    </div>
                    <div
                      class="col-12 col-sm-6 col-md-4 col-lg-3 d-lg-none d-md-block"
                    >
                      <div class="theme-label">Status</div>
                      <div class="theme-value">
                        {{ element.status || "-" }}
                      </div>
                    </div>
                  </div>
                </mat-list>
              </div>
            </td>
          </div>

          <tr
            mat-header-row
            *matHeaderRowDef="headerList"
            class="border-as-bottom"
          ></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: headerList"
            class="border-as-bottom last-border"
            [ngClass]="{ 'brdr-none': element.isExpanded }"
          ></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: ['expandedDetail']"
            class="expandable-row border-none"
            [ngClass]="{ 'd-none': !element.isExpanded }"
          ></tr>
        </table>  
      </div>
    </div>
  </form>
  <div class="row pagination-margin g-0">
    <div [ngClass]="{'card-top-spacing': checklist.length>0}" class="col-md-12">
       <div class="pull-left head-sm mb-0">
          <div class="count-txt m-b-md" *ngIf="checklist.length>0 ">Total Results
             : {{checklist.length}}
          </div>
       </div>
       <div class="pull-right pagination-entries mt-5 mt-md-0 mb-0" *ngIf="checklist.length>0" [ngClass]="{ 'd-md-block': gridItem, 'd-md-none': !gridItem }">
          <pagination-controls class="my-pagination" id="pagination1" (pageChange)="page = $event" [directionLinks]="true"
             maxSize="9" responsive="true" previousLabel="Prev">
          </pagination-controls>
       </div>
       <div class="pull-right pagination-entries mt-0 mb-0 d-none" *ngIf="checklist.length>0" [ngClass]="{ 'd-md-table': listItem }">
          <pagination-controls class="my-pagination" id="pagination2" (pageChange)="page = $event" [directionLinks]="true"
             maxSize="9" responsive="true" previousLabel="Prev">
          </pagination-controls>
       </div>
    </div>
 </div>
</div>
