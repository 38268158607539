import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  // Set a key-value pair in sessionStorage
  setItem(key: string, value: any): void {
    try {
      sessionStorage.setItem(key, value)
      // sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error storing data in sessionStorage:', error);
    }
  }

  // Get the value associated with a key from sessionStorage
  getItem(key: string): any {
    try {
      const storedValue = sessionStorage.getItem(key);
      // return storedValue ? JSON.parse(storedValue) : null;
      return storedValue ? storedValue : null
    } catch (error) {
      console.error('Error retrieving data from sessionStorage:', error);
      return null;
    }
  }

  // Remove a key-value pair from sessionStorage
  removeItem(key: string): void {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing data from sessionStorage:', error);
    }
  }

  // Clear all key-value pairs from sessionStorage
  clear(): void {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.error('Error clearing sessionStorage:', error);
    }
  }
}
