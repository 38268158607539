import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icons',
  templateUrl: './svg-icons.component.html',
  styleUrls: ['./svg-icons.component.sass']
})
export class SvgIconsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
