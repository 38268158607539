<div class="theme-conatiner">
  <div class="page-title">
    <div class="nav-btn" (click)="back()">
      <svg>
        <use xlink:href="#ICON_Arrow" />
      </svg>
    </div>
    <div class="w-100">
      <div class="page-heading-container">
        <h3 class="head-lg heading">
          {{ "bulkUpload.BulkUpload.label" | translate }}
        </h3>
      </div>
      <div class="breadcrum-container">
        <ul class="breadcrum">
          <li class="head-sm" (click)="globals.goBackToAllApplication()">
            {{ "bulkUpload.home.label" | translate }}
          </li>
          <li class="head-sm" (click)="back()">{{ "bulkUpload.usrmngt.label" | translate }}</li>
          <li class="head-sm">
            {{ "bulkUpload.BulkUpload.label" | translate }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Form Start -->
  <form [formGroup]="documentUpload" class="theme-form mt-mobile">
    <div class="theme-card w-100 p-0 card-top-spacing">
      <div class="card-content has-form">
        <div class="row">
          <div class="form-field col-md-6 col-lg-4 col-xl-3">
            <div
              formGroupName="documentName"
              class="custom-file upload-visibility"
            >
              <input
                type="file"
                accept=".csv,.xls,.xlsx"
                (change)="onFileChange($event)"
                class="custom-file-input d-none"
                id="customFile"
                (click)="$event.target.value=null"
              />
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <label for="customFile" class="docIcon">
                    <svg class="svg-md">
                      <use xlink:href="#ICON_Doc" />
                    </svg>
                  </label>
                  <div
                    [ngClass]="{ 'd-none': loading }"
                    class="preview-details ml-2"
                  >
                    <label
                      for="customFile"
                      *ngIf="!documentUpload.get('documentName').value"
                      class="fileUploadLabel theme-value d-block required-field"
                    >
                      {{ "bulkUpload.Upload.label" | translate }}
                    </label>
                    <label
                      for="customFile"
                      *ngIf="!documentUpload.get('documentName').value"
                      class="theme-label d-block"
                    >
                      {{ "bulkUpload.fileFormat.label" | translate }}
                    </label>

                    <label
                      *ngIf="documentUpload.get('documentName').value"
                      class="mt-10 fileUploadLabel theme-value"
                      for="customFile"
                    >
                      <div class="text-truncate doc-name">
                        {{ documentUpload.get("documentName").value }}
                      </div>
                    </label>
                  </div>
                  <div class="w-100" *ngIf="loading">
                    <div class="w-100 d-flex justify-content-center head-sm">
                      <div class="fa-3x">
                        <i class="fa fa-spinner fa-spin"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <label
                  *ngIf="!documentUpload.get('documentName').value"
                  for="customFile"
                  class="uploadBtn"
                >
                  <div class="btn quotelist-btn">
                    <svg class="svg-xxs rotate">
                      <use xlink:href="#ICON_Download_new" />
                    </svg>
                  </div>
                </label>
                <label
                  *ngIf="documentUpload.get('documentName').value"
                  for="customFile"
                  class="uploadBtn"
                >
                  <button (click)="removeDocFile()" class="btn quotelist-btn m-0">
                    <svg class="svg-xxs">
                      <use xlink:href="#ICON_Cross" />
                    </svg>
                  </button>
                </label>
              </div>
            </div>
            <span
              *ngIf="
                documentUpload.get('documentName').errors?.required &&
                documentUpload.get('documentName')?.touched
              "
              class="documentGroup error-msg"
              style="position: relative !important"
            >
              Required
            </span>
          </div>
          <div class="col-md-6 col-lg-8 col-xl-9">
            <div class="d-flex justify-content-end">
              <button
                class="btn primary-btn"
                (click)="BulkUserJSON(filePartialUploadModal)"
                [ngClass]="{ active: documentUpload.valid }"
              >
                {{ "bulkUpload.submit.btn" | translate }}
              </button>
              <button class="btn primary-btn">
                <a href="./assets/document/Bulk_Uploader_Format.xlsx">
                  {{ "bulkUpload.downloadTemplate.label" | translate }}
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- Form End -->
</div>

<!-- save modal start -->
<ng-template #filePartialUploadModal>
  <div class="modal-container">
    <div class="detail-container-modal modal-sm success-info quote-shared">
      <div class="modal-header">
        <div class="close-icon" (click)="filePartialUploadModalRef.hide();">
          <svg>
            <use xlink:href="#ICON_Cross" />
          </svg>
        </div>
      </div>
      <div class="modal-body no-cross-modal-body">
        <div class="row">
          <div class="text-center col-md-12 mb-4">
            <div class="sent-img">
              <img
                alt=""
                class="img-fluid w-20"
                src="assets/images/file-uploaded-partially.svg"
              />
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-center flex-column text-center">
            <span class="section-heading"> File Upload Successfully </span>
            <p class="theme-label">Uploaded file has <span class="error-msg font-weight-bold">{{ wrongCount }}</span> wrong and <span class="font-weight-bold text-success">{{successfulCount}}</span> successful entries. Please download the excel file with wrong entries, correct the data and again upload.</p>
          </div>
          <div class="col-md-12 text-center">
            <div class="row">
              <div class="col-md-12 text-center mt-30">
                <button
                  (click)="exportToExcel()"
                  type="submit"
                  class="btn sm theme-sm-btn primary-btn theme-lg-btn active"
                >
                  Download Excel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- contact details modal end -->
