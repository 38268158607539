<div class="theme-container">
	<!-- Page header start -->
	<div class="page-title">
		<div class="nav-btn" [routerLink]="['/allapplication']">
			<svg>
				<use xlink:href="#ICON_Arrow" />
			</svg>
		</div>
		<div class="w-100">
			<div class="page-heading-container">
				<h3 class="head-lg heading">
					{{ "userManagement.title" | translate }}
				</h3>
			</div>
			<div class="breadcrum-container">
				<ul class="breadcrum">
					<li class="head-sm" (click)="globals.goBackToAllApplication()">
						{{ "allApplication.allProducts.label" | translate }}
					</li>
					<li class="head-sm">
						{{ "userManagement.title" | translate }}
					</li>
				</ul>
			</div>
		</div>
	</div>
	<!-- Page header end -->

	<div class="quote-listing-page mt-mobile">
		<div class="theme-card">
			<div class="card-content has-form">
				<div class="row">
					<div class="col-12">
						<span class="pull-right"><button type="button" class="btn link-btn" [routerLink]="['/bulkUpload']">Bulk Upload</button></span>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-md-6 col-lg-8 form-field mb-0">
						<label for="quotenumber" class="head-sm">
							{{ "commonText.search" | translate }}
						</label>
						<span class="info-icon premium-tooltip premium-info-icon">
							<span class="tooltiptext">
								{{ "userManagement.search.placeholder" | translate }}</span
							>
							<svg>
								<use xlink:href="#ICON_Info" />
							</svg>
						</span>
						<input
							class="form-control mob-placeholder-align"
							id=""
							placeholder="Search"
							name="search"
							[(ngModel)]="term"
							aria-label="Input group"
							type="text"
						/>
						<!-- <label class="search-in-input input-search-icon ql-search-icon">
							<svg class="search-si-icon">
								<use xlink:href="#ICON_Search_Blue" />
							</svg>
						</label> -->
					</div>
					<div class="col-12 col-md-6 col-lg-4 form-field">
						<label class="invisible head-sm">
							{{ "userManagement.addUser.label" | translate }}
						</label>
						<div class="d-flex justify-content-end">
							<button
								type="button"
								class="btn primary-btn theme-lg-btn mr-20"
								[routerLink]="['/createsubproducer']"
							>
								{{ "userManagement.addUser.label" | translate }}
							</button>
							<div class="dropdown d-flex">
								<button
									class="btn quotelist-btn mb-4 mb-sm-0"
									id="dropdownMenuButton"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<svg height="16" width="16">
										<use xlink:href="#ICON_Three_dots" />
									</svg>
								</button>
								<ul
									class="dropdown-menu dropdown-menu-right"
									aria-labelledby="dropdownMenuButton"
								>
									<li class="brdr-bottom">
										<a class="dropdown-item" (click)="thisFileUpload()">
											<input
												type="file"
												id="file"
												style="display: none"
												#fileImportInput
												name="File Upload"
												id="csvFileUpload"
												(change)="fileChangeListener($event)"
											/>
											<span class="pl-2">{{
												"userManagement.revalidateBulkUpload.label" | translate
											}}</span></a
										>
									</li>
									<li>
										<a
											class="dropdown-item qoute-dropdown-li"
											(click)="downloadFile()"
											><span class="pl-2">{{
												"userManagement.uploadFormatRevalidation.label"
													| translate
											}}</span></a
										>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<form class="theme-form w-100">
		<div class="row">
			<ng-container *ngIf="agents.length > 0">
				<div
					class="col-md-6 col-lg-4 col-xl-3"
					*ngFor="
						let obj of agents
							| filter : term
							| paginate
								: { itemsPerPage: 12, currentPage: page, id: 'pagination1' };
						let i = index
					"
				>
					<div class="tata-card-container">
						<input
							type="radio"
							class="radio"
							id="{{ i }}"
							name="silver"
							value="silver"
						/>
						<label class="tata-card no-green-tick" for="{{ i }}">
							<div class="tata-card-header dropdown-section">
								<div>
									<div class="header-title text-capitalize">
										{{ obj.first_name }}{{ obj.last_name }}
									</div>
								</div>
								<div class="toggle-btn mr-25 mr-xs">
									<input
										type="checkbox"
										(click)="
											ChangeStatus(obj.email_id, obj.producer_status, $event)
										"
										[checked]="obj.producer_status === '0' ? true : false"
										(change)="producer_login($event)"
										id="{{ 'silverChoice' + i }}"
										class="switch switch-orange"
									/>
									<span class="s-checkbox"></span>
									<label
										for="{{ 'silverChoice' + i }}"
										class="lbl-on opacity-0"
										>{{ "userManagement.active.label" | translate }}</label
									>
									<label
										for="{{ 'silverChoice' + i }}"
										class="lbl-off opacity-0"
										>{{ "userManagement.Inactive.label" | translate }}</label
									>
								</div>
							</div>
							<div class="tata-card-body">
								<div class="info-section">
									<label class="theme-value"
										>{{ "userManagement.parentCode.label" | translate }}<span
											class="theme-label"
											>{{ parent_code }}</span
										></label
									>
									<label class="theme-value"
										>{{ "userManagement.parentName.label" | translate }}<span
											class="theme-label"
											>{{ parent_name }}</span
										></label
									>
									<label class="theme-value"
										>{{ "userManagement.mobileNumber.label" | translate }}<span
											class="theme-label"
											>{{
												obj.mobile_no === "undefined" ? "" : obj.mobile_no
											}}</span
										></label
									>
									<label class="theme-value"
										>{{ "userManagement.startDate.label" | translate }}<span
											class="theme-label"
											>{{ obj.start_Date }}</span
										></label
									>
									<label class="theme-value"
										>{{ "userManagement.expiresIn.label" | translate }}<span
											class="theme-label"
											>{{ obj.expiredIn }}
											{{ "userManagement.days.label" | translate }}</span
										></label
									>
								</div>

								<div class="info-section btn-section">
									<div
										class="background-status"
										[routerLink]="['/createsubproducer']"
										[queryParams]="obj"
									>
										<button class="btn primary-btn active m-0">
											{{ "userManagement.modify.label" | translate }}
										</button>
									</div>
								</div>
							</div>
						</label>
					</div>
				</div>
			</ng-container>
			<div class="col-12" *ngIf="agents.length == 0">
				<span class="head-sm">{{
					"userManagement.noRecordsFound" | translate
				}}</span>
			</div>
		</div>
	</form>

	<div class="w-100 pagination-margin">
		<div class="pull-left head-sm">
			<div class="count-txt m-b-md">
				Total result(s) found :
				{{
					(agents | filter : term)?.length
						? (agents | filter : term)?.length
						: 0
				}}
			</div>
		</div>
		<div
			class="pull-right pagination-entries d-md-block"
			*ngIf="(agents | filter : term)?.length"
		>
			<pagination-controls
				id="pagination1"
				class="my-pagination"
				responsive="true"
				(pageChange)="page = $event"
			>
			</pagination-controls>
		</div>
	</div>
</div>
