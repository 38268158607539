import { Component } from '@angular/core';
import { SEARCH_NSM } from '../services/constants';
import { BaseServiceService } from '../services/base-service.service';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-search-nsm',
  templateUrl: './search-nsm.component.html',
  styleUrls: ['./search-nsm.component.scss'],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({
          height: "0px",
          minHeight: "0",
        })
      ),
      state(
        "expanded",
        style({
          height: "*",
        })
      ),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class SearchNsmComponent {
  searchText: any;
  slctdSearchElem = undefined;
  nsmData: any[] = [];
  checklist: any[] = [];
  totalResults: number;
  gridItem: boolean = false;
  listItem: boolean = true;
  itemsPerPageGrid: string = "12";
  itemsPerPageList: string = "30";
  page: number = 1;
  headerList: string[] = SEARCH_NSM.headerList;
  searchOptions: string[] = [
    'Quote Id',
    'Product',
    'Client Name',
    'Base Quote Premium',
    'Status',
    'Action'
  ];

  constructor(public service: BaseServiceService){}
  
  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.service.search_nsm().subscribe((response) => {
      if (response.status == 0) {
        this.nsmData = response.data.map(item => ({ ...item, isExpanded: false }));
        this.checklist = [...this.nsmData];
      }   
    });
  }

  selectSearchOption = (param) => {
    this.slctdSearchElem = param;
  };

  focusOutSearch() {
    this.checklist = [];
    if (this.slctdSearchElem != undefined && this.searchText != undefined && this.searchText != '') {
      var filterName = this.searchText;
    //   for (let i = 0; this.checklist[i]; i++) {
    //     this.val = [];

    //     if(this.slctdSearchElem == "Quote Id" && filterName == this.nsmData[i].quote_id){
    //       this.checkListDetails(i, "", "");
    //     }
    //   }
    // } else {
    //   this.checkListDetails("", "", "outSearch");
    // }
  }
}

toggleExpand(element: any) {
  this.checklist.forEach((el) => {
    if (el !== element) {
      el.isExpanded = false;
    }
  });
  element.isExpanded = !element.isExpanded;
}

applyFilter() {
  if (!this.slctdSearchElem || !this.searchText) {
    this.checklist = [...this.nsmData]; 
    this.totalResults = this.checklist.length;
    return;
  }

  let filterValue = this.searchText.trim().toLowerCase();

  this.checklist = this.nsmData.filter(data => {
    switch (this.slctdSearchElem) {
      case 'Quote Id':
        return data.quote_id.toLowerCase().includes(filterValue);
      case 'Product':
        return data.product_name.toLowerCase().includes(filterValue);
      case 'Client Name':
        return data.client_name.toLowerCase().includes(filterValue);
      case 'Base Quote Premium':
        return data.base_quote_premium.toString().toLowerCase().includes(filterValue);
      case 'Status':
        return data.status.toLowerCase().includes(filterValue);
        return false;
      default:
        return false;
    }
  });

  this.totalResults = this.checklist.length;
}


reset() {
  this.searchText = '';
  this.slctdSearchElem = null;
  this.checklist = [...this.nsmData];
  this.totalResults = this.checklist.length;
}

  grid() {
    this.gridItem = true;
    this.listItem = false;
  }
  list() {
    this.listItem = true;
    this.gridItem = false;
  }

  collapseAll(element) {
    this.checklist.forEach((el: any) => {
      if (el.serialnumber != element.serialnumber) el.isExpanded = false;
    });
  }

  

}
