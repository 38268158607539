import { Component, OnInit } from '@angular/core';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-product-panel',
  templateUrl: './product-panel.component.html',
  styleUrls: ['./product-panel.component.sass']
})
export class ProductPanelComponent implements OnInit {
  name = 'Set iframe source';
  url: string = sessionStorage.getItem('product');
  urlSafe: SafeResourceUrl;
  constructor(public sanitizer: DomSanitizer, public router: Router, public service: BaseServiceService) { }
  back() {

  }
  ngOnInit(): void {
    // console.log(this.url);
    if (this.url === null || this.url === undefined || this.url === ''  ){
      sessionStorage.clear();
      this.router.navigateByUrl(`/login`);
    } else {

      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
  }
  navigate(url) {
    this.router.navigateByUrl(`/${url}`);
    if (url === 'login') {
      sessionStorage.clear();
    }
  }
}
