import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: `
    input:not([type=checkbox]):not([type=radio]):not([type=password]):not([readonly]):not(.ng-trim-ignore),
    textarea:not([readonly]):not(.ng-trim-ignore),
    :not([readonly]):not(.ng-trim-ignore)[ngDefaultControl],
    `,
})
export class TrimDirective {
    constructor(private el: ElementRef) { }
    @HostListener('blur')
    onBlur() {
        const value = this.el.nativeElement.value;
        const valueTrim = value.trim();
        if (value !== valueTrim) {
            this.el.nativeElement.value = valueTrim;
        }
    }
}