import { Component, OnInit } from '@angular/core';
import { BaseServiceService } from 'src/app/services/base-service.service';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-new-producer',
  templateUrl: './new-producer.component.html',
  styleUrls: ['./new-producer.component.sass']
})

export class NewProducerComponent implements OnInit {
  //@ViewChild('chekVal', {static: false}) Checkboxvalue: ElementRef;

  userdata: any;
  temp_location: any;
  active_brokers: any;
  temp_lob: any;
  temp_product: any;
  lob: any;

  selectedlocation: any;
  selectedproducts: any;
  dropdownSettings: { singleSelection: boolean; text: string; enableCheckAll: boolean; selectAllText: string; unSelectAllText: string; enableSearchFilter: boolean; classes: string; labelKey: string;badgeShowLimit:number };
  dropdownSettings2: { singleSelection: boolean; text: string; enableCheckAll: boolean; selectAllText: string; unSelectAllText: string; enableSearchFilter: boolean; classes: string; labelKey: string;badgeShowLimit:number }; //jay
  parent_name: string;
  Expiry_Date: any;
  userStatus: any;
  togglevalue: any;
  isShowplanZone: boolean = false;
  all_lob: any = [];
  active_lob: any = [];
  active_lob1: any = [];
  lobBasedProduct: any = [];
  arra: any = [];
  model: any = {};


  parentproductbackup: any;
  check_lob: any;
  matserLocation: any;
  lumastersPayment: any;
  check: any = [];
  active_products: any = [];
  constructor(public globals: Globals ,public service: BaseServiceService, public router: Router, public ActRoute: ActivatedRoute, public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.parent_name = sessionStorage.getItem('first_name');
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select",
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: "Unselect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      labelKey: 'location',
      badgeShowLimit:1
    };
    this.dropdownSettings2 = { //Jay
      singleSelection: false,
      text: "Select",
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: "Unselect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      labelKey: 'product',
      badgeShowLimit:1
    };


    this.ActRoute.queryParams.subscribe(params => {
      console.log(params.product);
      console.log(params.location);
      this.userdata = params;
      console.log(this.userdata.email_id)
     



      this.service.DataEntryUser(this.userdata.email_id).subscribe(response => {
        if (response) {
          console.log(response, 'response');

          this.togglevalue = response.data[0].producer_status;
          var d = new Date()
          var one_day = 1000 * 60 * 60 * 24
          var fetchdates = this.userdata.start_Date
          fetchdates = fetchdates.split("/").reverse().join("/");
          fetchdates = `"${fetchdates}"`
          
          fetchdates = new Date(fetchdates)
          var todate = new Date(fetchdates.setDate(fetchdates.getDate() + 90))

          
          //  divide the time difference of both the dates by no. of milliseconds in a day
          var Result = Math.round(todate.getTime() - d.getTime()) / (one_day);
          
          //  to remove decimals from the result
          this.Expiry_Date = Result.toFixed(0);
          console.log("nms", this.Expiry_Date)


          this.service.singleproducer({ data: sessionStorage.getItem('email') }).subscribe(active_brokers => {
            if (active_brokers.status === 0) {
              this.active_brokers = active_brokers.data;
              console.log(this.active_brokers, ' this.active_brokers');


              /* Jayaraman */

              this.service.getLobs().subscribe(async lob_role => {
                this.all_lob = lob_role.data;


                var lob = active_brokers.data[0].lob.split(',');

                if (lob[0] == '' || lob.length === 0) {
                  this.temp_lob = [];
                  Swal.fire(this.translate.instant("staticTs.oops"), 'this.translate.instant("staticTs.lobMapped")', this.translate.instant("staticTs.error"))

                } else {
                  if (active_brokers.data[0].lob.toLowerCase() == 'all') {
                    this.temp_lob = this.all_lob;
                  } else {
                    this.temp_lob = [];
                    lob.forEach(element => {
                      if (element) {
                        let temp = this.all_lob.filter((obj) => obj.lob_value.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""))
                        this.temp_lob.push(temp[0]);
                      }


                    })
                  }
                  if (this.temp_lob.length > 0) {
                    console.log(response.data[0].lob.split(','), 'lobVal');

                    var lobVal = response.data[0].lob.split(',');


                    if (lobVal[0] == '' || lobVal.length === 0) {
                      Swal.fire(this.translate.instant("staticTs.oops"), `This user has invalid lob marked via excel...${lobVal}`, this.translate.instant("staticTs.error"))

                    } else {
                      if (response.data[0].lob.toLowerCase() == 'all') {
                        this.temp_lob.forEach((obj) => {
                          if (obj) {
                            this.temp_lob.push(Object.assign(obj, { check: true }))
                          }

                        })
                      } else {
                        this.check_lob = [];
                        lobVal.forEach(element => {
                          if (element) {
                            let temp = this.temp_lob.filter((obj) => obj.lob_value.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""));

                            this.check_lob.push(temp[0]);
                            if (element == 'travel' || element == 'lta') {
                              this.isShowplanZone = true;
                            }
                          }
                        });
                        this.check = [];
                        this.check_lob.forEach((obj) => {
                          this.check.push(Object.assign(obj, { check: true }));
                        })
                        this.temp_lob.map(obj1 => this.check.find(ob => ob.id === obj1.id) || obj1);
                      }
                      /************Lob Competed *****Rajesh************* */

                      /************** products ********************/
                      if (this.check.length > 0) {
                        var id = this.check.map((obj) => obj.id);
                        this.service.getLobProduct({ lob_id: id }).subscribe(product_list => {
                          console.log(product_list.data, '##########res pro')
                          this.model.ad_product = [];
                          if (product_list.status === 0) {
                            var temp_act_prod = [];
                            temp_act_prod = product_list.data;
                            var parentSelectedProducts = active_brokers.data[0].product.split(',');
                           
                            if (parentSelectedProducts[0] == '' || parentSelectedProducts.length === 0) {
                              Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.invalidProductMapped"), this.translate.instant("staticTs.error"))

                            } else {
                             
                              if (active_brokers.data[0].product.toLowerCase() == 'all') {
                                this.temp_product = product_list.data;
                              } else {
                                this.temp_product = [];
                                parentSelectedProducts.forEach(element => {
                                  if (element) {
                                    var act_temp = temp_act_prod.filter((obj) => obj.product.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""))
                                    this.temp_product.push(act_temp[0]);
                                  }


                                });

                                this.temp_product = this.temp_product.filter((obj) => obj != undefined)
                                console.log(this.temp_product, '#######tem pro')

                              }
                              var selectedProducts = response.data[0].product.split(',')
                              
                              this.parentproductbackup = this.temp_product;
                              
                              if (response.data[0].product.toLowerCase() == 'all') {

                                this.model.ad_product = this.temp_product;
                              } else {
                                this.model.ad_product = [];
                                selectedProducts.forEach(element => {
                                  if (element) {
                                    let temp = this.temp_product.filter((obj) => obj.product_value.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""));
                                    this.model.ad_product.push(temp[0])
                                  }

                                });
                                this.model.ad_product = this.model.ad_product.filter((obj) => obj != undefined)
                                console.log(this.model.ad_product, '########model pro')
                              }

                            }

                          } else {
                            Swal.fire(this.translate.instant("staticTs.oops"), product_list.txt, this.translate.instant("staticTs.error"));

                          }

                        })
                      } else {
                        console.log(`Invalid lob data`);
                      }
                    }

                  } else {
                    
                    this.temp_lob = [];
                    this.active_products = [];
                    Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.chooseDifferentParent"), this.translate.instant("staticTs.error"))

                  }


                }

              })

              /* jayaramana */
              this.temp_location = active_brokers.data[0].location.split(',');
              console.log(this.temp_location, ' this.temp_location');


              var locationdata = [];
              this.temp_location.forEach((element, index) => {
                if (element) {
                  var obj = {
                    "id": index + 1,
                    "location": element
                  };
                  locationdata.push(obj);
                }
              });
              this.temp_location = locationdata;
              this.temp_product = active_brokers.data[0].product.split(',');

              var productdata = [];
              this.temp_product.forEach((element, index) => {
                if (element) {
                  var obj = {
                    "id": index + 1,
                    "product": element
                  };
                  productdata.push(obj);
                }

              });
              this.temp_product = productdata;


              var selectedlocation = this.userdata.location.split(',');

              var temp_loc = [];
              selectedlocation.forEach((productvalue) => {

                var sample = this.temp_location.filter((obj) => obj.location == productvalue);
                if (sample[0] !== undefined) {
                  temp_loc.push(sample[0]);

                }
              })

              this.selectedlocation = temp_loc;

              var selectedproducts = this.userdata.product.split(',');

              var temp1 = [];
              selectedproducts.forEach((productvalue) => {

                var sample = this.temp_product.filter((obj) => obj.product == productvalue);
                if (sample[0] !== undefined) {
                  temp1.push(sample[0]);

                }
              })

              this.selectedproducts = temp1;


            } else if (active_brokers.status === -114) {
              Swal.fire(this.translate.instant("staticTs.oops"), active_brokers.txt, this.translate.instant("staticTs.error"));
              sessionStorage.clear();
              this.router.navigateByUrl(`/login`);

            } else {
              Swal.fire(this.translate.instant("staticTs.oops"), active_brokers.txt, this.translate.instant("staticTs.error"));
            }
          }, err => {
            Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
          })



        } else if (response.status === -114) {
          Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
          sessionStorage.clear();
          this.router.navigateByUrl(`/login`);

        } else {
          Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
        }
      }, err => {
        Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
      })
    });



    //  Expiry date


  }

  changelocationlob() {


    if (this.selectedlocation === undefined || this.model.ad_product === undefined || this.model.ad_product.length === 0 || this.selectedlocation.length === 0) {
      Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.producerCannotBeEmpty"), this.translate.instant("staticTs.error"))
    } else {
      var product_value = this.model.ad_product.map((obj) => obj.product);
      var location_value = this.selectedlocation.map((obj) => obj.location);

      var temp = [];
      this.temp_lob.forEach(element => {
        if (element.check == true) {
          temp.push(element);

        }
      });

      
      var lob_value = temp.map((obj) => obj.lob_value);
      console.log(lob_value, 'lob_value');

      var data = {
        email_id: this.userdata.email_id,
        product: product_value.join(),
        location: location_value.join(),
        lob: lob_value.join()

      };
      console.log(data);

      this.service.updateleopro(data).subscribe(product_role => {
        if (product_role.status === 0) {
          Swal.fire(this.translate.instant("staticTs.success.swal"), this.translate.instant("staticTs.userUpdated.swal"), this.translate.instant("staticTs.success"))
          this.router.navigateByUrl('/user-mgmt');
        } else {
          Swal.fire(this.translate.instant("staticTs.oops"), product_role.txt, this.translate.instant("staticTs.error"))
        }
      }, err => {
        Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
      })

    }
  }
  /* Jayaraman */
  producer_status(eventVal) {
    console.log(eventVal);

  }

  ModifyCustomer() {
    this.router.navigate(['createsubproducer'], { queryParamsHandling: "preserve" })
  }

  ChangeProdStatus(data, status) {


    if (status == 0) {

      this.service.DeactiveStatus(data).subscribe(res => {
        console.log("readres", res)
      })

    }
    else {

      this.service.ActivateStatus(data).subscribe(res => {
        console.log("readres", res)
      })

    }
  }

  // Jayaraman Change LOB button to Checkbox
  public tempArr = []
  lobSelectChange(event, i, value) {

    console.log(event, i, value);

    var lob_id = value.id;
    var checked = event.target.checked;

    var lobVal = event.target.value;
    if (value.lob_value.toLowerCase() === 'lta' || value.lob_value.toLowerCase() === 'travel') {
      this.isShowplanZone = !value.check;
    }
    if (checked) {
      this.temp_lob[i].check = true;
      this.tempArr.push(lobVal)
      this.tempArr = this.tempArr.filter((test1, index, array) =>
        index === array.findIndex((findTest1) => findTest1 === test1)
      );

    } else {
      this.temp_lob[i].check = false;
      this.tempArr.splice(i, 1);
      this.model.ad_product = this.model.ad_product.filter((objc) => objc.lob_id != lob_id);

    }

    this.setLevel(this.tempArr, value)
  }
  async  setLevel(lobVal, valu) { // Jayaraman .. lob based product Loading temp_lob


    this.service.getLobs().subscribe(lob_role => {
      if (lob_role.status === 0) {
        this.active_lob = lob_role.data;
        this.active_lob1 = lob_role.data; //duplicate 

        this.arra = [];
        var temp = [];




        this.temp_lob.forEach(element => {
          if (element.check == true) {
            temp = this.active_lob.filter((obj) => obj.lob_value == element.lob_value);
            this.arra.push(temp[0]);
          }

        });


       
        this.lobBasedProduct = [];
        for (let lop of this.arra) {
          this.lobBasedProduct.push(lop.id);

        }


        this.service.getLobProduct({ lob_id: this.lobBasedProduct }).subscribe(response => {
          if (response.status === 0) {
            if (response.data == "") {
              this.temp_product = [];
              this.selectedproducts = [];
            } else {
              
              this.temp_product = response.data;
              this.service.singleproducer({ data: sessionStorage.getItem('email') }).subscribe(result => {
                console.log('1135')
                if (result.status === 0) {

                  var data = result.data[0];
                  var resproduct = data.product.split(',');
                  if (resproduct != "" && resproduct != undefined) {


                    var productVal = [];
                    resproduct.forEach(element => {
                      var tem = this.temp_product.filter((obj) => obj.product_value.toLowerCase() == element.toLowerCase());
                      console.log(tem, ' tem---');
                      if (tem.length > 0 && tem != undefined) {
                        productVal.push(tem[0]);
                      }
                    });
                    this.temp_product = productVal;


                  } else {
                    this.temp_product = [];
                  }

                }
              })


              if (this.selectedproducts.length > 0 && this.selectedproducts != undefined && valu != 'test') {
                var temp1 = [];
                this.temp_product.forEach(element => {
                  var fval = this.selectedproducts.filter((obj) => (obj.product_value.replace(/\s/g, '')).toLowerCase() == element.product_value);



                  if (fval.length > 0) {
                    temp1.push(fval[0])

                  }

                });

                this.selectedproducts = [];
                this.selectedproducts = temp1; //temp1[0];//this.amptemp;
              }

            }
          } else {
            Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"))
          }
        }, err => {
          Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
        })


      } else if (lob_role.status === -114) {
        Swal.fire(this.translate.instant("staticTs.oops"), lob_role.txt, this.translate.instant("staticTs.error"));
        sessionStorage.clear();
        this.router.navigateByUrl(`/login`);

      }
      else {
        Swal.fire(this.translate.instant("staticTs.oops"), lob_role.txt, this.translate.instant("staticTs.error"))
      }
    }, err => {
      Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
    })

  }
}
