<!-- <app-left-menu></app-left-menu>
<app-header></app-header> -->
<div class="product-inner-page w-100">
    <div class="theme-container">
        <div class="page-title">
            <div class="nav-btn" [routerLink]="['/user-mgmt']">
                <svg>
                    <use xlink:href="#ICON_Arrow" />
                </svg>
            </div>
            <div class="w-100">
                <div class="page-heading-container">
                    <h3 class="head-lg heading">
                        {{ "newProducer.dataEntryUser.label" | translate}}
                    </h3>
                </div>
                <div class="breadcrum-container">
                    <ul class="breadcrum">
                        <li class="head-sm" (click)="globals.goBackToUserManagement()">
                            {{ "newSubProducer.userManagement.label" | translate }}
                        </li>
                        <li class="head-sm">
                            {{ "newSubProducer.modifyUser.label" | translate }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <form class="theme-form">
            <div class="premium form-field m-n fw-100">
                <div class="premium-card-container">
                    <div class="theme-radio premium-card">
                        <input type="radio" id="silver" name="silver">
                        <label class="premium-lbl" for="silver">
                            <div class="premium-container">
                                <div class="premium-header">
                                    <div class="premium-type fw-100">
                                        <div class="pull-left">
                                            <div>
                                                <span class="f-bold">{{userdata.first_name | uppercase}} </span>
                                                <span class="theme-label m-l-sm">{{ "newProducer.deoUser.label" |
                                                    translate}}</span>
                                            </div>
                                        </div>

                                        <div class="toggle-btn type-2 pull-right">

                                            <input type="checkbox"
                                                (click)="ChangeProdStatus(userdata.email_id,userdata.producer_status)"
                                                [checked]="togglevalue=='0'?true :false" #chekVal
                                                (change)="producer_status($event)" id="silverChoice"
                                                class="switch switch-orange" />
                                            <span class="s-checkbox"></span>
                                            <label for="silverChoice" class="lbl-on">{{ "newProducer.active.label" |
                                                translate}}</label>
                                            <label for="silverChoice" class="lbl-off">{{ "newProducer.inactive.label" |
                                                translate}}</label>
                                        </div>

                                    </div>
                                </div>
                                <div class="premium-body">
                                    <div class="premium-summary">
                                        <div class="premium-list">
                                            <span class="theme-lbl"> {{userdata.parent_code}}
                                                {{parent_name | uppercase}}</span>
                                            <span class="premium-value">
                                                {{userdata.mobile_no}}</span>
                                        </div>
                                        <div class="premium-list ">
                                            <span class="theme-lbl">
                                                <div class="sub-label theme-label-small fw-100">{{
                                                    "newProducer.startDate.label" | translate}}</div>
                                                <div class="fw-100"> {{userdata.start_Date}}</div>
                                            </span>
                                            <span class="premium-value">
                                                <div class="sub-label theme-label-small fw-100">{{
                                                    "newProducer.expiresIn.label" | translate}}</div>
                                                <div class="fw-100">{{Expiry_Date }}{{ "newProducer.days.label" |
                                                    translate}}</div>
                                            </span>
                                        </div>
                                        <div class="text-center"><button type="button"
                                                class="btn primary-btn m-r-sm sm m-b-xs card-btn m-t-sm"
                                                (click)="ModifyCustomer()">{{ "newProducer.modify.label" |
                                                translate}}</button></div>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>

                </div>
            </div>



            <div class="fw-100 transact-box">
                <div class="theme-card w-100 has-form m-t-lg">
                    <label class="head-sm pop-med">{{ "newProducer.lob.label" | translate}}</label>
                    <div class="fw-100  m-b-md">

                        <span class="prod-sel-btn " *ngFor="let key of temp_lob;let i = index">
                            <input type="checkbox" class="button" [id]="key.lob" [value]="key.lob" name="check_{{i}}"
                                [checked]="key.check" (change)="lobSelectChange($event,i,key)"><label
                                for="{{key.lob}}">{{key.lob | uppercase }}</label>

                        </span>


                    </div>

                    <div class="fw-100  box-content">
                        <div class="card-content">
                            <button class="close-icon">
                                <svg>
                                    <use xlink:href="#ICON_Cross" />
                                </svg>
                            </button>
                            <div class="card-head label-indent">{{ "newProducer.motor.label" | translate}}</div>
                            <div class="box-dtls">
                                <div class="fw-100 form-field "
                                    [ngClass]="{'complete': selectedlocation != undefined  && selectedlocation != ''}">
                                    <label class="head-sm">{{ "newProducer.location.label" | translate}}</label>
                                    <div class="fw-100">
                                        <!-- Jayaraman -->
                                        <angular2-multiselect [data]="temp_location" required name='location'
                                            [(ngModel)]="selectedlocation" [settings]="dropdownSettings">
                                            <c-item>
                                                <ng-template let-item="item">
                                                    <label class="multi-lbl"
                                                        style="color: #333;min-width: 150px;">{{item.location |
                                                        uppercase}}</label>
                                                    <img [src]="item.image"
                                                        style="width: 30px; border: 1px solid #efefef;margin-right: 20px;"
                                                        alt="" />

                                                </ng-template>
                                            </c-item>
                                        </angular2-multiselect>


                                    </div>
                                </div>
                                <div class="fw-100 form-field"
                                    [ngClass]="{'complete': model.ad_product != undefined  && model.ad_product != ''}">
                                    <label class="head-sm">{{ "newProducer.product.label" | translate}}</label>
                                    <div class="fw-100">
                                        <!-- jayaraman selectedproducts-->
                                        <angular2-multiselect [data]="temp_product" name='product' required
                                            [(ngModel)]="model.ad_product" [settings]="dropdownSettings2">
                                            <c-item>
                                                <ng-template let-item="item">
                                                    <label class="multi-lbl"
                                                        style="color: #333;min-width: 150px">{{item.product |
                                                        uppercase}}</label>
                                                    <img [src]="item.image"
                                                        style="width: 30px; border: 1px solid #efefef;margin-right: 20px;"
                                                        alt="" />

                                                </ng-template>
                                            </c-item>
                                        </angular2-multiselect>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>

            </div>

            <div class="btn-block">
                <button type="button" class="btn primary-btn theme-lg-btn" (click)="changelocationlob()">{{
                    "newProducer.modify.label" | translate}}</button>
            </div>
        </form>
    </div>
</div>