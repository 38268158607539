<div class="theme-container">
	<!-- Page header start -->
	<div class="page-title">
		<div class="nav-btn" [routerLink]="['/user-mgmt']">
			<svg>
				<use xlink:href="#ICON_Arrow" />
			</svg>
		</div>
		<div class="w-100">
			<div class="page-heading-container">
				<h3 class="head-lg heading">
					{{ "newProducer.dataEntryUser.label" | translate }}
				</h3>
			</div>
			<div class="breadcrum-container">
				<ul class="breadcrum">
					<li class="head-sm" (click)="globals.goBackToAllApplication()">
						{{ "allApplication.allProducts.label" | translate }}
					</li>
					<li class="head-sm" (click)="globals.goBackToUserManagement()">
						{{ "newSubProducer.userManagement.label" | translate }}
					</li>
					<li class="head-sm" *ngIf="editedmode == '' || editedmode == undefined">
						{{ "newSubProducer.addUser.label" | translate }}
					</li>
					<li class="head-sm" *ngIf="editedmode != '' && editedmode != undefined">
						{{ "newSubProducer.modifyUser.label" | translate }}
					</li>
				</ul>
			</div>
		</div>
	</div>
	<!-- Page header end -->
	<form class="theme-form" #f="ngForm">
		<div class="theme-card">
			<div class="card-content">
				<div class="flex-box-100">
					<div class="row">
						<div class="col">
							<div class="section-heading">
								{{
								"newSubProducer.contactDetails.label" | translate
								}}
							</div>
						</div>
					</div>
					<div class="row">
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<div class="head-sm required-field">
								{{ "newSubProducer.emailId.label" | translate }}
							</div>
							<input type="email" class="form-control" name="email" [(ngModel)]="model.ad_email" (blur)="
									email['inputFocusClass'] = model.ad_email
										? true
										: false
								" [pattern]="email_pattern" required #email="ngModel" autocomplete="off" [maxlength]="80" placeholder="{{
									'newSubProducer.emailId.label'
										| translate
								}}" [readonly]="!editable" />
							<span class="error-msg" *ngIf="
									(!email.valid && email.touched) ||
									(submit && !email.valid)
								">{{
								"newSubProducer.properEmail.required" | translate
								}}
							</span>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<div class="head-sm">
								{{ "newSubProducer.mobileNumber.label" | translate }}
							</div>
							<input type="text" class="form-control" minlength="10" maxlength="10" #mobile="ngModel" placeholder="{{
									'newSubProducer.enterMobileNumber.placeholder'
										| translate
								}}" name="mobile" maxLength="10" [(ngModel)]="model.ad_mobile" autocomplete="off" (blur)="
									mobile['inputFocusClass'] = model.ad_mobile
										? true
										: false
								" (keypress)="globals.isNumberKey($event)"
								 (keyup) = "mobilecheck($event)"/>

								<span class="error-msg" *ngIf="startingNo">
									{{ "newSubProducer.properMobileNumber.required" | translate }}
								</span>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label class="head-sm required-field">
								{{ "newSubProducer.revalidation.label" | translate }}
							</label>
							<div class="radio-container">
								<div class="theme-radio radio-btn">
									<input type="radio" class="button" id="Active" value="Active" name="Revalidation"
										[(ngModel)]="model.ad_Revalidation" #Revalidation="ngModel" />
									<label for="Active">{{
										"newSubProducer.active.label" | translate
										}}
										<span class="lbl"></span>
									</label>
								</div>
								<div class="theme-radio radio-btn">
									<input type="radio" class="button" id="Inactive" value="Inactive" name="Revalidation"
										[(ngModel)]="model.ad_Revalidation" #Revalidation="ngModel" />
									<label for="Inactive">{{
										"newSubProducer.inactive.label" | translate
										}}<span class="lbl"></span></label>
								</div>
							</div>
							<span class="error-msg" *ngIf="
									(!Revalidation.valid && Revalidation.touched) ||
									(submit && !Revalidation.valid)
								">{{
								"newSubProducer.revalidationStatus.required"
								| translate
								}}</span>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label for="ifsccode" class="head-sm required-field">
								{{ "newSubProducer.startDate.label" | translate }}
							</label>

							<input class="form-control ngb-cursor-pointer default-ngb-datePicker" placeholder="{{
									'newSubProducer.dateFormat.placeholder'
										| translate
								}}" [minDate]="minDate" name="start" (dateSelect)="findEndDate(model.ad_startDate)" ngbDatepicker
								#d="ngbDatepicker" (click)="d.toggle()" readonly [(ngModel)]="model.ad_startDate" required #start="ngModel"
								autocomplete="off"/>
							<span class="error-msg" *ngIf="
									(!start.valid && start.touched) ||
									(submit && !start.valid)
								">{{
								"newSubProducer.properStartDate.required"
								| translate
								}}</span>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label for="accnumber" class="head-sm required-field">
								{{ "newSubProducer.endDate.label" | translate }}
							</label>

							<input class="form-control ngb-cursor-pointer default-ngb-datePicker" placeholder="{{
									'newSubProducer.dateFormat.placeholder'
										| translate
								}}" [minDate]="model.ad_startDate" [maxDate]="maxDate" name="endDate" readonly ngbDatepicker
								#e="ngbDatepicker" (click)="e.toggle()" readonly [(ngModel)]="model.ad_endDate" required #endDate="ngModel"
								autocomplete="off" />
							<span class="error-msg" *ngIf="
									(!endDate.valid && endDate.touched) ||
									(submit && !endDate.valid)
								">{{
								"newSubProducer.properEndDate.required"
								| translate
								}}</span>
						</div>
					</div>
				</div>
				<div class="flex-box-100">
					<div class="row">
						<div class="col-12">
							<div class="section-heading">
								{{
								"newSubProducer.generalInformation.label"
								| translate
								}}
							</div>
						</div>
					</div>
					<div class="row">
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label class="head-sm required-field">
								{{ "newSubProducer.type.label" | translate }}
							</label>

							<!-- Converted Dropdown as per CR-629 -->
							<ng-select placeholder="{{
									'commonText.select.placeholder'
										| translate
								}}" name="DataEntryUserType" [(ngModel)]="model.ad_DataEntryUserType" required #DataEntryUserType="ngModel"
								(change)="resetTypeDependent()">
								<ng-option value="{{ tt.value }}" *ngFor="let tt of userTypeList">{{ tt.value }}</ng-option>
							</ng-select>

							<!-- <input
								type="text"
								class="form-control"
								placeholder="{{
									'commonText.select.placeholder'
										| translate
								}}"
								name="DataEntryUserType"
								readonly
								[(ngModel)]="model.ad_DataEntryUserType"
								required
								#DataEntryUserType="ngModel"
								autocomplete="off"
							/>

							<span
								class="error-msg"
								*ngIf="
									(!DataEntryUserType.valid &&
										DataEntryUserType.touched) ||
									(submit && !DataEntryUserType.valid)
								"
								>{{
									"newSubProducer.properUserType.required"
										| translate
								}}</span
							> -->
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label for="ifsccode" class="head-sm">
								{{ "newSubProducer.solId.label" | translate }}
							</label>
							<input type="text" class="form-control" value="" name="DealerCode"
								 placeholder="{{
									'newSubProducer.solId.placeholder' | translate
								}}" [(ngModel)]="model.ad_DealerCode" #DealerCode="ngModel" autocomplete="off" (blur)="
									DealerCode['inputFocusClass'] =
										model.ad_DealerCode ? true : false
								" (input)="getDealerDetails($event)" />

							<span class="error-msg" *ngIf="
									(!DealerCode.valid && DealerCode.touched) ||
									(submit && !DealerCode.valid)
								">{{
								"newSubProducer.dealerCode.required" | translate
								}}
							</span>
							<span class="error-msg" *ngIf="errorMsg">{{ errorMsg | translate }}
							</span>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label for="accnumber" class="head-sm" [ngClass]="{'required-field':	model.ad_DataEntryUserType === 'External POSP' }">
								{{
								"newSubProducer.externalPospCode.label"
								| translate
								}}
							</label>
							<input type="text" class="form-control" value="" name="posp" placeholder="{{
									'newSubProducer.externalPosp.placeholder'
										| translate
								}}" [(ngModel)]="model.posp" [required]="
									model.ad_DataEntryUserType === 'External POSP'
								" #posp="ngModel" autocomplete="off" (blur)="
									posp['inputFocusClass'] = model.posp
										? true
										: false
								" [disabled]="( model.posp != undefined || model.posp != '' ) && ( dealerDetails != null || dealerDetails != undefined )" />
							<span class="error-msg" *ngIf="
									(posp.errors?.required && posp.touched) ||
									(submit && posp.errors?.required)
								">{{
								"newSubProducer.properPosp.required" | translate
								}}</span>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label for="accnumber" class="head-sm">
								{{ "newSubProducer.employeeCode.label" | translate }}
							</label>
							<input type="text" class="form-control" value="" (keypress)="omit_special_char($event)"
								[readonly]="!editable" placeholder="{{
									'newSubProducer.employeeCode.placeholder'
										| translate
								}}" name="LoginID" [(ngModel)]="model.ad_LoginID" #LoginID="ngModel" autocomplete="off" (blur)="
									LoginID['inputFocusClass'] = model.ad_LoginID
										? true
										: false
								" />
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label for="domain_id" class="head-sm">
								{{ "newSubProducer.domainId.label" | translate }}
							</label>
							<input type="text" name="domain_id" id="domain_id" class="form-control" value="" readonly placeholder="{{ 'newSubProducer.domainId.label' | translate}}"
								[(ngModel)]="model.ad_DomainId" #DomainId="ngModel" autocomplete="off" />
							</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label class="head-sm required-field">
								{{
								"newSubProducer.producerStatus.label" | translate
								}}
							</label>
							<ng-select placeholder="" name="pro_status" [readonly]="true" [(ngModel)]="model.ad_ProStatus" required
								#ProStatus="ngModel">
								<ng-option value="0">{{
									"newSubProducer.active.label" | translate
									}}</ng-option>
								<ng-option value="1">{{
									"newSubProducer.inactive.label" | translate
									}}</ng-option>
							</ng-select>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label class="head-sm" [ngClass]="{'required-field':	model.ad_DataEntryUserType === 'External POSP' }">
								{{ "newSubProducer.pospAadhar.label" | translate }}
							</label>
							<input type="text" class="form-control" minlength="12" maxlength="12" #psopaadhar="ngModel" placeholder="{{
									'newSubProducer.pospAdharNumber.placeholder'
										| translate
								}}" name="psopaadhar" [(ngModel)]="model.posp_aadhaar"
								oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1')" [required]="
									model.ad_DataEntryUserType == 'External POSP' &&
									(model.posp_pan == undefined ||
										model.posp_pan == '' ||
										model.posp_pan == null)
								" autocomplete="off" (blur)="
									psopaadhar['inputFocusClass'] = model.posp_aadhaar
										? true
										: false
								" [disabled]="( model.posp_aadhaar != undefined || model.posp_aadhaar != '') && ( dealerDetails != null || dealerDetails != undefined )" />
							<span class="error-msg" *ngIf="
									(psopaadhar.errors?.required && psopaadhar.touched) ||
									(submit && psopaadhar.errors?.required)
								">{{
								"newSubProducer.validAadhar.required" | translate
								}}</span>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label class="head-sm" [ngClass]="{'required-field':	model.ad_DataEntryUserType === 'External POSP' }">
								{{ "newSubProducer.pospPan.label" | translate }}
							</label>
							<input type="text" class="form-control" minlength="10" maxlength="10"
								pattern="^[A-Z]{5}[0-9]{4}[A-Z]{1}" #psoppan="ngModel" autocomplete="off" name="psoppan" placeholder="{{
									'newSubProducer.panNumber.placeholder' | translate
								}}" [(ngModel)]="model.posp_pan" oninput="this.value = this.value.toUpperCase()" (blur)="
									psoppan['inputFocusClass'] = model.posp_pan
										? true
										: false
								" [required]="
									model.ad_DataEntryUserType == 'External POSP' &&
									(model.posp_aadhaar == undefined ||
										model.posp_aadhaar == '' ||
										model.posp_aadhaar == null)
								" [disabled]="( model.posp_pan != undefined || model.posp_pan != '' ) && ( dealerDetails != null || dealerDetails != undefined )" />
							<span class="error-msg" *ngIf="
									(psoppan.errors?.required && psoppan.touched) ||
									(submit && psoppan.errors?.required)
								">{{
								"newSubProducer.validPan.required" | translate
								}}</span>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label for="prName" class="head-sm required-field">
								{{
								"newSubProducer.dataEntryUsername.label"
								| translate
								}}
							</label>
							<input type="text" type="text" class="form-control" id="prName" placeholder="{{
									'newSubProducer.enterProducerName.placeholder'
										| translate
								}}" [(ngModel)]="model.ad_prName" name="prName" required #prName="ngModel" autocomplete="off" (blur)="
									prName['inputFocusClass'] = model.ad_prName
										? true
										: false
								" [disabled]="( model.ad_prName != undefined || model.ad_prName != '' ) && ( dealerDetails != null || dealerDetails != undefined )" />
							<span class="error-msg" *ngIf="
									(prName.errors?.required && prName.touched) ||
									(submit && prName.errors?.required)
								">{{
								"newSubProducer.properProducerName.required"
								| translate
								}}</span>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label for="accnumber" class="head-sm">
								{{ "newSubProducer.parentCode.label" | translate }}
							</label>
							<input type="text" class="form-control" [value]="parent_code" readonly />
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label for="transType" class="head-sm required-field">
								{{
								"newSubProducer.transactionType.label" | translate
								}}
							</label>
							<ng-select placeholder="{{
									'commonText.select.placeholder' | translate
								}}" id="transType" name="transType" [(ngModel)]="model.ad_transType" required #transType="ngModel"
								autocomplete="off">
								<ng-option value="All">{{
									"newSubProducer.all.label" | translate
									}}</ng-option>
								<ng-option value="New">{{
									"newSubProducer.new.label" | translate
									}}</ng-option>
								<ng-option value="Rollover">{{
									"newSubProducer.rollover.label" | translate
									}}</ng-option>
								<ng-option value="Renewal">{{
									"newSubProducer.renewal.label" | translate
									}}</ng-option>
							</ng-select>
							<span class="error-msg" *ngIf="
									(!transType.valid && transType.touched) ||
									(submit && !transType.valid)
								">{{
								"newSubProducer.properTransactionType.required"
								| translate
								}}</span>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label for="location" class="head-sm">
								<span class="required-field">{{ "newSubProducer.location.label" | translate }}</span>
								<span *ngIf="model.ad_Location?.length>0">({{model.ad_Location?.length}})</span>
								<span *ngIf="model.ad_Location?.length>0 " class="info-icon premium-tooltip premium-info-icon mr-1">
									<span class="tooltiptext">
										<span *ngFor="let item of model?.ad_Location">
											<span *ngIf="item !== undefined">{{item.location}},&nbsp;</span>
										</span>
									</span>
									<svg>
										<use xlink:href="#ICON_Info" />
									</svg>
								</span>
							</label>

							<ng-multiselect-dropdown class="dd-multi-select ngSelectOption 1" [placeholder]="'Select'"
								[settings]="dropdownSettings" [ngModelOptions]="{ standalone: true }" ngDefaultControl
								[data]="temp_location" [(ngModel)]="model.ad_Location" id="location" required #location="ngModel"
								[disabled]="model.ad_Location && dealerDetails != null" (focusout)="validateLocation()">
							</ng-multiselect-dropdown>
							<span class="error-msg" *ngIf="locationErrorMsg">{{"newSubProducer.location.required" | translate}}</span>
						</div>
						<div class="form-field col-md-6 col-lg-4 col-xl-3">
							<label class="head-sm required-field">{{
								"searchComponent.status.label" | translate
								}}</label>
							<ng-select placeholder="{{
									'commonText.select.placeholder' | translate
								}}" name="deo_status" [(ngModel)]="model.ad_deo_status" required #status="ngModel"
								[disabled]="model.ad_deo_status && dealerDetails != null">
								<ng-option value="Active">{{
									"newSubProducer.active.label" | translate
									}}</ng-option>
								<ng-option value="Inactive">{{
									"newSubProducer.inactive.label" | translate
									}}</ng-option>
							</ng-select>
							<span class="error-msg" *ngIf="
									(!status.valid && status.touched) ||
									(submit && !status.valid)
								">{{
								"newSubProducer.status.required" | translate
								}}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="theme-card">
			<div class="card-content">
				<div class="row">
					<div class="col-12">
						<div class="section-heading">{{
							"newProducer.lob.label" | translate
							}}</div>
					</div>
				</div>
				<div class="col-12" *ngFor="let key of temp_lob; let i = index">
					<div id="accordion_{{i}}">
            <div class="card accordian-container">
              <div class="w-100 accordion-heading mt-0" id="heading_{{i}}">
                <label class="up-icon-label" >
									<div class="check-default mb-0">
										<input type="checkbox" [id]="key.lob" [value]="key.lob" name="check_{{ i }}" class="checkbox" 
											[checked]="key.check" (change)="lobSelectChange($event, i, key)" /><label for="{{ key.lob }}">{{
											key.lob | uppercase
											}}</label>
									</div>
                </label>
								
								<button class="pull-right btn quotelist-btn up-icon mr-0 accordion-btn invisible" data-bs-toggle="collapse"
								[attr.data-bs-target]="'#collapse_'+ i" aria-expanded="false" [attr.aria-controls]="'collapse_'+ i" [attr.id]="'accordionBtn'+ i">
									<svg class="svg-xs icon-primary up-arrow">
										<use xlink:href="#ICON_Down-arrow" />
									</svg>
								</button>
									<button class="pull-right btn quotelist-btn mr-0 accordion-btn " (click)="toggleAccordian(i, true)" *ngIf="key.check">
										<svg class="svg-xs icon-primary up-arrow">
											<use xlink:href="#ICON_Down-arrow" />
										</svg>
									</button>

							
              </div>

              <div id="collapse_{{i}}" class="collapse accordion-body mb-4" aria-labelledby="heading_{{i}}"
                [attr.data-bs-parent]="'#accordion_'+ i ">

								<div class="row pl-input-container">
									<ng-container *ngFor="let product of temp_product; let j = index">
											<div class="col-12" *ngIf="j===0">
												<div class="section-sub-heading">
													Products
												</div>
											</div>
											<ng-container >
												<div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-15" *ngIf="checkProduct(product, key)">
													<div class="check-default mb-0" >
														<input class="checkbox" type="checkbox" [id]="'product' + product.id" name="product{{product.id}}" [value]="product.product" [checked]="product.checked" (change)="OnItemSelectPro($event, j, product)">
														<label for="product{{ product.id }}">	{{
															product.product
														}}</label>
													</div>
												</div>
											</ng-container>
									</ng-container>
								</div>


								<div class="row pl-input-container" *ngIf="(key.lob_value.toLowerCase() === 'lta' || key.lob_value.toLowerCase() === 'travel')">
									<ng-container *ngFor="let plan of plan_data; let k = index">
										<div class="col-12" *ngIf="k === 0">
											<div class="section-sub-heading" >
												Plans
											</div>
										</div>
										<div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-15" *ngIf="checkPlans(plan, key)">
											<div class="check-default mb-0">
												<input type="checkbox" class="checkbox" [id]="'plan'+ plan.id" name="plan{{plan.id}}" [checked]="plan.checked" [value]="plan.prod_plan" (change)="OnItemSelectPlan($event, k, plan)" />
												<label for="plan{{plan.id}}">
													{{plan.prod_plan}}
												</label>
											</div>
										</div>
									</ng-container>
									
									<ng-container *ngFor="let zone of zone_data; let l = index;">
										<div class="col-12"   *ngIf="l === 0">
											<div class="section-sub-heading">
												Zone
											</div>
										</div>
										<div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-15"  *ngIf="checkPlans(zone, key)">
											<div class="check-default mb-0">
												<input type="checkbox" class="checkbox" [id]="'zone' + zone.id" name="zone{{zone.id}}"  [checked]="zone.checked" [value]="zone.zone" (change)="onItemSelectZone($event, l, zone)"/>
												<label for="zone{{zone.id}}">
													{{zone.zone}}
												</label>
											</div>
										</div>
									</ng-container>
									</div>
							</div>
            </div>
          </div>
				</div>
			</div>
		</div>

		<div class="theme-card" *ngIf="paymentModeVal.length > 0">
			<div class="card-content">
				<div class="row">
					<div class="col-12">
						<div class="section-sub-heading">
							Payment Mode
						</div>
					</div>
					<ng-container *ngFor="let product of paymentModeVal; let i = index;">
						<div class="col-12 col-md-6 col-lg-4 col-xl-3">
							<div class="check-default mb-10" >
								<input class="checkbox" type="checkbox" [id]="product.value" name="{{product.value}}" [value]="product.value" [checked]="product.checked" (change)="onItemSelectPayment($event,i, product)">
								<label for="{{ product.value }}">	{{
									product.value
								}}</label>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>

		<div class="btn-block">
			<!-- Hiding Upload button as discussion with Chunnu -->
			<!-- <button type="submit" class="btn primary-btn theme-lg-btn">
				{{ "newSubProducer.upload.label" | translate }}
			</button> -->
			<button *ngIf="editedmode != '' && editedmode != undefined" type="submit" class="btn primary-btn theme-lg-btn active"
			[disabled] = "f.invalid" (click)="saveUser()">
				{{ "newSubProducer.update.label" | translate }}
			</button>
			<button *ngIf="editedmode == '' || editedmode == undefined" type="submit" class="btn primary-btn theme-lg-btn active"
			[disabled] = "f.invalid" (click)="saveUser()">
				{{ "newSubProducer.add.label" | translate }}
			</button>
		</div>
	</form>
</div>