import { Component, OnInit } from "@angular/core";
import { BaseServiceService } from "src/app/services/base-service.service";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.sass"],
})
export class NotificationComponent implements OnInit {
  lobArr = [];
  iconsArr = [];
  allNotification = [];
  notificationArr = [];
  selectedLob = "";
  showNotiLoader: boolean = false;
  constructor(
    public service: BaseServiceService,
    public router: Router,
    public route: ActivatedRoute,
    public translate: TranslateService
  ) { }

  async ngOnInit() {

    let lob_value = window.location.href?.includes('?') ? window.location.href?.split('?lob=')[1] : "";
    // let lob = this.route.snapshot.params["lob"];
    this.selectedLob = lob_value != "" && lob_value != undefined ? lob_value.toLowerCase() : this.selectedLob;
    this.getIcons();
    await this.callallLobs();
    await this.allNotifications();
    this.addCountInDropdown();
    this.lobChanged(this.selectedLob);
  }

  lobChanged(lob: string) {
    if (lob == '') {
      this.selectedLob = lob = this.lobArr[0]?.lob_value ? this.lobArr[0]?.lob_value : "";
    }
    console.log(lob);
    this.sortByType(lob);
  }

  getTimePeriod(date) {
    let time = '';
    let dt1 = new Date(date);
    let dt2 = new Date();
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    diff = Math.abs(Math.round(diff));
    if (diff > 0 && diff < 24) {
      return diff + "hours ago"
    }
    if (diff >= 8760) {
      let years = diff / 8760;
      years = Math.round(Math.floor(years));
      diff = diff - 8760 * years;
      time = (years > 0) ? ((years != 1) ? (years + 'years ') : (years + 'year ')) : '';
    }
    if (diff >= 730) {
      let months = diff / 730;
      months = Math.round(Math.floor(months));
      diff = diff - 730 * months;
      time += (months > 0) ? ((months != 1) ? (months + 'months ') : (months + 'month ')) : '';
    }
    if (diff >= 24) {
      let days = diff / 24;
      days = Math.round(Math.floor(days));
      diff = diff - 24 * days;
      time += (days > 0) ? ((days != 1) ? (days + 'days ') : (days + 'day ')) : '';
      time += (diff > 0) ? ((diff == 1) ? (diff + ' hour') : (diff + ' hours')) : '';
    }
    time = time.length > 0 ? time + ' ago' : '';
    return time;
  }

  iconMapping(product_id) {
    let icon_name: any = this.iconsArr.filter(item => {
      if (item.name == product_id) {
        return item;
      }
    });
    return icon_name[0];
  }

  getSortByDate() {
    this.notificationArr = this.notificationArr.sort((a, b) => {
      return <any>new Date(b.u_ts) - <any>new Date(a.u_ts);
    });
  }

  sortByType(lob: string) {
    lob = lob.includes('(') ? lob.substring(0, lob.indexOf('(')) : lob;
    this.router.navigate(["/notification"]);
    this.notificationArr = this.allNotification[lob] == undefined ? [] : this.allNotification[lob];
    this.notificationArr.forEach((element) => {
      element.hours = this.getTimePeriod(element.u_ts);
      element.icon = this.iconMapping(element.product_id).value;
      element.name = this.iconMapping(element.product_id).description;
    });
    this.getSortByDate();
    console.log(this.notificationArr, lob);
  }

  addCountInDropdown() {
    for (let i = 0; i < this.lobArr.length; i++) {
      let count = this.allNotification[this.lobArr[i]]?.length == undefined ? 0 : this.allNotification[this.lobArr[i]]?.length;

      this.lobArr[i] = { lob: this.lobArr[i] + '(' + count + ')', lob_value: this.lobArr[i] };
    }
    console.log(this.lobArr);
  }

  getIcons() {
    this.service.producticon().subscribe(response => {
      if (response.status === 0) {
        this.iconsArr = response.data;
      }
    })
  }

  allNotifications() {
    this.showNotiLoader = true;
    let allNoti = [];
    return new Promise((resolve, reject) => {
      this.service.notification().subscribe(
        (response) => {
          if (response.status === 0) {
            this.allNotification = response.data;
            this.lobArr.forEach(element => {
              if (this.allNotification[element] != undefined)
                allNoti = allNoti.concat(this.allNotification[element]);
            });
            this.lobArr.unshift('all');
            this.allNotification['all'] = allNoti;
            resolve(true);
            this.showNotiLoader = false;
          } else if (response.status === -114) {
            Swal.fire(
              this.translate.instant("staticTs.oops"),
              response.txt,
              this.translate.instant("staticTs.error")
            );
            sessionStorage.clear();
            this.router.navigateByUrl(`/login`);
          } else {
            Swal.fire(
              this.translate.instant("staticTs.oops"),
              response.txt,
              this.translate.instant("staticTs.error")
            );
          }
        },
        (err) => {
          Swal.fire(
            this.translate.instant("staticTs.oops"),
            this.translate.instant("staticTs.serverTimeout"),
            this.translate.instant("staticTs.error")
          );
        }
      );
    });
  }

  callallLobs() {
    return new Promise((resolve, reject) => {
      let email = sessionStorage.getItem("email");
      this.service.getallLobs(email).subscribe(
        (response) => {
          if (response.status === 0) {
            this.lobArr = response.data[0].lob.split(",");
            this.lobArr = this.lobArr.filter((a) => a);
            resolve(true);
          } else if (response.status === -114) {
            Swal.fire(
              this.translate.instant("staticTs.oops"),
              response.txt,
              this.translate.instant("staticTs.error")
            );
            sessionStorage.clear();
            this.router.navigateByUrl(`/login`);
          } else {
            Swal.fire(
              this.translate.instant("staticTs.oops"),
              response.txt,
              this.translate.instant("staticTs.error")
            );
          }
        },
        (err) => {
          Swal.fire(
            this.translate.instant("staticTs.oops"),
            this.translate.instant("staticTs.serverTimeout"),
            this.translate.instant("staticTs.error")
          );
        }
      );
    })
  }

  routeBack() {
    this.router.navigateByUrl("/allapplication");
  }
  navigateToUrl(url, quote_id) {
    console.log(`${window.location.origin + "/" + url + quote_id}`);
    window.location.href = `${window.location.origin + "/" + url + quote_id}`;
  }
}
