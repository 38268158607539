import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { TranslateService } from "@ngx-translate/core";
import { BaseServiceService } from 'src/app/services/base-service.service';
import { ToastrService } from "ngx-toastr";
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit {
  documentUpload: FormGroup;
  loading : boolean = false;
  file: any;

  filePartialUploadModalRef: BsModalRef;
  ngbConfig: NgbModalOptions = {
    backdrop: "static",
    keyboard: false
  };
  wrongCount = 0;
  failCases;
  successfulCount = 0;
  mandatoryFields = ['email_id','deo_status','producer_status','type','start_Date','end_date','user_type','first_name','last_name','transaction_type','location','revalidation','lob','payment_mode','role'];
  constructor(public _fb: FormBuilder, 
    public globals : Globals, 
    public translate: TranslateService, 
    public service: BaseServiceService, 
    private toastr: ToastrService,
    private _ngbModal: BsModalService,
    public router: Router
    ) {}

  ngOnInit(): void {
    this.ngbConfig["class"] = "theme-modal";
    this.initializeForm();
  }
  initializeForm() {
    this.documentUpload = this._fb.group({
      document: [""],
      documentName: ["", [Validators.required]],
      documentType: [null],
      documentSize: [""],
      reasonForApproval: [""]
    });
  }

  onFileChange(event:any) {
    this.file = event;
    this.documentUpload.get("document").setValue(event.target.files[0]);
    this.documentUpload.get('documentName').setValue(event?.target?.files[0]?.name);
  }

  removeDocFile(){
    this.documentUpload.get('document').setValue('');
    this.documentUpload.get('documentName').setValue('');
    this.file = null;
  }

  BulkUserJSON(filePartialUploadModal){
    if(this.file){
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      const file = this.file.target.files[0];
      reader.onload = async (ev) => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return initial;
        }, {});
        let finalData = [];
        Object.keys(jsonData).forEach(key=>{
          finalData=[...finalData,...jsonData[key]];
        });
        if(finalData?.length == 0){
          this.toastr.error(this.translate.instant('bulkUpload.invalidData'));
        }
        else if(finalData?.length && this.mandatoryField(finalData)==false){
          this.toastr.error(this.translate.instant('bulkUpload.mandatory'));
        }
        else{
          await this.createUser(finalData,filePartialUploadModal);
        }
        
      }
      reader.readAsBinaryString(file); 
    }else{
      this.toastr.error(this.translate.instant('bulkUpload.uploadFile'));
    }
  }

  mandatoryField(finalData){
    let valid = true;
    finalData.forEach(data=>{
      this.mandatoryFields.forEach(key=>{
        if(data[key]?.length == 0 || data[key]==undefined ||(data[key].toString())?.length ==0){
          valid = false;
        }
      });
    });
    return valid;
  }

  createUser(UsersData,filePartialUploadModal){
    return new Promise((resolve,reject)=>{
      this.service.bulkDeuUserCreation(UsersData).subscribe((response)=>{
        if(response.status == 0){
          if(response.data.failure_data?.length){
            this.failCases =response.data.failure_data;
            this.wrongCount =  response.data.failure_data?.length;
            this.successfulCount = UsersData.length - this.wrongCount;
            this.filePartialUploadModalRef = this._ngbModal.show(filePartialUploadModal, this.ngbConfig);
            this.removeDocFile();
          }
          else if(response.data.message.toLowerCase() == 'insert success'){
            this.toastr.success(this.translate.instant('bulkUpload.useradded'));
            this.removeDocFile();
          }resolve(true);
        }
        else{
          this.toastr.error(response.txt);
          resolve(true);
        }
      },err=>{
        this.toastr.error(this.translate.instant('staticTs.serverTimeout'));
        resolve(true);
      });
    });
  }

  exportToExcel() {
    let data =[];
    let keys = Object.keys(this.failCases[0]);
    data.push(keys);
    for (let i = 0; i < this.failCases.length; i++) {
      data.push(Object.values(this.failCases[i]));
    }
    var CsvString = "";
			data.forEach((RowItem: any, RowIndex: any) => {
				RowItem.forEach((colItem: any, colIndex: any) => {
					CsvString += colItem + ",";
				});
				CsvString += "\r\n";
			});
			CsvString = "data:application/csv, " + encodeURIComponent(CsvString);
			var x = document.createElement("A");
			x.setAttribute("href", CsvString);
			const fileName ="bulk_upload_failure_report.csv";
			x.setAttribute("download", fileName);
			document.body.appendChild(x);
			x.click();
  }
  back(){
    this.router.navigateByUrl(`/user-mgmt`);
  }

}
