import { Component, OnInit } from "@angular/core";
import { Globals } from "src/app/globals";
import { Router } from "@angular/router";
import { BaseServiceService } from "src/app/services/base-service.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
@Component({
  selector: "app-combo-products",
  templateUrl: "./combo-products.component.html",
  styleUrls: ["./combo-products.component.sass"],
})
export class ComboProductsComponent implements OnInit {
  comboForm: FormGroup;
  lobData1 = this.service.comboMaster[0]["lobData1"];
  lobData2 = this.service.comboMaster[0]["lobData2"];
  product1 = this.service.comboMaster[0]["product1"];
  product2 = this.service.comboMaster[0]["product2"];
  selectedLob1 = null;
  selectedLob2 = null;
  selectedproduct1 = null;
  selectedproduct2 = null;

  constructor(
    public globals: Globals,
    public router: Router,
    public service: BaseServiceService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.comboForm = this.formBuilder.group({
      lob1: [null, Validators.required],
      lob2: [null, Validators.required],
      product1: [null, Validators.required],
      product2: [null, Validators.required],
    });
    this.autoSelect();
  }

  autoSelect() {
    if (this.lobData1?.length == 1) {
      this.selectedLob1 = this.lobData1[0]?.lob_value;
      this.selectedproduct1 =
        this.product1?.length == 1 ? this.product1[0]?.value : null;
    }
    if (this.lobData2?.length == 1) {
      this.selectedLob2 = this.lobData2[0]?.lob_value;
      this.selectedproduct2 =
        this.product2?.length == 1 ? this.product2[0]?.value : null;
    }
  }
  onLobChange(lob_value, card_type) {
    switch (card_type) {
      case "card1":
        this.selectedproduct1 =
          this.product1?.length == 1 ? this.product1[0]?.value : null;
        break;
      case "card2":
        this.selectedproduct1 =
          this.product2?.length == 1 ? this.product2[0]?.value : null;
    }
  }

  createQuote() {
    if (this.comboForm.invalid) {
      this.comboForm.markAllAsTouched();
      return;
    }
    sessionStorage.setItem("backFromCustomerdiary", document.location.href);
    window.location.href = `${window.location.origin}${this.service.customerDiaryPath}`;
  }

  back() {
    sessionStorage.setItem("current_lob", "");
    this.router.navigateByUrl("/allapplication");
  }
}
