import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType, 
  HttpResponse 
} from "@angular/common/http";
import { Observable, of  } from "rxjs";

import { tap } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseServiceService } from './base-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { Constant } from './constants'

@Injectable({
  providedIn: "root",
})
export class Interceptor implements HttpInterceptor {
  constructor(public router: Router, private ngbModal: NgbModal, public service: BaseServiceService, private spinner: NgxSpinnerService) { }
  
  requestParam : any;
  keyNameInSessionStorage : any;
  profileDetails : any;
  api : any;
  apiPath : any;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    this.requestParam = request.url;
    
    
    this.generateCustomKeyName(request);
    //to cut the interceptor chain is to simply return an empty Observable
    //check Data Stored in Session Storage
    this.getCachedResponse();
    // attempt to retrieve a cached response  
    const cachedResponse = this.profileDetails;
    // return cached response  
    if(cachedResponse) {
      return of(new HttpResponse({ body: cachedResponse })); 
    } 

  else{
       // send request to server and add response to cache  
    return next.handle(request).pipe(
      tap((event) => {
        if (event.type === HttpEventType.Response) {
          const response = event.body;
          const headers = event.headers;
          
          // this.spinner.hide();
          if (headers) {
            
            if (response.status === -106 || response.status === -114) {
              if (request.url != `${this.service.SecUrl_two}profile` && request.url == `${this.service.SecUrl_two}profile`) {
                this.router.navigate(['/login']);
                this.spinner.hide();
              } else { 
                this.router.navigate(['/login']);
                this.spinner.hide()
              }
            } else {
              if (event.headers.get('set-in-auth-token') !== null) {
                if (sessionStorage.getItem('auth-token') == sessionStorage.getItem('in-auth-token')) {
                  sessionStorage.setItem('auth-token', event.headers.get('set-in-auth-token'));
                  sessionStorage.setItem('in-auth-token', event.headers.get('set-in-auth-token'));
                  this.service.headers['in-auth-token'] = event.headers.get('set-in-auth-token');
                  this.service.newheaders['in-auth-token'] = event.headers.get('set-in-auth-token');
                } else {
                  if(sessionStorage.getItem('in-auth-token') == request.headers.get('in-auth-token')){
                      sessionStorage.setItem('in-auth-token', event.headers.get('set-in-auth-token'));
                      this.service.headers['in-auth-token'] = event.headers.get('set-in-auth-token');
                      // this.service.newheaders['in-auth-token'] = event.headers.get('set-in-auth-token');
                  }
                  else if(sessionStorage.getItem('auth-token') == request.headers.get('in-auth-token')){
                    sessionStorage.setItem('auth-token', event.headers.get('set-in-auth-token'));
                    this.service.newheaders['in-auth-token'] = event.headers.get('set-in-auth-token');
                  }
                }
              }
              //
            }
          }
           // Generic Code to store Response for every Api in sessionStorage with Origin and End Point Name 
          this.requestParam = request.url;
          this.generateCustomKeyName(request); 
          if(sessionStorage.getItem('mapped_producers') != '0'){
            this.storeResponseInSessionStorage(response);
          }
          return event;
        }
      })
    );
    }
  }

  // Make custom Key Name to store API Response in sessionStorage

  generateCustomKeyName(request){
    this.keyNameInSessionStorage = "";
    this.apiPath = '';
    let splitted = this.requestParam.split('://'); //this will output ["http", "url"]
    let first = splitted[0]; //"http"
    let second = splitted[1]; //"url"
    let third = ''
    if(second != undefined){
      let splitted2 = second.split('.')
      third = splitted2[0]
    }
    let apiPath = this.requestParam.split('/api');
    this.api = apiPath[1]
    let rest = this.requestParam.substring(0, this.requestParam.lastIndexOf("/") + 1);
    let last = this.requestParam.substring(this.requestParam.lastIndexOf("/") + 1, this.requestParam.length);
   // this.keyNameInSessionStorage = third + '_' + last;
   //for producer 
   
   if(sessionStorage.getItem('in-auth-token') != undefined && (sessionStorage.getItem('in-auth-token') == request.headers.get('in-auth-token'))){
    this.keyNameInSessionStorage = third + '_' + last;
    }
  
  //for Emp
  if(sessionStorage.getItem('auth-token') != undefined && (sessionStorage.getItem('auth-token') == request.headers.get('in-auth-token'))){
      this.keyNameInSessionStorage = third + '_' + last + '_'+ 'Emp';
    }
  }

  generateCustomKeyProfileApiEmp(response){
    this.keyNameInSessionStorage = "";
    this.apiPath = '';
    let splitted = response.url.split('://'); //this will output ["http", "url"]
    let first = splitted[0]; //"http"
    let second = splitted[1]; //"url"
    let third = ''
    if(second != undefined){
      let splitted2 = second.split('.')
      third = splitted2[0]
    }
    let apiPath = response.url.split('/api');
    this.api = apiPath[1]
    
    let rest = response.url.substring(0, response.url.lastIndexOf("/") + 1);
    let last = response.url.substring(response.url.lastIndexOf("/") + 1, response.url.length);
    this.keyNameInSessionStorage = third + '_' + last + '_'+ 'Emp';
    this.storeResponseInSessionStorage(response.body);
  }

  storeResponseInSessionStorage = (_Response)=>{
    // Currently storing only profile API Response in session storage
    //if(this.requestParam.substring(this.requestParam.lastIndexOf("/") + 1, this.requestParam.length) == 'profile' && sessionStorage.getItem(this.keyNameInSessionStorage) == undefined){
    if(Constant.includes(this.api) && this.keyNameInSessionStorage.length > 0 &&  sessionStorage.getItem(this.keyNameInSessionStorage) == undefined ){
      if(_Response){
        try {
          sessionStorage.setItem(this.keyNameInSessionStorage, JSON.stringify(_Response));
        } catch (error) {
          console.log("Error in session storage", error);
        }
      }
    }
    
  }

  getCachedResponse(){
    this.profileDetails = null;
    if(sessionStorage.getItem(this.keyNameInSessionStorage) != null) {
      this.profileDetails = JSON.parse(sessionStorage.getItem(this.keyNameInSessionStorage))
    }
  }

  // if in-auth-token  == for producer
  // if auth-token  == for employee

}