<ng-container *ngIf="showLoader && !isDocumentIdFetched; else showNsmContainer">
  <div
    class="d-flex justify-content-center align-items-center dropdown-input-loader mt-6 dropdown-input-loader"
  >
    <div class="fa-2x">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
  </div>
</ng-container>

<ng-template #showNsmContainer>
  <div class="theme-container" *ngIf="showTakeActionForm">
    <div [ngClass]="{ 'page-title': !cToken }">
      <div class="nav-btn" *ngIf="!cToken" (click)="routeback()">
        <svg>
          <use xlink:href="#ICON_Arrow" />
        </svg>
      </div>
      <div class="w-100">
        <div class="page-heading-container d-flex justify-content-between">
          <div
            [ngClass]="{
              'head-lg heading': !cToken,
              'additional-discount-heading': cToken
            }"
          >
            {{ "additionalDiscountDetails.label" | translate }}
          </div>
        </div>
        <div class="breadcrum-container" *ngIf="!cToken">
          <ul class="breadcrum">
            <li class="head-sm" click="globals.goBackToAllApplication()">
              {{ "additionalDiscountDetails.back" | translate }}
            </li>
            <li class="head-sm">
              {{ "additionalDiscountDetails.label" | translate }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="preview-details">
      <div class="theme-card card-top-spacing full">
        <div class="card-content pb-0">
          <div class="d-flex justify-content-between col-md-12">
            <label class="section-heading">
              {{ "additionalDiscountDetails.details" | translate }}
            </label>
            <div class="d-flex justify-content-between">
            <button
              class="btn link-btn"
              (click)="showViewHistoryModal(view_history_template)"
            >
              {{ "additionalDiscountDetails.viewHistory" | translate }}
            </button>
            <div class="pull-right p-l-sm d-flex align-items-center">
              <button
                (click)="downloadQuote()"
                type="button"
                class="btn quotelist-btn mr-0 d-none d-md-block"
                tooltipclass="tooltip-outer-wrapper"
              >
                <svg class="svg-xs mini-svg m-0" name="review_dnld">
                  <use xlink:href="#ICON_Download" />
                </svg>
              </button>
              </div>
              </div>
          </div>
          <div *ngFor="let data of discountingData" class="row">
            <div class="col-md-6 col-lg-4 col-xl-3 mb-20">
              <label class="theme-label">{{
                "additionalDiscountDetails.quoteId" | translate
              }}</label>
              <p class="theme-value">{{ data.quote_id }}</p>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mb-20">
              <label class="theme-label">{{
                "additionalDiscountDetails.producerName" | translate
              }}</label>
              <p class="theme-value">{{ data.producer_name }}</p>
            </div>

            <div class="col-md-6 col-lg-4 col-xl-3 mb-20">
              <label class="theme-label">{{
                "additionalDiscountDetails.mappedLoc" | translate
              }}</label>
              <p class="theme-value">{{ data.mapped_tagic_location }}</p>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mb-20">
              <label class="theme-label">{{
                "additionalDiscountDetails.clientName" | translate
              }}</label>
              <p class="theme-value">{{ data.client_name }}</p>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mb-20">
              <label class="theme-label">{{
                "additionalDiscountDetails.product" | translate
              }}</label>
              <p class="theme-value">{{ data.product_name }}</p>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mb-20">
              <label class="theme-label">{{
                "additionalDiscountDetails.period" | translate
              }}</label>
              <p class="theme-value">{{ data.policy_period }}</p>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mb-20">
              <label class="theme-label">{{
                "additionalDiscountDetails.noOfLives" | translate
              }}</label>
              <p class="theme-value">{{ data.no_of_lives }}</p>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mb-20">
              <label class="theme-label">{{
                "additionalDiscountDetails.premium" | translate
              }}</label>
              <p class="theme-value">
                {{ data.base_quote_premium | currency : "INR" }}
              </p>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mb-20">
              <label class="theme-label">{{
                isNSMFlow
                  ? ("additionalDiscountDetails.requestedDiscounting"
                    | translate)
                  : ("additionalDiscountDetails.requiredDiscounting"
                    | translate)
              }}</label>
              <p class="theme-value">
                {{ data.required_discount_precentage }}%
              </p>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3 mb-20">
              <label class="theme-label">{{
                isNSMFlow
                  ? ("additionalDiscountDetails.requestedPremium" | translate)
                  : ("additionalDiscountDetails.expectedClosurePremium"
                    | translate)
              }}</label>
              <p class="theme-value">
                {{ data.required_premium | currency : "INR" }}
              </p>
            </div>
            <hr />
          </div>
          <div
            [formGroup]="takeActionForm"
            class="col-md-12"
            *ngIf="!isSubmitted"
          >
            <label class="section-heading">
              {{ "additionalDiscountDetails.takeAction" | translate }}
            </label>
            <div class="row">
              <div class="form-field col-md-12" *ngIf="isStatusQueried">
                <label for="remark" class="head-sm required-field">
                  {{ "additionalDiscountDetails.remarks" | translate }}</label
                >
                <textarea
                  class="form-control"
                  [formControl]="remarkControl"
                  cols="30"
                  rows="4"
                  placeholder="{{
                    'additionalDiscountDetails.updatedRemarks' | translate
                  }}"
                ></textarea>
              </div>
              <div class="col-md-6 col-lg-4 form-field" *ngIf="!isNSMFlow">
                <label class="head-sm" *ngIf="!(isStatusQueried)">{{
                  "additionalDiscountDetails.referTo" | translate
                }}</label>
                <label class="head-sm" *ngIf="isStatusQueried">{{
                  "additionalDiscountDetails.referBack" | translate
                }}</label>
                <ng-select
                  [items]="referToArray"
                  [searchable]="false"
                  bindLabel="name"
                  bindValue="value"
                  formControlName="referTo"
                  placeholder="Refer To"
                  class="ngSelectOption 1"
                  (change)="onApprovalChange($event)"
                >
                </ng-select>
              </div>
              <div class="row" *ngIf="isNSMFlow">
                <div class="col-md-4 col-lg-3 col-xl-3 form-field">
                  <label for="updatedPremium" class="head-sm">{{
                    "additionalDiscountDetails.updatedPremium" | translate
                  }}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="updatedPremium"
                      name="updatedPremium"
                      id="updatedPremium"
                      autocomplete="off"
                      placeholder="Updated Premium"
                      (keypress)="restrictInput($event)"
                    />
                  </div>
                  <div *ngIf="takeActionForm.get('updatedPremium').hasError('premiumExceeded')">
                    <small class="text-danger">
                      Updated premium cannot be greater than the base quote premium.
                    </small>
                  </div>
                </div>
                <div class="col-md-4 col-lg-3 col-xl-3 form-field">
                  <label for="updatedDiscount" class="head-sm">{{
                    "additionalDiscountDetails.updatedDiscounting" | translate
                  }}</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="updatedDiscount"
                      name="updatedDiscount"
                      id="updatedDiscount"
                      autocomplete="off"
                      placeholder="Updated Discounting %"
                      (keypress)="restrictInput($event)"
                    />
                    <!-- <div class="input-group-append">
                    <span class="input-group-text">%</span>
                </div> -->
                    <!-- <span class="input-group-text svg-md"> -->
                    <!-- <i class="fa fa-percent" aria-hidden="true"></i> -->
                    <!-- </span> -->
                  </div>
                  <div *ngIf="takeActionForm.get('updatedDiscount').hasError('negativeDiscount')">
                    <small class="text-danger">
                      Discount percent cannot be negative.
                    </small>
                  </div>
                  <div *ngIf="takeActionForm.get('updatedDiscount').hasError('discountExceeded')">
                    <small class="text-danger">
                      Discount cannot exceed 100%.
                    </small>
                  </div>
                </div>
                <div class="col-md-4 col-lg-3 col-xl-3 form-field">
                  <label class="head-sm">{{
                    "additionalDiscountDetails.action" | translate
                  }}</label>
                  <ng-select
                    [items]="actionRequired"
                    [searchable]="false"
                    formControlName="actionRequired"
                    placeholder="Action Required"
                    class="ngSelectOption 1"
                    (change)="toggleApprovalFieldVisibility($event)"
                  >
                  </ng-select>
                </div>
                <div
                  class="col-md-4 col-lg-3 col-xl-3 form-field"
                  *ngIf="showRecommendForApproval"
                >
                  <label class="head-sm">{{
                    "additionalDiscountDetails.recommendForApproval" | translate
                  }}</label>
                  <ng-select
                    [items]="recommendForApproval"
                    [searchable]="false"
                    bindLabel="name"
                    bindValue="value"
                    formControlName="recommendForApproval"
                    placeholder="Recommend For Approval"
                    class="ngSelectOption 1"
                    (change)="onApprovalChange($event)"
                  >
                  </ng-select>
                </div>
              </div>
              
              <div formGroupName="docUpload">
                <div
                  class="form-field col-md-12 my-5 theme-form mt-mobile"
                  id="uploadDoc"
                >
                  <div class="row">
                    <div class="col-md-6 col-xl-3">
                      <div class="d-flex">
                        <div class="d-flex">
                          <label for="uploadFile" class="docIcon">
                            <svg class="svg-md">
                              <use xlink:href="#ICON_Doc" />
                            </svg>
                          </label>
                          <label
                            for="uploadFile"
                            class="m-3 fileUploadLabel theme-value"
                          >
                            {{
                              "additionalDiscountDetails.uploadDocument"
                                | translate
                            }}
                          </label>
                        </div>
                        <div
                          class="d-flex justify-content-center align-items-center ml-3"
                        >
                          <label for="uploadFile" class="uploadBtn">
                            <div class="btn quotelist-btn m-0">
                              <!-- <svg class="svg-xxs rotate">
                                <use xlink:href="#ICON_Download" />
                              </svg> -->
                              Select
                            </div>
                          </label>
                        </div>
                      </div>
                      <input
                        type="file"
                        #customFile
                        (click)="customFile.value = null"
                        (change)="onFileSelected($event, 'docUpload')"
                        id="uploadFile"
                        class="custom-file-input d-none"
                        accept=".doc, .pdf, .xls, .xlsx, .jpg, .png, .jpeg"
                      />
                      <div *ngIf="uploadedDocuments.length > 0">
                        <ul class="theme-label">
                          <li
                            *ngFor="let doc of uploadedDocuments; let i = index"
                            class="my-2 text-break"
                          >
                            {{ doc.name }}
                            <button
                            *ngIf="!isUploadSuccessful[i]" 
                              (click)="documentUpload('docUpload', i)"
                              class="btn quotelist-btn m-0 mx-2"
                            >
                              <svg class="svg-xxs rotate">
                                <use xlink:href="#ICON_Download" />
                              </svg>
                            </button>
                            <button
                              (click)="removeDocument(i)"
                              class="btn quotelist-btn m-0 mx-2"
                            >
                              <svg class="svg-xxxs">
                                <use xlink:href="#ICON_Cross" />
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-field col-md-12">
                <label for="remark" class="head-sm required-field">
                  {{ "additionalDiscountDetails.remarks" | translate }}</label
                >
                <textarea
                  class="form-control"
                  formControlName="remark"
                  cols="30"
                  rows="4"
                  placeholder="{{
                    'additionalDiscountDetails.remarksPlaceHolder' | translate
                  }}"
                ></textarea>
                <span
                  class="error-msg required-field"
                  *ngIf="
                    takeActionForm.get('remark')?.touched &&
                    takeActionForm.get('remark')?.hasError('required')
                  "
                >
                  {{
                    "additionalDiscountDetails.remarksRequiredErrorMessage"
                      | translate
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-top-spacing" *ngIf="!isSubmitted && showTakeActionForm">
    <div>
      <button
        class="btn primary-btn theme-lg-btn active"
        [disabled]="!takeActionForm.valid || showButtonLoader"
        (click)="onSubmit(success_template)"
      >
        {{ "commonBtn.submit" | translate }}
        <div class="fa" *ngIf="showButtonLoader">
          <i class="fa fa-spinner fa-spin"></i>
        </div>
      </button>
    </div>
    <!-- <ng-template #nsmFlowButtons>
    <div class="d-flex">
      <button
        class="btn primary-btn theme-lg-btn active"
        (click)="onAcceptOrResend('accept')"
      >
        {{ "commonBtn.accept" | translate }}
      </button>
      <button
        class="btn primary-btn theme-lg-btn active"
        (click)="onAcceptOrResend('resend')"
      >
        {{ "commonBtn.resend" | translate }}
      </button>
    </div>
  </ng-template> -->
  </div>
</ng-template>
<!-- view history modal -->
<ng-template #view_history_template>
  <div
    class="modal-container d-flex justify-content-center align-items-center mt-5"
  >
    <div class="detail-container-modal modal-lg">
      <div class="modal-body">
        <div class="pt-6">
          <div class="row">
            <div class="my-5">
              <h4 class="modal-title">Discounting History</h4>
            </div>
            <div class="close-icon" (click)="viewHistoryModalRef.hide()">
              <svg>
                <use xlink:href="#ICON_Cross" />
              </svg>
            </div>
          </div>
          <div *ngIf="noHistoryFound">
            <p>No history found for Quote Id : {{ quoteId }}</p>
          </div>
          <div
            class="row"
            *ngFor="let discountingContent of discountingHistoryContent"
          >
            <div class="d-flex justify-content-between mb-4">
              <div class="d-flex flex-column">
                <span class="theme-value">{{ discountingContent.name }}</span>
                <p class="theme-label">
                  {{ discountingContent.date | date : "EEEE, MMMM d, y" }}
                </p>
                <p class="theme-label">{{ discountingContent.remarks }}</p>
                <div *ngIf="discountingContent.doc_attached === 'Yes'">
                    <span class="theme-label">
                    <ul class="p-0">
                      <li *ngFor="let doc of discountingContent.doc_name">
                        <a [href]="getDocumentUrl(doc)" target="_blank">{{ doc }}</a>
                      </li>
                    </ul>
                    </span>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center">
                <div
                  class="status-button"
                  [ngClass]="getStatusClasses(discountingContent.status)"
                >
                  {{ discountingContent.status | titlecase }}
                </div>
              </div>
             
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #success_template>
  <div class="modal-container">
    <div class="detail-container-modal modal-sm success-info">
      <div class="modal-header">
        <div class="close-icon" (click)="successModalRef.hide()">
          <svg>
            <use xlink:href="#ICON_Cross" />
          </svg>
        </div>
      </div>
      <div class="modal-body succes-container">
        <div class="row">
          <div class="text-center col-md-12">
            <div>
              <img
                alt=""
                class="img-fluid w-20"
                src=".\assets\images\FILLED ICONS\ICON_Share_new.svg"
              />
            </div>
            <div class="head-md status-label mt-4 font-lg">
              {{ "Quote/s shared successfully" }}
            </div>
            <div class="font-md head-md mt-4 text-inht">
              {{ "Your Quote has been shared with the following Email Id:"
              }}<strong>{{ assigneeEmail }}</strong>
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-center">
            <table class="success-modal-tbl">
              <tr>
                <td class="theme-label pl--60">
                  {{ "Quote Id/s :" }}
                </td>
                <td class="theme-value pl-0 pr--60">
                  {{ quoteId }}
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-12 text-center mt-30">
            <button
              *ngIf="!isNSMFlow"
              type="button"
              class="btn sm primary-btn theme-sm-btn mr-0 active"
              (click)="successModalRef.hide(); routeToReviewQuote()"
            >
              {{ "success_Detail.button.continue" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
