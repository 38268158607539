import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { RenewalComponent } from './renewal/renewal.component';
// import { AllapplicationComponent } from './allapplication/allapplication.component';
// import { MytransactionComponent } from './mytransaction/mytransaction.component';
const routes: Routes = [
//	{ path: 'dashrenewal', component: RenewalComponent},
//	{ path: 'allapplication', component: AllapplicationComponent},
	// { path: 'mytransactions', component: MytransactionComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
