import { AbstractControl, FormArray, FormGroup, ValidationErrors } from "@angular/forms";
import { errorMessage } from "./error-message";

export function checkFormControl(control: AbstractControl): string {
    if ((control.touched || control.dirty) && control.invalid && control.errors) {
      const errors: ValidationErrors = control.errors;
      if (errors.required) {
        return errorMessage.required;
      }
      if (errors.minlength) {
        return errorMessage.minLength + errors.minlength.requiredLength;
      }
      if (errors.maxlength) {
        return errorMessage.maxLength + errors.maxlength.requiredLength;
      }
      if (errors.min) {
        return errorMessage.minValue + errors.min.min;
      }
      if (errors.max) {
        return errorMessage.maxValue + errors.max.max;
      }
      if (errors.email) {
        return errorMessage.email;
      }
      if (errors.invalidDate) {
        return errorMessage.invalidDate;
      }
      if (errors.pattern) {
        return errorMessage.pattern;
      }
      if(errors.invalidPolicyDate) {
        return errorMessage.invalidPolicyDate;
      }
      if(errors.filterToDate){
        return errorMessage.filterToDate;
      }
      if(errors.invalidDobDate){
        return errorMessage.invalidDobDate;
      }
    }
  }

  export function marksControlsTouched(group: FormGroup | FormArray): null {
    for (const i in group.controls) {
      if (i) {
        group.controls[i].markAsTouched();
        if (
          group.controls[i] instanceof FormGroup ||
          group.controls[i] instanceof FormArray
        ) {
          marksControlsTouched(group.controls[i]);
        }
      }
    }
    return;
  }