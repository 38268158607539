import { Pipe, PipeTransform } from '@angular/core';
import { checkFormControl } from './form-validation';
@Pipe({
  name: 'ErrorMessagePipe',
})
export class ErrorMessagePipe implements PipeTransform {
  transform(value, control, touched, dirty) {
    return checkFormControl(control);
  }
}
