import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  loaded = false;
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private spinner: NgxSpinnerService,
  ) {}

  loadCommonElements(commonPath, commonCssPath, productCss) {
    let polyfillses5 = this._document.createElement('script');
    polyfillses5.noModule = true;
    polyfillses5.defer = true;
    polyfillses5.src = `${commonPath}/polyfills-es5.js`;

    let maines5 = this._document.createElement('script');
    maines5.noModule = true;
    maines5.defer = true;
    maines5.src = `${commonPath}/main-es5.js`;

    let polyfillses2015 = this._document.createElement('script');
    polyfillses2015.type = 'module';
    polyfillses2015.src = `${commonPath}/polyfills-es2015.js`;

    let main2015 = this._document.createElement('script');
    main2015.type = 'module';
    main2015.src = `${commonPath}/main-es2015.js`;

    let commonStyle = this._document.createElement('link');
    commonStyle.rel = 'stylesheet';
    commonStyle.href = `${commonCssPath}`;
    commonStyle.type = 'text/css';
    commonStyle.onload = () => {
      this._document.head.appendChild(productStyle);
    };

    let productStyle = this._document.createElement('link');
    productStyle.rel = 'stylesheet';
    productStyle.href = productCss;
    productStyle.type = 'text/css';
    productStyle.onload = () => {
      this.loaded = true;
      this.spinner.hide();
    };

    this._document.head.appendChild(polyfillses5);
    this._document.head.appendChild(maines5);
    this._document.head.appendChild(polyfillses2015);
    this._document.head.appendChild(main2015);
    this._document.head.appendChild(commonStyle);
  }
}
