<!-- <app-left-menu *ngIf="router.url !== '/' && !router.url.includes('/login')"></app-left-menu>
<app-header *ngIf="router.url !== '/' && !router.url.includes('/login') "></app-header> -->
<tagic-header *ngIf = "router.url !== '/' && !router.url.includes('/login') && !isCtoken"></tagic-header>
<tagic-left-menu *ngIf = "router.url !== '/' && !router.url.includes('/login') && !isCtoken"></tagic-left-menu>

<!-- Multilanguage Implementation -->
<!-- <tagic-header *ngIf = "router.url && router.url != '/login'" [multiLanguage]="true" (onChangeMultiLanguage)="changeLanguage($event)"></tagic-header> -->
<div *ngIf="(isCtoken)" class="pull-left col-md-6 ml-5 position-absolute">
  <img class="svg-xl" src="../assets/images/logo.png">
</div>
<div id="appcontainer" #scrollContainer
  [ngClass]="{'center-container product-inner-page w-100': router.url && !router.url.includes('/login') && !pageNotFound, 'h-100': router.url !== '/' && router.url.includes('/login')}">
  <router-outlet *ngIf="scriptLoader.loaded" (activate)="onActivate($event, scrollContainer)"></router-outlet>
</div>

<app-svg-icons></app-svg-icons>
<ngx-spinner bdColor="#fff" size="default" template="<img src ='./assets/images/loader/loader.gif' />"></ngx-spinner>