import { Component, OnInit } from '@angular/core';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {

  constructor(public service: BaseServiceService,public router:Router, public translate: TranslateService) { }

  ngOnInit(): void {
    this.service.checklogin();
    this.getLoc();
  }

  /******************Get location************************************/ 
getLoc() {
  this.service.getLocation(sessionStorage.getItem('location')).subscribe(responseloc => {
    if (responseloc.status === 0) {
      sessionStorage.setItem('location_code', responseloc.data[0].location_code);
      if(responseloc.data[0]?.office_code?.length > 0){
        sessionStorage.setItem('office_code', responseloc.data[0]?.office_code);
      }
      else{
        sessionStorage.setItem('office_code', '');
      }
      sessionStorage.setItem('state_code', responseloc.data[0].state_code);
      sessionStorage.setItem('branch_gstin_no', responseloc.data[0].branch_gstin_no);
      sessionStorage.setItem('state_name', responseloc.data[0].state_name);
      
    }
    else {
      Swal.fire(this.translate.instant("staticTs.oops"), responseloc.txt, this.translate.instant("staticTs.error"));
    }
  }, err => {
    Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
  })

}
trans() {
  this.router.navigateByUrl('/mytransactions');
}

}
