import { Component, OnInit, ViewChild, Injectable, ElementRef } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute, Params } from "@angular/router";
//import Swal from "sweetalert2";
import { BaseServiceService } from "src/app/services/base-service.service";
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { Globals } from "src/app/globals";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { SwalService } from "src/app/services/swal.service";
import { SessionStorageService } from "src/app/services/session-storage.service";

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = "/";

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : "";
  }
}

@Component({
  selector: "app-new-sub-producer",
  templateUrl: "./new-sub-producer.component.html",
  styleUrls: ["./new-sub-producer.component.sass"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class NewSubProducerComponent implements OnInit {

  startingNo: boolean= false;
  model: any = {}
  submit: boolean = false;
  role: string = "Producer";
  active: string;
  disable: boolean = false;
  bntStyle: string;
  @ViewChild('f', { static: true }) userData: NgForm;
  shwplace: any
  pageMode: number = 0;
  active_products: any = [];
  active_brokers: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  lobdropdownSettings = {};
  productdropdownSettings = {};
  paymentdropdownSettings = {};
  readonly: boolean = false;
  minDate: any;
  producerStartDate: any;
  producerEndDate: any;
  parent_code: string;
  temp_lob: any;
  temp_location: any;
  temp_product: any;
  temp_loc = []
  selectedproducts: any;
  selectedlocation;

  eidPattern = '^[0-9]*$';
  locationPattern = '^[a-zA-Z]+(?:,[a-zA-Z]+)*$';
  mobNumberPattern = '[6-9]{1}[0-9]{9}';
  ntlogin_pattern = '^[a-zA-Z0-9]{3,100}$';
  email_pattern = '^[a-zA-Z0-9][a-zA-Z0-9-_\.]+@([a-zA-Z]|[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9])\.[a-zA-Z0-9]{2,10}(?:\.[a-zA-Z]{2,10})?$';
  editedmode: any;
  userdata: Params;
  fetchpaymenttype: any = [];
  getpayment: any = [];

  paymentModeVal: any = [];
  fetchlob: any = [];
  getlob: any;
  produce_code: string;
  ad_lob: any;
  activeClass: boolean = false;
  active_lob: any = [];
  active_lob1: any = [];
  lobBasedProduct: any = [];
  arra: any = [];
  amptemp: any = [];
  autoLoadData: any;
  plandropdownSettings: any;
  zonedropdownSettings: any;
  plan_data: any;
  zone_data: any;
  isShowplanZone: boolean = false;
  maxDate: any;
  modelstart: any;
  modelend: any;

  all_lob: any;
  parentproductbackup: any;
  check_lob: any;
  matserLocation: any;
  lumastersPayment: any;
  check: any = [];
  editable: boolean = true;
  dealerDetails = null;
  errorMsg = '';
  locationErrorMsg: boolean=false;
  modifiedUser: boolean =false;

  constructor(
    public globals: Globals,
    public service: BaseServiceService,
    public router: Router,
    public ActRoute: ActivatedRoute,
    public translate: TranslateService,
    private el: ElementRef,
    private toastr: ToastrService,
    private swalService: SwalService,
    private sessionStorageService: SessionStorageService
  ) {
    this.bntStyle = "btn-inactive";
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
  }

  userTypeList = [
    { id: 1, value: 'Partner Employee' },
    { id: 2, value: 'External POSP' }
  ]

  ngOnInit(): void {
    this.parent_code = this.sessionStorageService.getItem('parent_code');

    this.service.getParentPayMode(this.parent_code).subscribe(async (result) => {
      this.fetchpaymenttype.push(result);
      // console.log(this.fetchpaymenttype, 'this.fetchpaymenttype')

      this.getpayment = this.fetchpaymenttype[0].data.length > 0 ? this.fetchpaymenttype[0].data[0].payment_type.split(',') : [];

      this.paymentModeVal = this.fetchpaymenttype.data
      // console.log(this.paymentModeVal, 'paymentModeVal');
      if (this.paymentModeVal) {
        this.paymentModeVal = this.paymentModeVal[0].payment_type.split(',')
      } else {
        this.paymentModeVal = []
      }
    })

    this.service.firstname = this.sessionStorageService.getItem('first_name');
    this.service.email = this.sessionStorageService.getItem('email');
    this.service.loginrole = this.sessionStorageService.getItem('loginrole');
    this.model.ad_role = this.translate.instant("staticTs.dataEntryOperator")
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select",
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: "Unselect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      textField: "location",
      itemsShowLimit: 1,
      disabled: this.model.ad_Location && this.dealerDetails != null
    };

    this.lobdropdownSettings = {
      singleSelection: false,
      text: "Select",
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: "Unselect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      textField: "lob",
      itemsShowLimit: 1
    };
    this.plandropdownSettings = {
      singleSelection: false,
      text: "Select",
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: "Unselect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      textField: "prod_plan",
      itemsShowLimit: 1
    };
    this.zonedropdownSettings = {
      singleSelection: false,
      text: "Select",
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: "Unselect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      textField: "zone",
      itemsShowLimit: 1
    };

    this.productdropdownSettings = {
      singleSelection: false,
      text: "Select",
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: "Unselect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      textField: "product",
      itemsShowLimit: 1
    };

    this.paymentdropdownSettings = {
      singleSelection: false,
      text: "Select",
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: "Unselect All",
      enableSearchFilter: true,
      classes: "myclass custom-class",
      textField: "value",
      idField: "master_id",
      itemsShowLimit: 1
    };
    this.ActRoute.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0) { 
        this.modifiedUser = true;
      }
      this.userdata = params;
      this.Getlob()

      this.service.getAutoloadData({ broker_code: this.sessionStorageService.getItem('parent_code') }).subscribe(response => { //Display auto load  Date  based role 
        // console.log(this.zone_data, '######insert######')
        if (response.status === 0) {
          this.autoLoadData = response.data[0];

          this.role = response.data[0].role;
          this.service.getLobs().subscribe(async lob_role => {
            this.all_lob = lob_role.data;
            var lob = response.data[0].lob.split(',');
            if (lob[0] == '' || lob.length === 0) {
              this.temp_lob = [];
              // Swal.fire({
              //   title: this.translate.instant("staticTs.oops"),
              //   text: this.translate.instant("staticTs.lobMapped"),
              //   icon: 'error',
              //   showConfirmButton: false,
              //   showCloseButton: true,
              // })
              this.swalService.error(
                this.translate.instant("staticTs.lobMapped"),
                {
                  showConfirmButton: false,
                  showCloseButton: true,
                }
              );
              // Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.lobMapped"), this.translate.instant("staticTs.error"))
            } else {
              if (response.data[0].lob.toLowerCase() == 'all') {
                this.temp_lob = this.all_lob;
              } else {
                this.temp_lob = [];
                lob.forEach(element => {
                  var temp = this.all_lob.filter((obj) => obj.lob_value.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""))
                  if (temp[0]) {
                    this.temp_lob.push(temp[0]);
                  }

                })
              }
              // console.log(lob, '########### lob')

              if (this.temp_lob.length > 0) {

                var lobVal = response.data[0].lob.split(',');
                if (lobVal[0] == '' || lobVal.length === 0) {
                  // Swal.fire({
                  //   title: this.translate.instant("staticTs.oops"),
                  //   text: `This user has invalid lob marked via excel...${lobVal}`,
                  //   icon: 'error',
                  //   showConfirmButton: false,
                  //   showCloseButton: true,
                  // })
                  this.swalService.error(
                    `This user has invalid lob marked via excel...${lobVal}`,
                    {
                      showConfirmButton: false,
                      showCloseButton: true,
                    }
                  );
                  //Swal.fire(this.translate.instant("staticTs.oops"), `This user has invalid lob marked via excel...${lobVal}`, this.translate.instant("staticTs.error"))
                } else {
                  /************Lob Competed*****Rajesh************* */
                  /************** product ********************/
                  /*********LOCATION*************/
                  // this.temp_location = response.data[0].location.split(','); //jayaraman

                  let location = response.data[0].location.split(',');
                  let loc = [];
                  if (location && location.length > 0) {
                    location.forEach((elemet, index) => {
                      if (elemet) {
                        loc.push({ id: index + 1, location: elemet })
                      }

                    })
                  }

                  this.temp_location = loc;
                  // console.log(this.temp_location, 'this.temp_location1');
                  // this.model.ad_Location = response.data[0].location;
                  /**************payment*************/
                  // this.lumastersPayment = await new Promise((paymentresolve) => {
                  //   this.paymentModeValDetails(paymentresolve)
                  // })

                  this.service.masterRecord('um_payment_type').subscribe(payment => {
                    if (payment.status === 0) {
                      var split_bro = response.data[0].payment_type.split(',');
                      this.paymentModeVal = [];
                      split_bro.forEach(element => {
                        var temp = payment.data.filter((obj) => obj.value.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""))
                        if (temp[0]) {
                          this.paymentModeVal.push(temp[0]);
                        }

                      })
                      // console.log(this.paymentModeVal, "########payment get######")
                    } else if (payment.status === -114) {
                      ///Swal.fire(this.translate.instant("staticTs.oops"), payment.txt, this.translate.instant("staticTs.error"));
                      // Swal.fire({
                      //   title: this.translate.instant("staticTs.oops"),
                      //   text: payment.txt,
                      //   icon: 'error',
                      //   showConfirmButton: false,
                      //   showCloseButton: true,
                      // })
                      this.swalService.error(
                        payment.txt, 
                        {
                          showConfirmButton: false,
                          showCloseButton: true,
                        }
                      );
                    }
                    else {
                      //Swal.fire(this.translate.instant("staticTs.oops"), payment.txt, this.translate.instant("staticTs.error"));
                      // Swal.fire({
                      //   title: this.translate.instant("staticTs.oops"),
                      //   text: payment.txt,
                      //   icon: 'error',
                      //   showConfirmButton: false,
                      //   showCloseButton: true,
                      // })
                      this.swalService.error(
                        payment.txt,{
                          showConfirmButton: false,
                          showCloseButton: true,
                        }
                      )
                    }
                  }, err => {
                    //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
                    // Swal.fire({
                    //   title: this.translate.instant("staticTs.oops"),
                    //   text: this.translate.instant("staticTs.serverTimeout"),
                    //   icon: 'error',
                    //   showConfirmButton: false,
                    //   showCloseButton: true,
                    // })
                    this.swalService.error(
                      this.translate.instant("staticTs.serverTimeout"),
                      {
                         showConfirmButton: false,
                        showCloseButton: true,
                      }
                    );
                  })
                }
              } else {
                this.temp_lob = [];
                this.active_products = [];
                // Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.chooseDifferentParent"), this.translate.instant("staticTs.error"))
                // Swal.fire({
                //   title: this.translate.instant("staticTs.oops"),
                //   text: this.translate.instant("staticTs.chooseDifferentParent"),
                //   icon: 'error',
                //   showConfirmButton: false,
                //   showCloseButton: true,
                // })
                this.swalService.error(
                  this.translate.instant("staticTs.chooseDifferentParent"),
                  {
                    showConfirmButton: false,
                    showCloseButton: true,
                  }
                );
              }
            }
          })
          if (this.editable == true) {
            this.findEndDate(response.data[0].Start_date);
          }
        }
      });

      if (Object.keys(this.userdata).length != 0) {
        this.editable = false; // this for User type Feild Editmode disable 
        this.parent_code = this.sessionStorageService.getItem('parent_code');
        this.produce_code = this.sessionStorageService.getItem('producer_code');
        this.editedmode = params['email_id'];

        if (Object.keys(params).length !== 0) {
          this.pageMode = 1;
          this.service.DataEntryUserAdminSingle(params.email_id).subscribe(response => {
            // console.log(response, 'responsejayar');
            this.readonly = true;
            if (response.data[0].lob == 'undefined' || response.data[0].lob == undefined || response.data[0].lob == null || response.data[0].lob == 'null') {
              response.data[0].lob = '';
            }
            if (response.data[0].location == 'undefined' || response.data[0].location == undefined || response.data[0].location == null || response.data[0].location == 'null') {
              response.data[0].location = '';
            }
            if (response.data[0].payment_mode == 'undefined' || response.data[0].payment_mode == undefined || response.data[0].payment_mode == null || response.data[0].payment_mode == 'null') {
              response.data[0].payment_mode = '';
            }
            if (response.data[0].product == 'undefined' || response.data[0].product == undefined || response.data[0].product == null || response.data[0].product == 'null') {
              response.data[0].product = '';
            }
            if (response.data[0].external == 'undefined' || response.data[0].external == undefined || response.data[0].external == null || response.data[0].external == 'null') {
              response.data[0].external = '';
            }
            if (response.data[0].dealer_code == 'undefined' || response.data[0].dealer_code == undefined || response.data[0].dealer_code == null || response.data[0].dealer_code == 'null') {
              response.data[0].dealer_code = '';
            }
            if (response.data[0].login_id == 'undefined' || response.data[0].login_id == undefined || response.data[0].login_id == null || response.data[0].login_id == 'null') {
              response.data[0].login_id = '';
            }
            if (response.data[0].mobile_no == 'undefined' || response.data[0].mobile_no == undefined || response.data[0].mobile_no == null || response.data[0].mobile_no == 'null') {
              response.data[0].mobile_no = '';
            }

            if (response.status === 0) {
              this.readonly = true;
              if (response.data) {
                this.model.ad_role = this.translate.instant("staticTs.dataEntryOperator")
                this.model.ad_prcode = response.data[0].parent_code
                this.model.ad_prName = response.data[0].first_name
                this.model.ad_LoginID = response.data[0].login_id
                this.model.ad_email = response.data[0].email_id
                this.model.ad_mobile = response.data[0].mobile_no

                this.model.ad_transType = response.data[0].transaction_type

                this.model.ad_DealerCode = response.data[0].dealer_code

                this.model.ad_ProducerStatus = response.data[0].producer_status
                this.model.ad_startDate = this.excelToDate(response.data[0].start_Date)
                this.model.ad_endDate = this.excelToDate(response.data[0].end_date)
                this.model.ad_Revalidation = response.data[0].revalidation
                this.model.posp = response.data[0].external
                this.model.ad_DomainId = response.data[0].domain_id
                this.model.ad_ProStatus = response.data[0].producer_status
                this.model.ad_deo_status = response.data[0].deo_status
                this.model.posp_aadhaar = response.data[0].posp_aadhaar
                this.model.posp_pan = response.data[0].posp_pan

                // parent data get
                this.service.getAutoloadData({ broker_code: this.sessionStorageService.getItem('parent_code') }).subscribe(async parent => {

                  if (parent.status === 0) {

                    if (parent.data[0].lob == 'undefined' || parent.data[0].lob == undefined || parent.data[0].lob == null || parent.data[0].lob == 'null') {
                      parent.data[0].lob = '';
                    }
                    if (parent.data[0].location == 'undefined' || parent.data[0].location == undefined || parent.data[0].location == null || parent.data[0].location == 'null') {
                      parent.data[0].location = '';
                    }
                    if (parent.data[0].payment_type == 'undefined' || parent.data[0].payment_type == undefined || parent.data[0].payment_type == null || parent.data[0].payment_type == 'null') {
                      parent.data[0].payment_type = '';
                    }
                    if (parent.data[0].product == 'undefined' || parent.data[0].product == undefined || parent.data[0].product == null || parent.data[0].product == 'null') {
                      parent.data[0].product = '';
                    }

                    this.minDate = this.excelToDate(response.data[0].Start_date)
                    this.maxDate = this.excelToDate(response.data[0].End_Date)


                    this.service.getLobs().subscribe(async lob_role => {
                      this.all_lob = lob_role.data;

                      var lob = parent.data[0].lob.split(',');

                      if (lob[0] == '' || lob.length === 0) {
                        this.temp_lob = [];
                        //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.lobMapped"), this.translate.instant("staticTs.error"))
                        this.swalService.error(
                          this.translate.instant("staticTs.lobMapped"),
                        );
                      } else {
                        if (parent.data[0].lob.toLowerCase() == 'all') {
                          this.temp_lob = this.all_lob;
                        } else {
                          this.temp_lob = [];

                          lob.forEach(element => {
                            var temp = this.all_lob.filter((obj) => obj.lob_value.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""))
                            if (temp[0]) {
                              this.temp_lob.push(temp[0]);
                            }
                          })
                        }
                        if (this.temp_lob.length > 0) {
                          var lobVal = response.data[0].lob.split(',');
                          if (lobVal[0] == '' || lobVal.length === 0) {
                            //Swal.fire(this.translate.instant("staticTs.oops"), `This user has invalid lob marked via excel...${lobVal}`, this.translate.instant("staticTs.error"))
                            this.swalService.error(
                              `This user has invalid lob marked via excel...${lobVal}`,
                            );
                          } else {
                            if (response.data[0].lob.toLowerCase() == 'all') {
                              this.temp_lob.forEach((obj) => {
                                this.temp_lob.push(Object.assign(obj, { check: true }))
                              })
                            } else {
                              this.check_lob = [];
                              lobVal.forEach(element => {
                                var temp = this.temp_lob.filter((obj) => obj.lob_value.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""));
                                if (temp[0]) {
                                  this.check_lob.push(temp[0]);
                                }
                                if (element == 'travel' || element == 'lta') {
                                  this.isShowplanZone = true;
                                }
                              });
                              this.check = [];
                              this.check_lob.forEach((obj) => {
                                this.check.push(Object.assign(obj, { check: true }));
                                if (obj.check == true) {
                                  this.lobBasedProduct.push(obj.id);
                                }
                              })
                              this.temp_lob.map(obj => this.check.find(o => o.id === obj.id) || obj);
                            }

                            /************Lob Competed*****Rajesh************* */

                            /************** product ********************/

                            if (this.check.length > 0) {
                              var id = this.check.map((obj) => obj.id);
                              this.service.getLobProduct({ lob_id: id }).subscribe(async product_list => {
                                this.model.ad_product = [];
                                if (product_list.status === 0) {
                                  var temp_act_prod = [];
                                  temp_act_prod = product_list.data;
                                  var parentSelectedProducts = parent.data[0].product.split(',');
                                  if (parentSelectedProducts[0] == '' || parentSelectedProducts.length === 0) {
                                    //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.invalidProductMapped"), this.translate.instant("staticTs.error"))
                                    this.swalService.error(this.translate.instant("staticTs.invalidProductMapped"));
                                  } else {
                                    if (parent.data[0].product.toLowerCase() == 'all') {
                                      this.temp_product = product_list.data;
                                    } else {
                                      this.temp_product = [];
                                      //console.log(temp_act_prod, 'temp_act_prod');
                                      parentSelectedProducts.forEach(element => {
                                        var act_temp = temp_act_prod.filter((obj) => obj.product.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""))
                                        if (act_temp[0]) {
                                          this.temp_product.push(act_temp[0]);
                                        }
                                      });
                                      this.temp_product = this.temp_product.filter((obj) => obj != undefined)
                                    }
                                    var selectedProducts = response.data[0].product.split(',')
                                    this.parentproductbackup = this.temp_product;

                                    if (response.data[0].product.toLowerCase() == 'all') {
                                      this.model.ad_product = this.temp_product;
                                    } else {
                                      this.model.ad_product = [];
                                      selectedProducts.forEach(element => {
                                        var temp = this.temp_product.filter((obj) => obj.product_value.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""));
                                        if (temp[0]) {
                                          temp[0].checked = true;
                                          this.model.ad_product.push(temp[0])
                                        }
                                      });
                                      this.model.ad_product = this.model.ad_product.filter((obj) => obj != undefined)
                                    }
                                    // if (parent.data[0].plan == null || parent.data[0].plan.toLowerCase() == 'na') {
                                    // } else { //to remove empty block statement sonar
                                    if (!(parent.data[0].plan == null || parent.data[0].plan.toLowerCase() == 'na')) {
                                      var product_data = [];
                                      product_data = this.model.ad_product.map((obj) => obj.product)
                                      // commented bacause if else blocks are the same sonar
                                      // if (response.data[0].product.toLowerCase() == 'all') {
                                      //   product_data = this.model.ad_product.map((obj) => obj.product);
                                      // } else {
                                      //   product_data = this.model.ad_product.map((obj) => obj.product);
                                      // }
                                      var data = { "product": product_data };
                                      var plan_list = await new Promise((resolve) => {
                                        this.getAsPlan(data, resolve)
                                      })
                                      this.plan_data = plan_list;
                                      this.temp_product.forEach((product) => {
                                        this.plan_data.forEach((element, index) => {
                                          if (product.product.toLowerCase() === element.product.replace(/\s/g, '').toLowerCase()) { 
                                            element.lob_code = product.lob_code;
                                            element.planProductName = product.product;
                                          }
                                        })
                                      })
                                      if (response.data[0].plan !== "") {
                                        this.model.plan = [];
                                        if (response.data[0].plan.toLowerCase() == 'all') {
                                          this.model.plan = this.plan_data;
                                        } else {
                                          var plandata = response.data[0].plan.split(',');
                                          plandata.forEach((element) => {
                                            var temp = this.plan_data.filter((obj) => obj.prod_plan.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""))
                                            if (temp[0]) {
                                              temp[0].checked = true;
                                              this.model.plan.push(temp[0]);
                                            }
                                          });
                                        }
                                      }
                                    }
                                    // if (parent.data[0].zone == null || parent.data[0].zone.toLowerCase() == 'na') {
                                    // } else {  //empty blck statement sonar
                                    if (!(parent.data[0].zone == null || parent.data[0].zone.toLowerCase() == 'na')) {
                                      if (this.model.plan != undefined) {
                                        var plan_data = this.model.plan.map((obj) => obj.prod_plan);
                                        var data_plan = { "plan": plan_data };
                                        var zone_list = await new Promise((resolve) => {
                                          this.getAsZone(data_plan, resolve)
                                        })
                                        this.zone_data = zone_list;
                                        this.temp_product.forEach((product) => {
                                          this.zone_data.forEach((element, index) => {
                                            if (product.product.toLowerCase() === element.product.replace(/\s/g, '').toLowerCase()) { 
                                              element.lob_code = product.lob_code;
                                              element.planProductName = product.product;
                                            }
                                          })
                                         })
                                      }
                                      if (response.data[0].zone !== "") {
                                        this.model.zone = [];
                                        if (response.data[0].zone.toLowerCase() == 'all') {
                                          this.model.zone = this.zone_data;
                                        } else {
                                          var zonedata = response.data[0].zone.split(',');

                                          zonedata.forEach(element => {
                                            var temp = this.zone_data.filter((obj) => obj.zone.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""))
                                            if (temp[0]) {
                                              temp[0].checked = true;
                                              this.model.zone.push(temp[0]);
                                            }
                                          });
                                        }
                                      }
                                    }
                                  }

                                } else {
                                  //Swal.fire(this.translate.instant("staticTs.oops"), product_list.txt, this.translate.instant("staticTs.error"));
                                  this.swalService.error(product_list.txt);
                                }
                              })
                            } else {
                              console.log(`Invalid lob data`);
                            }

                            /*********LOCATION*************/
                            //this.temp_location = parent.data[0].location.split(','); //jayara

                            let location = parent.data[0].location.split(',');
                            let loc = [];
                            if (location && location.length > 0) {
                              location.forEach((elemet, index) => {
                                if (elemet) {
                                  loc.push({ id: index + 1, location: elemet })
                                }
                              })
                            }
                            this.temp_location = loc;
                            let res = response.data[0].location.split(',');
                            let redDate = [];
                            if (res && res.length > 0) {
                              res.forEach(element => {
                                let Data = this.temp_location.filter(resItem => resItem.location.toLowerCase() == element.toLowerCase());
                                redDate.push(Data[0])
                              });
                            }

                            this.model.ad_Location = redDate;

                            /**************payment*************/

                            this.lumastersPayment = await new Promise((paymentresolve) => {
                              this.paymentModeValDetails(paymentresolve)
                            })
                            if (this.lumastersPayment.length > 0) {
                              var par_pay = parent.data[0].payment_type.split(',');
                              this.paymentModeVal = [];
                              if (parent.data[0].payment_type.toLowerCase() == 'all') {
                                this.paymentModeVal = this.lumastersPayment;
                              } else {
                                par_pay.forEach(element => {
                                  var temp = this.lumastersPayment.filter((obj) => obj.value.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""))
                                  if (temp[0]) {
                                    this.paymentModeVal.push(temp[0]);
                                  }
                                })
                                console.log(this.paymentModeVal, '#######payval#####')

                              }
                              if (response.data[0].payment_mode.toLowerCase() == 'all') {
                                this.model.ad_PaymentMode = this.paymentModeVal;
                              } else {
                                this.model.ad_PaymentMode = [];
                                var de_split = response.data[0].payment_mode.split(',');
                                de_split.forEach(element => {
                                  var temp = this.paymentModeVal.filter((obj) => obj.value.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""))
                                  if (temp[0]) {
                                    temp[0].checked = true;
                                    this.model.ad_PaymentMode.push(temp[0]);
                                  }
                                })
                                console.log(this.model.ad_PaymentMode, '###########')
                              }
                            } else {
                              //(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
                              this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
                            }
                          }
                        } else {
                          this.temp_lob = [];
                          this.active_products = [];
                          //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.chooseDifferentParent"), this.translate.instant("staticTs.error"))
                          this.swalService.error(this.translate.instant("staticTs.chooseDifferentParent"));
                        }
                      }
                    })
                  } else {
                    //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.invalidParent"), this.translate.instant("staticTs.error"))
                    this.swalService.error(this.translate.instant("staticTs.invalidParent"));
                  }
                })
              }
            } else if (response.status === -114) {
              //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
              this.swalService.error(response.txt);
              this.sessionStorageService.clear();
              this.router.navigateByUrl(`/login`);
            } else {
              //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
              this.swalService.error(response.txt);
            }
          }, err => {
            //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
            this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
          })
        }

        // For editing the form
        if (this.editedmode.length != 0) {
          var starteddate = params['start_Date']
          starteddate = starteddate.split("/").reverse().join("/");
          starteddate = `"${starteddate}"`
          var getstrt = new Date(starteddate);

          var endeddate = params['end_date']
          endeddate = endeddate.split("/").reverse().join("/");
          endeddate = `"${endeddate}"`
          var getend = new Date(endeddate);

          this.parent_code = this.sessionStorageService.getItem('parent_code');

          this.userData.control.patchValue(
            this.model = {
              email: params['email_id'],
              ad_mobile: params['mobile_no'],
              ad_Revalidation: params['revalidation'],
              ad_startDate: { year: getstrt.getFullYear(), month: getstrt.getMonth() + 1, day: getstrt.getDate() },
              ad_endDate: { year: getend.getFullYear(), month: getend.getMonth() + 1, day: getend.getDate() },
              ad_DealerCode: params['dealer_code'],
              ad_prName: params['first_name'],
              posp: params['external'],
              ad_LoginID: params['login_id'],
              ad_transType: params['transaction_type'],
              ad_paymentModeVal: params['payment_mode']
            },
          );
          this.minDate = { //jay  --/ in edit mode  star date as min date  
            year: this.model.ad_startDate.year,
            month: this.model.ad_startDate.month,
            day: this.model.ad_startDate.day,
          };
        }
      }
      else {
        this.pageMode = 0;
        this.model.ad_ProducerStatus = '0';
        this.model.ad_DataEntryUserType = this.userTypeList[0].value;
        this.parent_code = this.sessionStorageService.getItem('parent_code');
        this.model.ad_prcod = this.sessionStorageService.getItem('parent_code');
      }
    });

    this.service.singleproducer({ data: this.sessionStorageService.getItem('email') }).subscribe(active_brokers => {
      if (active_brokers.status === 0) {
        this.producerStartDate = this.excelToDate(active_brokers.data[0].Start_date);
        this.minDate = this.excelToDate(active_brokers.data[0].Start_date);
        this.maxDate = this.excelToDate(active_brokers.data[0].End_Date);
        this.active_brokers = active_brokers.data;

        console.log(this.maxDate, 'this.maxDate');
        this.temp_location = [];

        if (Object.keys(this.userdata).length != 0) {
          var selectedlocation = this.userdata.location.split(',');
          var temp_loc = [];
          if (selectedlocation && selectedlocation.length > 0) {
            selectedlocation.forEach((productvalue) => {
              var sample = this.temp_location.filter((obj) => obj.location == productvalue);
              if (sample[0] !== undefined) {
                temp_loc.push(sample[0]);
              }
            })
          }
          this.selectedlocation = temp_loc;
          // edit form  lob
          this.model.ad_lob = this.fetchlob[0].lob.split(',');
          var storetemp_lob = [];
          this.model.ad_lob.forEach((productvalue) => {
            var lob_get = this.temp_lob.filter((obj) => obj.lob == productvalue);
            if (lob_get[0]) {
              storetemp_lob.push(lob_get[0]);
            }
          })
          this.model.ad_lob = storetemp_lob;

          this.model.ad_lob = storetemp_lob;

          var selectedproducts = this.userdata.product.split(',');

          var temp = [];
          selectedproducts.forEach((productvalue) => {

            var sample = this.temp_product.filter((obj) => obj.product == productvalue);
            if (sample[0]) {
              temp.push(sample[0]);

            }
          })
          this.selectedproducts = temp;
          // var testtemp = this.temp_lob.find((obj) => obj.lob_value === 'lta' || obj.lob_value === 'travel');
        }
      } else if (active_brokers.status === -114) {
        //Swal.fire(this.translate.instant("staticTs.oops"), active_brokers.txt, this.translate.instant("staticTs.error"));
        this.swalService.error(active_brokers.txt);
        this.sessionStorageService.clear();
        this.router.navigateByUrl(`/login`);

      } else {
        //Swal.fire(this.translate.instant("staticTs.oops"), active_brokers.txt, this.translate.instant("staticTs.error"));
        this.swalService.error(active_brokers.txt);
      }
    }, err => {
      //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
      this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
    })
  }

  excelToDate(apiDate) {
    var fDate;

    if (apiDate != '' && apiDate != null && apiDate != undefined) {
      apiDate = apiDate.split('/');
      fDate = {
        "year": parseInt(apiDate[2]),
        "month": parseInt(apiDate[1]),
        "day": parseInt(apiDate[0]),
      }
    } else {
      fDate = "";
    }
    return fDate;
  }



  /*****************GET ALL PAYMENT******************/
  paymentModeValDetails(resolve) {
    this.service.masterRecord('um_payment_type').subscribe(response => {
      if (response.status === 0) {
        resolve(response.data);
      } else if (response.status === -114) {
        //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
        this.swalService.error(response.txt);
      }
      else {
        //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
        this.swalService.error(response.txt);
      }
    }, err => {
      //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
      this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
    })
  }

  onItemSelectPayment(event, i, payment) {
    if (this.model.ad_PaymentMode == undefined) {
      this.model.ad_PaymentMode = []
     }
    if (event) {
      let itemCheck = event.target.checked;
      if (itemCheck) {
        this.paymentModeVal[i].checked = true;
        this.model.ad_PaymentMode.push(payment)
      } else {
        this.paymentModeVal[i].checked = false;
        this.model.ad_PaymentMode = this.model.ad_PaymentMode.filter(function(item) {
          return payment.value !== item.value
        })
      }
    }
  }

  validateLocation(){
    if(this.model.ad_Location== undefined || this.model.ad_Location?.length == 0){
      this.locationErrorMsg = true;
      this.submit=false;
      return;
    }else{
      this.locationErrorMsg = false;
    }
  }

  saveUser() {

    if(this.startingNo) {
      this.toastr.error('Please enter valid mobile number');
      return;
    }

    if(this.model.ad_Location== undefined || this.model.ad_Location?.length == 0){
      this.locationErrorMsg = true;
      this.submit=false;
      return;
    }else{
      this.locationErrorMsg = false;
    }
    if (this.model.ad_product === undefined || this.model.ad_product?.length == 0) { 
      this.toastr.error('Please select atleast one product', '');
      return;
    }
    
    if (this.model.ad_PaymentMode === undefined || this.model.ad_PaymentMode?.length == 0) { 
      this.toastr.error('Please select atleast one payment mode', '');
      return;
    }

    if (this.userData.value.Revalidation == undefined) {
      this.userData.value.Revalidation = 'Active';
    }
    this.submit = true;
    if (this.userData.valid && this.errorMsg == '') {

      var product_value = this.model.ad_product.map((obj) => obj.product);
      var payment_value = this.model.ad_PaymentMode.map((obj) => obj.value);
      console.log(payment_value, "############pay")
      console.log(this.model.ad_Location, 'this.model.ad_Location')
      if(this.model.ad_Location != undefined){
        for (let i = 0; i <= this.model.ad_Location.length; i++) {
          if (this.model.ad_Location[0] != undefined) {
            var locationdata = this.model.ad_Location ? this.model.ad_Location.map((obj) => obj.location) : "";
          }
        }
      }
      var zonedata = this.model.zone ? this.model.zone.map((obj) => obj.zone) : "";
      var plandata = this.model.plan ? this.model.plan.map((obj) => obj.prod_plan) : "";
      // var psop_aadhar = this.userData.value.psopaadhar ? this.userData.value.psopaadhar : "";  //psop change
      var psop_aadhar = this.model.posp_aadhaar ? this.model.posp_aadhaar : "";  //psop change
      //var posp_pan = this.userData.value.psoppan ? this.userData.value.psoppan : "";
      var posp_pan = this.model.posp_pan ? this.model.posp_pan : "";


      var temp = [];
      this.temp_lob.forEach(element => {


        if (element.check == true) {
          temp.push(element);

        }
      });

      var lob_value = temp.map((el) => { return el.lob_value });
      var get_email_domain = this.userData.value.email.split('@');
      get_email_domain = '@' + get_email_domain[1];

      var activity_by = this.sessionStorageService.getItem('email');
      var editmode = (this.editedmode != '' && this.editedmode != undefined) ? '1' : '0';

      var formdata = {
        "ad_role": this.translate.instant("staticTs.dataEntryOperator"),
        //"ad_DataEntryUserType": `${this.userData.value.DataEntryUserType}`,
        "ad_DataEntryUserType": `${this.model.ad_DataEntryUserType}`,
        "ad_prcode": `${this.parent_code}`,
        //"ad_prName": `${this.userData.value.prName}`,
        "ad_prName": `${this.model.ad_prName}`,
        "ad_LoginID": `${this.userData.value.LoginID}`,
        "ad_email": `${this.userData.value.email}`,
        "ad_mobile": `${this.userData.value.mobile}`,
        "ad_product": `${product_value}`,
        "ad_transType": `${this.userData.value.transType}`,
        "ad_Location": `${locationdata}`,
        "ad_DealerCode": `${this.userData.value.DealerCode != undefined ? this.userData.value.DealerCode : ''}`,
        "ad_PaymentMode": `${payment_value}`,
        "ad_ProducerStatus": `0`,
        "ad_Revalidation": `${this.userData.value.Revalidation}`,
        "ad_startDate": `${this.userData.value.start.day}/${this.userData.value.start.month}/${this.userData.value.start.year}`,
        "ad_endDate": `${this.userData.value.endDate.day}/${this.userData.value.endDate.month}/${this.userData.value.endDate.year}`,
        "domain": `${get_email_domain}`,
        //"ad_posp": `${this.userData.value.posp}`,
        "ad_posp": `${this.model.posp}`,
        "lob": `${lob_value}`,
        "plan": `${plandata}`,
        "zone": `${zonedata}`,
        "producer_status": `${this.userData.value.pro_status}`,
        "domain_id": `${this.userData.value.domain_id}`,
        //"deo_status": `${this.userData.value.deo_status}`,
        "deo_status": `${this.model.ad_deo_status}`,
        "posp_aadhaar": `${psop_aadhar}`,
        "posp_pan": `${posp_pan}`,
        "instance": "1",
        "param": `${editmode}`,
        "activity_done_by": `${activity_by}`,
        "client": "IPDSV2"
      }
      console.log(this.userData.value.location, 'this.userData.value.location');

      this.service.DataEntryUserAdminInsert(formdata).subscribe(response => {

        if (response.status === 0) {
          if (this.modifiedUser) { 
            //Swal.fire(this.translate.instant("staticTs.success.swal"), this.translate.instant("staticTs.dataEntryUserUpdated"), this.translate.instant("staticTs.success"));
            this.swalService.success(this.translate.instant("staticTs.dataEntryUserUpdated"));
          } else {
            //Swal.fire(this.translate.instant("staticTs.success.swal"), this.translate.instant("staticTs.dataEntryUserCreated"), this.translate.instant("staticTs.success"));
            this.swalService.success(this.translate.instant("staticTs.dataEntryUserCreated"));
          }
          
          this.router.navigateByUrl(`/user-mgmt`);

        } else if (response.status === -114) {
          //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
          this.swalService.error(response.txt);
          this.sessionStorageService.clear();
          this.router.navigateByUrl(`/login`);

        } else {
          //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
          this.swalService.error(response.txt);
        }
      }, err => {
        //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
        this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
      })

    }
    // else {
      // this.globals.autoFocusOnError(this.userData.form, this.el);
      // Swal.fire({
      //   title: this.translate.instant("staticTs.oops"),
      //   text: this.translate.instant("staticTs.fillMandatory"),
      //   icon: 'error',
      //   showConfirmButton: false,
      //   showCloseButton: true,
      // })
      // Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.fillMandatory"), this.translate.instant("staticTs.error"))
    // }
  }

  Getlob() {
    this.service.DataEntryUser(this.userdata.email_id).subscribe(res => {
      if (res.data.length > 0) {

        this.model.ad_DataEntryUserType = res.data[0].user_type
        this.fetchlob = res.data

        this.selectedproducts = res.data[0].product;

        this.temp_product = [];
        this.temp_lob = [];
        var product_value = (res.data[0].product).split(',');
        for (var i = 0; i < product_value.length; i++) {
          var temp = {
            "id": i,
            "product": product_value[i],
            "product_value": product_value[i],

          };

          this.temp_product.push(temp);
        }

        this.selectedproducts = [];
        var product = res.data[0].product.split(',');

        product.forEach(element => {
          var tempFil = this.temp_product.filter((obj) => obj.product_value == element)
          if (tempFil[0]) {
            this.selectedproducts.push(tempFil[0]);
          }

        });

        //....

        var lob_value = (res.data[0].lob).split(',');
        for (var ind = 0; ind < lob_value.length; ind++) {
          var temp1 = {
            "id": ind,
            "lob": lob_value[ind],
            "lob_value": lob_value[ind],
            "check": true
          };
          this.temp_lob.push(temp1);
        }
      }

      this.setLevel(this.tempArr, 'test')

      this.service.singleproducer({ data: this.sessionStorageService.getItem('email') }).subscribe(active_brokers => {

        if (active_brokers.status === 0) {

          this.active_brokers = active_brokers.data;

          var resVal = active_brokers.data[0]; //Duplicate
          this.producerStartDate = resVal.Start_date;
          this.producerEndDate = resVal.End_Date;
          
          if (this.editable == true) {
            this.model.ad_startDate = moment(new Date()).format('DD/MM/YYYY');
            //this.endDateCalcuation(resVal.Start_date, resVal.End_Date);
            this.endDateCalcuation(this.model.ad_startDate, resVal.End_Date);
          }

          let location = active_brokers.data[0].location.split(',');
          let loc = [];
          if (location && location.length > 0) {
            location.forEach((elemet, index) => {
              if (elemet) {
                loc.push({ id: index + 1, location: elemet })
              }
            })
          }

          this.temp_location = loc;

          this.model.ad_DomainId = active_brokers.data[0].domain;
          this.model.ad_ProStatus = active_brokers.data[0].status;


          if (Object.keys(this.userdata).length !== 0) {

            const activeBrokerLobs = active_brokers.data[0].lob.split(',');

            var lobdata = [];
            activeBrokerLobs.forEach((element, index) => {
              if (element) {
                var obj = {
                  "id": this.temp_lob[index].id,
                  "lob": element,
                  "lob_value": element,
                  "check": false
                };
                lobdata.push(obj);
              }

            });

            this.temp_lob = lobdata;
            var newArr = []
            lob_value.forEach(element => {
              var temp1 = this.temp_lob.filter((obj) => obj.lob_value == element)

              if (temp1[0]) {
                newArr.push(temp1[0]);
              }

            });


            var testNew = [];
            newArr.forEach(element => {
              var chekVal = this.temp_lob.filter((obj) => obj.lob_value == element.lob_value);
              if (chekVal[0]) {
                testNew.push(chekVal[0]);
              }
              var idx = this.temp_lob.findIndex((indx) => indx.lob_value == element.lob_value);
              if (idx != -1) {

                this.temp_lob[idx].check = true;
              }

            });
          }
        }
      })
    })
  }

  lobSelect(event) {
    event.preventDefault();
  }


  // Jayaraman --- Change lob Button To Chekbox
  public tempArr = []
  lobSelectChange(event, i, valu) {
    var _IndexOfLob = i;
    var lob_id = valu.id;
    var checked = event.target.checked;
    
    var lobVal = event.target.value;
    if (valu.lob_value.toLowerCase() === 'lta' || valu.lob_value.toLowerCase() === 'travel') {
      this.isShowplanZone = !valu.check;
    }

    if (checked) {
      this.temp_lob[i].check = true;
      this.toggleAccordian(i, true);

      this.tempArr.push(lobVal)
      this.tempArr = this.tempArr.filter((test, index, array) =>

        index === array.findIndex((findTest) => findTest === test)
      );


    } else {
      this.temp_lob[i].check = false;
      this.toggleAccordian(i, false);
      for (let i = 0; i <= this.tempArr.length; i++) {
        if (this.tempArr[i] == this.temp_lob[_IndexOfLob].lob) {
          this.tempArr.splice(i, 1);

        }
      }
      for (let i = 0; i <= this.lobBasedProduct.length; i++) {
        if (this.lobBasedProduct[i] == valu.id) {
          this.lobBasedProduct.splice(i, 1)
        }
      }

      this.model.ad_product = this.model.ad_product ? this.model.ad_product.filter((obj) => obj.lob_id != lob_id) : undefined;
    }

    this.setLevel(this.tempArr, valu)
  }

  toggleAccordian(i, key) {
    let accBtn = document.getElementById('accordionBtn' + i)
    if (accBtn) {
      if (key) {
        accBtn.click();
      } else {
        if (accBtn.getAttribute('aria-expanded') !== 'false') {
          accBtn.click()
        }
      }
    }
  }

  async setLevel(lobVal, valu) { // Jayaraman .. lob based product Loading temp_lob
    this.service.getLobs().subscribe(lob_role => {
      if (lob_role.status === 0) {
        this.active_lob = lob_role.data;
        this.active_lob1 = lob_role.data; //duplicate 

        this.arra = [];
        var temp = [];

        this.active_lob1.forEach(element => {
          if (element.check == true) {
            temp = this.active_lob.filter((obj) => obj.lob_value == element.lob_value);
            if (temp[0]) {
              this.arra.push(temp[0]);
            }
          }
        });

        if (valu.check == true) {
          this.lobBasedProduct.push(valu.id);
        }

        let lobid = this.lobBasedProduct.filter(element => { return element != undefined })
        let flobid = lobid.filter((value, index) => lobid.indexOf(value) == index)

        console.log(flobid, 'flobid');

        this.service.getLobProduct({ lob_id: flobid }).subscribe(response => {
          if (response.status === 0) {
            if (response.data == "") {
              this.temp_product = [];
              this.selectedproducts = [];
            } else {

              var tempdata = response.data;
              this.service.singleproducer({ data: this.sessionStorageService.getItem('email') }).subscribe(result => {
                if (result.status === 0) {
                  var data = result.data[0];
                  var resproduct = data.product.split(',');
                  if (resproduct != "" && resproduct != undefined) {
                    var productVal = [];
                    resproduct.forEach(element => {
                      var tem = tempdata.filter((obj) => obj.product_value.toLowerCase().replace(/\s/g, "") == element.toLowerCase().replace(/\s/g, ""));

                      if (tem.length > 0 && tem) {
                        productVal.push(tem[0]);
                      }
                    });
                    productVal.forEach((ele) => { 
                      this.temp_product.forEach((prod) => { 
                        if (prod.id === ele.id) {
                          ele.checked = prod.checked;
                        }
                      })
                    })
                    this.temp_product = productVal;
                    console.log(this.temp_product, '############## @@@ product');
                  }
                }
              })

              if (this.selectedproducts.length > 0 && this.selectedproducts != undefined && valu != 'test') {
                var lstTemp = [];
                this.temp_product.forEach(element => {
                  var fval = this.selectedproducts.filter((obj) => (obj.product_value.replace(/\s/g, '')).toLowerCase() == element.product_value);
                  if (fval.length > 0) {
                    lstTemp.push(fval[0])
                  }
                });

                this.selectedproducts = [];
                this.selectedproducts = lstTemp; //temp[0];//this.amptemp;
              }
            }
          } else {
            //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"))
            this.swalService.error(response.txt);
          }
        }, err => {
          //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
          this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
        })
      } else if (lob_role.status === -114) {
        //Swal.fire(this.translate.instant("staticTs.oops"), lob_role.txt, this.translate.instant("staticTs.error"));
        this.swalService.error(lob_role.txt);
        this.sessionStorageService.clear();
        this.router.navigateByUrl(`/login`);

      }
      else {
        //Swal.fire(this.translate.instant("staticTs.oops"), lob_role.txt, this.translate.instant("staticTs.error"))
        this.swalService.error(lob_role.txt);
      }
    }, err => {
      //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
      this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
    })
  }

  setDate(stardate) {
    var startDate = stardate.year + '-' + stardate.month + '-' + stardate.day;
    var date = moment(startDate);
    var dd = moment(date).add(90, "days").calendar();
    var endDate = moment(dd)
    var yy = endDate.year();
    var MM = endDate.month() + 1;
    var dd1 = endDate.date(); //dd changed to dd1, dd already define sonar
    var data = { year: yy, month: MM, day: dd1 };
    return data;
  }
  findEndDate(stardate) {  //Jay  / auto calculate 90 days for start to  endate ---> NOTE : NEED TO REDUCE CODE 

    this.model.ad_endDate = this.setDate(stardate);
    // bug fixing for end date calculation
    // this.finalLogic(1);

  }
  finalLogic(type) {
    if (modelstartDate) {
      var modelstartDate;
      if (type == 1) {
        modelstartDate = this.model.ad_startDate.year + '/' + this.model.ad_startDate.month + '/' + this.model.ad_startDate.day;
      } else {
        modelstartDate = this.modelstart.year + '/' + this.modelstart.month + '/' + this.modelstart.day;
      }
      var modelEndDate = this.modelend.year + '/' + this.modelend.month + '/' + this.modelend.day;
    }
    var diffDays = this.calculateTwoDate(modelstartDate, modelEndDate);
    console.log(diffDays, 'diffDays', this.modelstart);

    if (diffDays > 90) {
      this.model.ad_endDate = this.setDate(this.model.ad_startDate);
      this.maxDate = this.setDate(this.modelstart);

    } else {
      this.model.ad_endDate = (this.modelend);
      this.maxDate = (this.modelend)

    }
  }
  formatCahnge(date) {
    if (date != undefined) {
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var yyyy = date.getFullYear();

      var formatedDate = { year: yyyy, month: mm, day: dd };
      return formatedDate;
    }

  }
  calculateTwoDate(val1, val2) {
    const date1: any = new Date(val1);
    const date2: any = new Date(val2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  dateFormatChange(value) {
    if (value) {
      var dd = value.split('/');
      var changeDate = dd[2] + '/' + dd[1] + '/' + dd[0];
      return changeDate;
    }
  }
  endDateCalcuation(startDate, endDate) {
    if (this.role === 'Producer' || this.role.toLowerCase().replace(/\s/g, '') === 'subproducer') {  //Must Need Data format Should be MM/DD/ YYYY

      var date1 = new Date(this.dateFormatChange(startDate));
      var date2 = new Date(this.dateFormatChange(endDate));


      this.model.ad_startDate = this.formatCahnge(date1);
      this.model.ad_endDate = this.setDate(this.model.ad_startDate)  //this.formatCahnge(date2);

      this.minDate = this.formatCahnge(date1);

      this.modelstart = this.formatCahnge(date1) //DUplicate
      this.modelend = this.formatCahnge(date2) //Duplicate

    } else {
      var date3 = new Date(startDate);
      var date4 = new Date(endDate);
      this.model.ad_startDate = this.formatCahnge(date3);
      this.model.ad_endDate = this.formatCahnge(date4);
      this.maxDate = this.formatCahnge(date4);
      console.log(this.maxDate, ' this.maxDate');


      this.modelstart = this.formatCahnge(date3); //DUplicate
      this.modelend = this.formatCahnge(date4); //Duplicate

      this.finalLogic(0);
    }
  }

  /****************************Product Based plan******************************************/

  OnItemSelectPro(event?, i?, product?) {
    if (this.model.ad_product == undefined) {
      this.model.ad_product = []
     }
    if (event) {
      let itemCheck = event.target.checked;
      if (itemCheck) {
        this.temp_product[i].checked = true;
        this.model.ad_product.push(product)
      } else {
        this.temp_product[i].checked = false;
        
        this.model.ad_product = this.model.ad_product.filter(function(item) {
          return product.id !== item.id
        })

        if (this.model.ad_product.length < 1) {
          this.plan_data = [];
          this.zone_data = [];
        } 
      }
    }

    this.setLevelPro();
  }

  // Product
      // plan

  OnItemDeSelectPro(item: any) {
    this.setLevelPro();
  }
  onSelectAllPro(item: any) {
    this.setLevelPro();
  }
  onDeSelectAllPro(item: any) {
    this.setLevelPro();
  }

  checkProduct(product, key) {
    if (product.lob_id !== undefined && key.id !== undefined)
      return product.lob_id.toString() === key.id.toString();    
    
    return false;
  }

  checkPlans(plan, key) {
    if (plan.lob_code !== undefined && key.lob_code !== undefined) {
      if (plan.lob_code === key.lob_code)
        return plan.product.replace(/\s/g, '').toLowerCase() === plan.planProductName.toLowerCase();  
    }
    return false;
  }

  setLevelPro() {
    if (this.model?.ad_product?.length) {
      var result = this.model.ad_product.map((obj) => obj.product);
      var data = { "product": result };
      this.service.get_product_based_plan(data).subscribe(response => {
        if (response.status === 0) {
          if (response.data.length > 0) {
            this.plan_data = [];
            var seen = new Set();
            var filteredArr = response.data.filter(el => {
              var duplicate = seen.has(el.prod_plan);
              seen.add(el.prod_plan);
              return !duplicate;
            });
            
            this.model.ad_product.forEach((product) => {
              filteredArr.forEach((element, index) => {
                if (product.product.toLowerCase() === element.product.replace(/\s/g, '').toLowerCase()) { 
                  element.lob_code = product.lob_code;
                  element.planProductName = product.product;
                }

                if (this.model.plan !== undefined) { 
                  this.model.plan.forEach((prod) => { 
                    if (prod.id === element.id) {
                      element.checked = prod.checked;
                    }
                  })
                }
              })
            })
            
            this.plan_data = filteredArr;
            if (this.model.plan == undefined || this.model.plan.length == 0) {
              this.model.plan = [];
            } else {
              var activeplan = [];
              this.model.plan.forEach((ele) => {
                var temp = this.plan_data.filter((obj) => obj.prod_plan.toLowerCase().replace(/\s/g, "") == ele.prod_plan.toLowerCase().replace(/\s/g, ""))
                if (temp[0]) {
                  activeplan.push(temp[0]);
                }
              })

              this.model.plan = activeplan;
            }

          } else {
            this.plan_data = [];
          }
        } else {
          //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"))
          this.swalService.error(response.txt);
        }
      })
    } else {
      this.plan_data = [];
      this.model.plan = [];
    }

  }

  getAsPlan(data, resolve) {
    this.service.get_product_based_plan(data).subscribe(response => {
      if (response.status === 0) {
        var seen = new Set();
        var filteredArr = response.data.filter(el => {
          var duplicate = seen.has(el.prod_plan);
          seen.add(el.prod_plan);
          return !duplicate;
        });

        resolve(filteredArr);
      } else if (response.status === -114) {
        this.plan_data = [];
      }
      else {
        //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
        this.swalService.error(response.txt);
      }
    }, err => {
      //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
      this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
    })
  }

  /****************************End Product Based plan******************************************/

  /****************************plan Based zone******************************************/

  OnItemSelectPlan(event: any, i?, plan?) {
    if (this.model.plan == undefined) {
      this.model.plan = []
     }
    if (event) {
      let itemCheck = event.target.checked;
      if (itemCheck) {
        this.plan_data[i].checked = true;
        this.model.plan.push(plan)
      } else {
        this.plan_data[i].checked = false;
        this.model.plan = this.model.plan.filter(function(item) {
          return plan.id !== item.id
        })

        if (this.model.plan.length < 1) {
          this.zone_data = [];
        } 
      }
    }
    this.setLevelPlan();
  }

  onItemSelectZone(event: any, i?, zone?) {
    if (this.model.zone == undefined) {
      this.model.zone = []
    }
    if (event) {
      let itemCheck = event.target.checked;
      if (itemCheck) {
        this.zone_data[i].checked = true;
        this.model.zone.push(zone)
      } else {
        this.zone_data[i].checked = false;
        this.model.zone = this.model.zone.filter(function(item) {
          return zone.id !== item.id
        })
      }
    }
  }

  OnItemDeSelectPlan(item: any) {
    this.setLevelPlan();
  }
  onSelectAllPlan(item: any) {
    this.setLevelPlan();
  }
  onDeSelectAllPlan(item: any) {
    this.setLevelPlan();
  }

  checkZone(zone, key) { 
    if (zone.lob_code === key.lob_code)
      return zone.product.replace(/\s/g, '').toLowerCase() === zone.planProductName.toLowerCase();
    
    return false;
    
  }

  setLevelPlan() {
    if (this.model?.plan?.length > 0) {
      var result = this.model.plan.map((obj) => obj.prod_plan);
      var data = { "plan": result };
      this.service.get_plan_based_zone(data).subscribe(response => {
        if (response.status === 0) {
          if (response.data.length > 0) {
            this.zone_data = [];
            var seen = new Set();
            var filteredArr = response.data.filter(el => {
              var duplicate = seen.has(el.zone);
              seen.add(el.zone);
              return !duplicate;
            });

            this.model.ad_product.forEach((product) => {
              filteredArr.forEach((element, index) => {
                if (product.product.toLowerCase() === element.product.replace(/\s/g, '').toLowerCase()) { 
                  element.lob_code = product.lob_code;
                  element.planProductName = product.product;
                }

                if (this.model.zone !== undefined) { 
                  this.model.zone.forEach((zone) => { 
                    if (zone.zone === element.zone) {
                      element.checked = zone.checked;
                    }
                  })
                }
              })
             })

            this.zone_data = filteredArr;

            if (this.model.zone == undefined || this.model.zone.length == 0) {
              this.model.zone = [];

            } else {

              var activezone = [];
              this.model.zone.forEach((ele) => {
                var temp = this.zone_data.filter((obj) => obj.zone.toLowerCase().replace(/\s/g, "") == ele.zone.toLowerCase().replace(/\s/g, ""))
                if (temp[0]) {
                  activezone.push(temp[0]);
                }
              })

              this.model.zone = activezone;
            }
          } else {
            this.zone_data = [];
          }
        } else {
          //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"))
          this.swalService.error(response.txt);
        }
      })
    } else {
      this.zone_data = [];
      this.model.zone = [];
    }
  }

  getAsZone(data, resolve) {
    this.service.get_plan_based_zone(data).subscribe(response => {
      if (response.status === 0) {
        var seen = new Set();
        var filteredArr = response.data.filter(el => {
          var duplicate = seen.has(el.zone);
          seen.add(el.zone);
          return !duplicate;
        });

        resolve(filteredArr);
      } else if (response.status === -114) {
        this.plan_data = [];
      }
      else {
        //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
        this.swalService.error(response.txt);
      }
    }, err => {
      //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
      this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
    })

  }


  /****************************End plan Based zone******************************************/

  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  getDealerDetails(event) {
    this.errorMsg = '';
    this.dealerDetails = null;
    if (event.target.value.length >= 1 && event.target.value.length < 4) {
      this.errorMsg = this.translate.instant("staticTs.enterMin4Characters")
      return;
    }

    let data = {
      "producer_code": this.sessionStorageService.getItem('producer_code'),
      "dealer_code": this.model.ad_DealerCode,
      "office_location": this.sessionStorageService.getItem('location_code'),
      "source": "BAM"
    };
    if (event.target.value.length >= 4) {
      this.errorMsg = ''
      this.service.get_dealer_details(data).subscribe(async response => {
        if (response.status === 0) {
          if (response.data.length > 0) {
            this.dealerDetails = response.data[0];
            let locationCode = response.data[0].office_location;

            await new Promise((resolve) => {
              this.service.get_location_name(locationCode).subscribe(response => {
                if (response.status === 0) {
                  resolve(response.data);
                  this.dealerDetails.location_name = response.data[0].txt_office;
                } else {
                  //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
                  this.swalService.error(response.txt);
                }
              })
            })
            this._AutoFillFormDetails(this.dealerDetails);
          }
          if (response.data.length == 0) {
            this.errorMsg = this.translate.instant("staticTs.noDealerDetails")
            //Swal.fire(this.translate.instant("staticTs.noDataFound"), this.translate.instant("staticTs.noDealerDetails"), this.translate.instant("staticTs.error"));
            this.swalService.error(this.translate.instant("staticTs.noDealerDetails"));
          }
        }
        else {
          //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
          this.swalService.error(response.txt);
        }
      }, err => {
        //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
        this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
      })
    }
  }

  _AutoFillFormDetails(_dealerDetails) {
    this.model.ad_Location = [];
    this.model.posp_aadhaar = _dealerDetails.aadhar_no;
    this.model.posp_pan = _dealerDetails.pan_card;
    this.model.posp = _dealerDetails.posp_code;
    this.model.ad_prName = _dealerDetails.dealer_name;

    let loc = _dealerDetails.location_name.split(',');

    if (loc && loc.length > 0) {
      loc.forEach((elemet, index) => {
        if (elemet) {
          let Data = this.temp_location.filter(resItem => resItem.location.toLowerCase() == elemet.toLowerCase());
          this.model.ad_Location.push(Data[0])
        }
      })
    }

    if (_dealerDetails.active_flag == 'A') {
      this.model.ad_deo_status = this.translate.instant("userManagement.active.label");
    } else {
      this.model.ad_deo_status = this.translate.instant("userManagement.Inactive.label");
    }
  }

  resetTypeDependent() {
    this.dealerDetails = null;
    this.model.ad_DealerCode = undefined;
    this.model.posp_aadhaar = undefined;
    this.model.posp_pan = undefined;
    this.model.posp = undefined;
    this.model.ad_prName = undefined;
    this.model.ad_Location = [];
    this.model.ad_ProStatus = '0';
    this.errorMsg = '';
  }

  mobilecheck(control) {
    console.log(control)
    let temp = control?.target?.value;
    if (temp != "" && temp != undefined) {
        let firstVal = temp?.slice(0, 1);
        let len = temp.length;

        if (
            firstVal != "6" &&
            firstVal != "7" &&
            firstVal != "8" &&
            firstVal != "9"
        ) {
            this.startingNo = true;
        }
        else {
            this.startingNo = false;
        }
    }
  }

  checking(f:any){
    console.log(f);
    
  }
  call(ev:any){
    console.log(ev);
  }
}
