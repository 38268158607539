import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-bulk-upload-panel',
  templateUrl: './bulk-upload-panel.component.html',
  styleUrls: ['./bulk-upload-panel.component.sass']
})
export class BulkUploadPanelComponent implements OnInit {

  constructor(private modalService: BsModalService) { }
  modalRef: BsModalRef;
  ngOnInit(): void {
  }

  otpExpMaster(otp_exception_master_modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(otp_exception_master_modal,Object.assign({}, { class: 'theme-modal modal-lg popup-small otp-exp-modal' }));
  } 
}
