<!-- <app-left-menu></app-left-menu>
<app-header></app-header> -->
<div class="theme-container detail-list-container application-container bulk-upload-container">
    <div class="page-title fw-100">
        <div class="nav-btn" [routerLink]="['/user-mgmt']">
            <svg>
                <use xlink:href="#ICON_Arrow" />
            </svg>
        </div>
        <div class="head-lg pull-left heading">{{ "userManagement.title" | translate}}</div>
    </div>
    <div class="custom-dropzone" ngx-dropzone>
        <div>
            <div class="icon">
                <svg>
                    <use xlink:href="#ICON_Upload" />
                </svg>
            </div>
            <div class="text">{{ "userManagement.bulkUpload.label" | translate}}</div>
            <div class="sub-text">{{ "userManagement.browseDragDrop.label" | translate}}</div>
        </div>
    </div>
    <div class="btn-block">
        <button type="button" (click)="otpExpMaster(otp_exception_master_modal)" class="btn primary-btn  active theme-lg-btn">{{ "commonBtn.submit" | translate }}</button>
    </div>
</div>

<ng-template #otp_exception_master_modal>
  <div class="modal-container">
    <div class="detail-container-modal">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ "userManagement.otpExceptionModal.label" | translate}}</h4>
        <div class="close-icon" (click)="modalRef.hide()">
          <svg>
            <use xlink:href="#ICON_Cross" />
          </svg>
        </div>
      </div>
      <div class="modal-body">
        <div class="fw-100 field">
          <label class="head-sm">{{ "newLogin.producerCode.label" | translate}}</label>
          <ng-select>
            <ng-option value="002155487987546">002155487987546</ng-option>
            <ng-option value="002155487987547">002155487987547</ng-option>
          </ng-select>
        </div>
        <div class="fw-100 field">
          <label class="head-sm">{{ "userManagement.channel.label" | translate}}</label>
          <ng-select>
            <ng-option value="Lorem Ipsum">Lorem Ipsum</ng-option>
            <ng-option value="Lorem Ipsum">Lorem Ipsum</ng-option>
          </ng-select>
        </div>
        <div class="text-center m-t-lg"><button type="button" class="btn secondary-btn">{{ "userManagement.skipOTP.label" | translate}}</button></div>
      </div>
    </div>
  </div>
</ng-template>