import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SwalService {

  confirm(message: string, options?): Promise<any> {
    return Swal.fire({
      title: 'Confirmation',
      text: message,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      ...options,
    });
  }

  success(message: string, options?): Promise<any> {
    return Swal.fire({
      title: 'Success!',
      text: message,
      icon: 'success',
      ...options,
    });
  }

  info(message: string, options?): Promise<any> {
    return Swal.fire({
      title: 'Info',
      text: message,
      icon: 'info',
      ...options,
    });
  }

  warning(message: string, options?): Promise<any> {
    return Swal.fire({
      title: 'Warning!',
      text: message,
      icon: 'warning',
      ...options,
    });
  }

  error(message: string, options?): Promise<any> {
    return Swal.fire({
      title: 'Oops!',
      text: message,
      icon: 'error',
      ...options,
    });
  }

  custom(options): Promise<any> {
    return Swal.fire(options);
  }
}
