<div class="theme-container" #target>
  <!-- Page header start -->
  <div class="page-title">
    <div (click)="routeBack()" class="nav-btn">
      <svg>
        <use xlink:href="#ICON_Arrow" />
      </svg>
    </div>
    <div class="w-100">
      <div class="page-heading-container">
        <h3 class="head-lg heading">Notification</h3>
      </div>
      <div class="breadcrum-container">
        <ul class="breadcrum">
          <li class="head-sm" (click)="routeBack()">notification</li>
          <li class="head-sm">view All</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Page header end -->

  <!-- LOB & Cards -->
  <form class="theme-form mt-mobile">
    <div class="row">
      <div class="col-12">
        <div class="form-field col-md-6 col-lg-4 col-xl-3">
          <label for="model" class="head-sm required-field">Select LOB</label>
          <ng-select [(ngModel)]="selectedLob" name="lob" (change)="lobChanged($event)">
            <ng-option value="{{ lob.lob_value }}" *ngFor="let lob of lobArr">{{
              lob.lob
              }}</ng-option>
          </ng-select>
        </div>
      </div>
    </div>

    <hr class="mt-md-0 mb-0" />
    <!-- shadow loader -->
    <div class="shadow-loader-container" *ngIf="showNotiLoader">
      <div class="shadow-loader-post">
        <div class="shadow-loader-line"></div>
        <div class="shadow-loader-line"></div>
        <div class="shadow-loader-line"></div>
        <div class="shadow-loader-line"></div>
        <div class="shadow-loader-line"></div>
        <div class="shadow-loader-line"></div>
      </div>
    </div>

    <!-- Cards -->
    <div class="row">
      <div class="col-12">
        <div class="theme-card w-100 card-top-spacing">
          <div class="card-content pt-0 pb-0 pd-small">
            <div class="preview-structure">
              <ng-container *ngFor=" let notification of notificationArr; let i=index">
                <div class="brdr-bottom">
                  <div class="d-flex align-items-baseline justify-content-between flex-wrap">
                    <div class="d-flex align-items-center pt-20" [class.pb-20]="!(notification.url.length > 0)"
                      [class.pb-10]="notification.url.length > 0">
                      <div *ngIf="notification?.icon?.length>0"><img alt="Nan" width="40px" class="img-fluid circle_avatar"
                          src="{{service.commonIconPath}}/assets/images/{{ notification.icon }}" /></div>
                      <div class="d-flex flex-column ml-10">
                        <span class="message-body message_ellipse">{{ notification?.message }}</span>
                        <span class="message-time">{{notification?.hours }}</span>
                      </div>
                    </div>
                    <div class="pb-20 ml-adjust" *ngIf="notification.url.length > 0">
                      <button class="action_btn" (click)="
                      navigateToUrl(notification.url, notification.quote_id)
                    ">
                        View Quote
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <!-- <div class="count-txt m-b-md m-t-sm" *ngIf="notificationDetails.length == 0 && !showNotiLoader">No Notifications
                Found : 0</div> -->
              <!-- <div class="fw-100 text-center link-btn font-sm mt-3" *ngIf="notificationDetails.length > limit"
                (click)="viewAll()">
                View All
              </div> -->
            </div>
            <!-- <div *ngFor=" let notification of notificationArr; let i=index">
              <div class="card accordian-container">
                <div class="w-100 m-0 accordion-heading ques-ans-content">
                  <label class="font-weight-old head-sm pull-left my-3"><span><img alt="Nan" width="20px"
                        class="mr-2 img-fluid" src="{{ service.commonIconPath }}assets/images/{{
                        notification.icon
                      }}" /> </span>{{ notification?.message }}.</label>
                  <div class="theme-label">
                    {{ notification?.hours }}
                  </div>
                </div>
                <div class="my-2" *ngIf="notification.url.length > 0">
                  <button class="btn primary-btn theme-btn sm mr-0" (click)="
                    navigateToUrl(notification.url, notification.quote_id)
                  ">
                    Take Action
                  </button>
                </div>
                <hr class="m-0 p-0" *ngIf="notificationArr.length - 1 != i" />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </form>
</div>