
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';

//import Swal from 'sweetalert2';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Globals } from 'src/app/globals';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { SwalService } from 'src/app/services/swal.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.sass']
})
export class UserManagementComponent implements OnInit {
  agents: any = [];
  parent_name: string;
  term;
  parent_code: string;
  testId: number = 0;
  Expiry_Date = [];
  loading: boolean = true;
  convertedObj: any = "";
  csvRecords: any[] = [];
  header: boolean = true;
  page: number = 1;
  @ViewChild('fileImportInput', { static: false }) fileImportInput: any;
  enableSave: boolean = false;

  constructor(
    public router: Router,
    public service: BaseServiceService,
    private ngxCsvParser: NgxCsvParser,
    private spinner: NgxSpinnerService,
    public globals: Globals,
    public translate: TranslateService,
    private swalService: SwalService,
    private sessionStorageService: SessionStorageService
  ) {
    this.loading = true;
    this.spinner.show();
  }

  ngOnInit(): void {
    this.sessionStorageService.setItem('leftmenuactive', 'user-mgmt');
    this.parent_name = this.sessionStorageService.getItem('first_name');

    if (this.sessionStorageService.getItem('role') == 'broker') {
      var data = {
        email_id: this.sessionStorageService.getItem('email')
      }
      this.service.umbroker(data).subscribe(respons => {
        if (respons.status === 0) {
          if (respons.data.length === 0) {
            this.spinner.hide();
            return false;
          }
          this.parent_code = this.sessionStorageService.getItem('parent_code');
          this.agents = respons.data;

          for (var ind = 0; ind < this.agents.length; ind++) {
            var d = moment()
            // var one_day = 1000 * 60 * 60 * 24
            var fetchdate = this.agents[ind].start_Date
            fetchdate = fetchdate.split("/").reverse().join("/");
            // fetchdate = `"${fetchdate}"`

            fetchdate = moment(fetchdate)
            var todate = moment(moment(fetchdate).add(90, 'days'))
           
            //  divide the time difference of both the dates by no. of milliseconds in a day
            var Results = moment.duration(todate.diff(d)).asDays();
            
            //  to remove decimals from the results
            this.Expiry_Date.push(Results.toFixed(0));
            this.agents[ind].expiredIn = Results.toFixed(0)

          }
          this.loading = false;
          this.spinner.hide();

        } else if (respons.status === -114) {
          this.loading = false;
          this.spinner.hide();
          //Swal.fire(this.translate.instant("staticTs.oops"), respons.txt, this.translate.instant("staticTs.error"));
          this.swalService.error(respons.txt);
          this.router.navigateByUrl('/login');
        }
        else {
          this.loading = false;
          this.spinner.hide();
          //Swal.fire(this.translate.instant("staticTs.oops"), respons, this.translate.instant("staticTs.error"))
          this.swalService.error(respons)
        }
      }, err => {
        this.loading = false;
        this.spinner.hide();
        //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
        this.swalService.error(this.translate.instant("staticTs.serverTimeout"))
      })

    } else {
      this.loading = false;
      this.spinner.hide();
      this.router.navigateByUrl('/login');
    }
  }

  producer_login(eventVal) { //jay
    console.log(eventVal)
  }

  // To change the producer_status
  ChangeStatus(data, status, event) {
    if (event.target.checked == false) {
      this.service.DeactiveStatus(data).subscribe(res => {
        console.log("readres", res)
      })
    }
    else {
      this.service.ActivateStatus(data).subscribe(res => {
        console.log("readres", res)
      })
    }
  }

  thisFileUpload() {
    document.getElementById("csvFileUpload").click();
  }

  async fileChangeListener($event: any) {
    const files = $event.srcElement.files;
    this.header = (this.header as unknown as string) === 'true' || this.header === true;

    this.ngxCsvParser.parse(files[0], { header: this.header, delimiter: ',' })
      .pipe().subscribe(async (result: Array<any>) => {
        await this.validateJson(result)
        // console.log(result);
        this.csvRecords = result;
        if (this.csvRecords.length === 0) {
          //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.validData"), this.translate.instant("staticTs.error"));
          this.swalService.error(this.translate.instant("staticTs.validData"));
        } else {
          this.saveCSVJson();
        }
      }, (error: NgxCSVParserError) => {
        console.error(error);
      });
  }

  validateJson(data): any {
    let checkKeyPresenceInArray = key => data.some(obj => Object.keys(obj).includes(key));
    var isEmailPresent = checkKeyPresenceInArray('email_id');
    var isstatusPresent = checkKeyPresenceInArray('status');
    let validEmail = data.filter(obj => obj.email_id && obj.email_id != "" && obj.email_id != null && obj.email_id != undefined)
    let validStatus = data.filter(obj => obj.status && obj.status != "" && obj.status != null && obj.status != undefined)

    if (!(isEmailPresent && isstatusPresent)) {
      this.enableSave = false;
      return false;
    }
    if (validEmail.length != data.length) {
      this.enableSave = false
    }
    if (validStatus.length != data.length) {
      this.enableSave = false;
      return false;
    }
    this.enableSave = true;
  }

  saveCSVJson() {
    let lstactive = (this.csvRecords.filter(obj => obj.status.toLowerCase().replace(/\s/g, '') == 'active' && obj.email_id && obj.email_id != "" && obj.email_id != null && obj.email_id != undefined)).map(el => el['email_id']);
    let lstInactive = (this.csvRecords.filter(obj => obj.status.toLowerCase().replace(/\s/g, '') == 'inactive' && obj.email_id && obj.email_id != "" && obj.email_id != null && obj.email_id != undefined)).map(el => el['email_id'])

    if (lstInactive.length == 0 && lstactive.length == 0) {
      //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.invalidData"), this.translate.instant("staticTs.error"));
      this.swalService.error(this.translate.instant("staticTs.invalidData"));
      return false;
    }
    let data = {
      active_set: lstactive,
      inactive_set: lstInactive,
      producer_code: this.sessionStorageService.getItem('producer_code')
    }

    this.service.saverevalexcel(data).subscribe(async response => {
      if (response.status === 0) {
        //Swal.fire(this.translate.instant("staticTs.success2.swal"), this.translate.instant("staticTs.revalidationSuccess"), this.translate.instant("staticTs.success"));
        this.swalService.success(this.translate.instant("staticTs.revalidationSuccess"));
        this.clearfile();
        this.getList();
      } else if (response.status === -114) {
        //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
        this.swalService.error(response.txt);
        this.sessionStorageService.clear();
        this.router.navigateByUrl(`/login`);
      } else {
        //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
        this.swalService.error(response.txt);
      }
    }, err => {
      //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
      this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
    })
  }

  getList() {
    var data = {
      email_id: this.sessionStorageService.getItem('email')
    }
    this.service.umbroker(data).subscribe(response => {
      if (response.status === 0) {
        if (response.data.length === 0) {
          return false;
        }
        this.parent_code = this.sessionStorageService.getItem('parent_code');
        this.agents = response.data;
        for (var i = 0; i < this.agents.length; i++) {
          var d = new Date()
          var one_day = 1000 * 60 * 60 * 24
          var fetchdates = this.agents[i].start_Date
          fetchdates = fetchdates.split("/").reverse().join("/");
          fetchdates = `"${fetchdates}"`

          fetchdates = new Date(fetchdates)
          var todate = new Date(fetchdates.setDate(fetchdates.getDate() + 90))
          //  divide the time difference of both the dates by no. of milliseconds in a day
          var Result = Math.round(todate.getTime() - d.getTime()) / (one_day);
          //  to remove decimals from the result
          this.Expiry_Date.push(Result.toFixed(0));
        }
      } else if (response.status === -114) {
        //Swal.fire(this.translate.instant("staticTs.oops"), response.txt, this.translate.instant("staticTs.error"));
        this.swalService.error(response.txt);
        this.router.navigateByUrl('/login');
      }
      else {
        //Swal.fire(this.translate.instant("staticTs.oops"), response, this.translate.instant("staticTs.error"))
        this.swalService.error(response);
      }
    }, err => {
      //Swal.fire(this.translate.instant("staticTs.oops"), this.translate.instant("staticTs.serverTimeout"), this.translate.instant("staticTs.error"))
      this.swalService.error(this.translate.instant("staticTs.serverTimeout"));
    })
  }

  clearfile() {
    this.fileImportInput.nativeElement.value = "";
    this.enableSave = false;
  }

  downloadFile() {
    const expData = this.agents;
    let lstData = [];
    const ExpDaata1 = expData.filter(el => el.email_id && el.email_id != '' && el.revalidation && el.revalidation != '')
    ExpDaata1.map((element) => {
      let dataTemp = {
        email_id: element['email_id'],
        status: element['revalidation'].trim().toLowerCase() == 'active' ? 'active' : (element['revalidation'].trim().toLowerCase() == 'inactive' ? 'inactive' : '')
      }
      lstData.push(dataTemp)
    })
    var data = []

    if (lstData.length > 0) {
      data = lstData;
    }
    else {
      data = [
        { "email_id": "test1@abc.com", "status": "active" },
        { "email_id": "test2@abc.com", "status": "inactive" }
      ];
    }

    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var a = document.createElement('a');
    var blob = new Blob([csvArray], { type: 'text/csv' }),
      url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = "Revalidation-Upload.csv";
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
