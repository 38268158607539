export const errorMessage = {
    required: "errorMessage.required",
    minLength: "errorMessage.minLength",
    maxLength: "errorMessage.maxLength",
    maxValue: "errorMessage.maxValue",
    minValue: "errorMessage.minValue",
    email: "errorMessage.email",
    invalidDate: "errorMessage.invalidDate",
    pattern: "errorMessage.pattern",
    invalidPolicyDate : "errorMessage.invalidPolicyDate15",
    filterToDate: "errorMessage.filterToDate",
    invalidDobDate: "errorMessage.invalidDobDate",
  };