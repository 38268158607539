<div class="theme-container my-transaction">
  <!-- Page header start -->
  <div class="page-title">
    <div class="nav-btn" (click)="globals.goBackToAllApplication()">
      <svg>
        <use xlink:href="#ICON_Arrow" />
      </svg>
    </div>
    <div class="w-100">
      <div class="page-heading-container">
        <h3 class="head-lg heading" *ngIf="role == 'agent' || role == 'broker'">Quick Links</h3>
        <h3 class="head-lg heading" *ngIf="role != 'agent' && role != 'broker'">More Items</h3>
      </div>
      <div class="breadcrum-container">
        <ul class="breadcrum">
          <li class="head-sm" (click)="globals.goBackToAllApplication()">
            {{ "allApplication.allProducts.label" | translate }}
          </li>
          <li class="head-sm" *ngIf="role == 'agent' || role == 'broker'">Quick Links</li>
          <li class="head-sm" *ngIf="role != 'agent' && role != 'broker'">More Items</li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Page header end -->

  <ng-container *ngIf="role == 'agent' || role == 'broker'">
    <div class="row">
      <div class="col-12">
        <form class="theme-form">
          <div class="row">
            <div
              class="col-12 col-md-6 col-lg-3"
              (click)="buttonav('payinslip')"
            >
              <div class="theme-card text-center">
                <div class="outer-container">
                  <div class="">
                    <img
                      alt=""
                      class="img-fluid svg-lg"
                      src="assets/images/Pay_In_Slip.svg"
                    />
                  </div>
                  <div class="card-text">
                    {{ "myTransaction.payInSlip.label" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 col-lg-3"
              (click)="buttonav('eodpayinslip')"
            >
              <div class="theme-card text-center">
                <div class="outer-container">
                  <div class="">
                    <img
                      alt=""
                      class="img-fluid svg-lg"
                      src="assets/images/EOD_Pay_In_Slip.svg"
                    />
                  </div>
                  <div class="card-text">
                    {{ "myTransaction.eodPayInSlip.label" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 col-lg-3"
              (click)="buttonav('eodpayinsliptravel')"
            >
              <div class="theme-card text-center">
                <div class="outer-container">
                  <div class="">
                    <img
                      alt=""
                      class="img-fluid svg-lg"
                      src="assets/images/EOD_Pay_In_Slip.svg"
                    />
                  </div>
                  <div class="card-text">
                    {{ "myTransaction.eodPayInSlipTravel.label" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 col-lg-3"
              (click)="buttonav('instapolicy')"
            >
              <div class="theme-card text-center">
                <div class="outer-container">
                  <div class="">
                    <img
                      alt=""
                      class="img-fluid svg-lg"
                      src="assets/images/Group 13535.svg"
                    />
                  </div>
                  <div class="card-text">
                    {{ "myTransaction.instaPolicyMotor.label" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 col-lg-3"
              (click)="buttonav('instapolicytravel')"
            >
              <div class="theme-card text-center">
                <div class="outer-container">
                  <div class="">
                    <img
                      alt=""
                      class="img-fluid svg-lg"
                      src="assets/images/Group 13535.svg"
                    />
                  </div>
                  <div class="card-text">
                    {{ "myTransaction.instaPolicyTravel.label" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 col-lg-3"
              *ngIf="lockstatus !== 'LOCKED'"
              (click)="navigate()"
            >
              <div class="theme-card text-center">
                <div class="outer-container">
                  <div class="">
                    <img
                      alt=""
                      class="img-fluid svg-lg"
                      src="assets/images/redirect_v1_gray.svg"
                    />
                  </div>
                  <div class="card-text">
                    {{ "myTransaction.ipdsV1.label" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 col-lg-3"
              *ngIf="showCommissionOnDemand && lockstatus !== 'LOCKED'"
              (click)="buttonav('commissionOnDemand')"
            >
              <div class="theme-card text-center">
                <div class="outer-container">
                  <div class="">
                    <img
                      alt=""
                      class="img-fluid svg-lg"
                      src="assets/images/Commission_On_Demand.svg"
                    />
                  </div>
                  <div class="card-text">
                    {{ "myTransaction.commissionOnDemand.label" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 col-lg-3"
              *ngIf="lockstatus !== 'LOCKED'"
              (click)="buttonav('standAlonePayment')"
            >
              <div class="theme-card text-center">
                <div class="outer-container">
                  <div class="">
                    <img
                      alt=""
                      class="img-fluid svg-lg"
                      src="assets/images/Standalone_Payment.svg"
                    />
                  </div>
                  <div class="card-text">
                    {{ "myTransaction.standAlonePayment.label" | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-12 col-md-6 col-lg-3"
              *ngIf="lockstatus !== 'LOCKED'"
              (click)="buttonav('payLater')"
            >
              <div class="theme-card text-center">
                <div class="outer-container">
                  <div class="">
                    <img
                      alt=""
                      class="img-fluid svg-lg"
                      src="assets/images/Pay_Later.svg"
                    />
                  </div>
                  <div class="card-text">
                    {{ "myTransaction.payLater.label" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>

  <div class="row">
    <div class="col-12">
      <app-features-area></app-features-area>
    </div>
  </div>
</div>
