import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Globals } from 'src/app/globals';
import { BaseServiceService } from 'src/app/services/base-service.service';
import { SessionStorageService } from 'src/app/services/session-storage.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit {
  selectedLOBValue;
  modalRefLocation: BsModalRef;
  modalRefPayment: BsModalRef;
  modalRefDetails: BsModalRef;
  userProfileData: any;
  paymentOptions: any;
  lobOptions: any;
  uniqueLobs: any;
  currentIndex: any;
  products: any;
  index = 0;
  plans: any;
  zones: any;
  lobshown: any;
  sol_id: any;
  i = 0;
  searchContent: any;
  somedata: string[] = [];
  shadowLoader: boolean = false;
  planZoneArray = [];
  iconspath: {} = {
    "aigcombined": "AIG_Combined_Default.svg", "personallines": "Personal_lines_default.svg", "lta": "LTA_default.svg", "propertyandenergy": "Property_Energy_Default.svg", "marinecargo": "Marine_Default.svg", "health": "Health_Default.svg", "motor": "Motor_default.svg", "personalaccident": "Personal_Accident_default.svg", "aviation": "Aviation_default.svg", "home": "Home_default.svg", "travel": "travel_default.svg", "financialline": "Financial_lines_default.svg", "tradecredit": "Trade_Credit_default.svg", "casualty": "Casualty_default.svg", "construction": "Constuction.svg", "warrantyproducts": "Warranty_Products.svg","combo":"Combo_Default.svg"
  };
  paymentIcon: {} = { "Bank Guarantee": "Bank_Loan.svg", "Cash": "Cash.svg", "Demand draft": "Demand_Draft.svg", "Insta Payment Motor": "Insta_Policy.svg", "Instant Policy (EOD/Float)": "Insta_Policy.svg", "NEFT": "NEFT.svg", "Advance Premium Deposit": "Advance_payment.svg", "Cash Deposit": "Cash_deposit.svg", "CD Insta payment": "CD_Payment.svg", "Cheque": "Cheque.svg", "Insta Payment": "Insta_Policy.svg", "Online Payment": "Online.svg", "Self-payment link to Customer": "Payment_link.svg", "Receipt": "ICON_receipt" };
  sessionLocation: any;
  sessionState: any;
  filteredData: any;
  depositbalance: any;
  productsObject = {};
  showCardDetail: boolean = false;
  Object = Object;
  locationlist = JSON.parse(this.sessionStorageService.getItem('locationlist'));
  config = {
    class: "theme-modal",
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false
  };

  constructor(private modalService: BsModalService, public service: BaseServiceService, public globals: Globals, private sessionStorageService: SessionStorageService) {

  }

  async ngOnInit() {
    this.filteredData = this.locationlist;
    await this.getProfile();
  }

  getProfile() {
    return new Promise((resolve, reject) => {
      this.sessionLocation = this.sessionStorageService.getItem('location');
      this.sessionState = this.sessionStorageService.getItem('state_name')
      this.service.get_product_plan_zone_master().subscribe(response => {
        this.planZoneArray = response.data;
      })
      this.service.profile().subscribe(responseprofile => {
        if (responseprofile.status === 0) {
          this.userProfileData = responseprofile.data;
          this.getUserData();
          resolve(true);
        }
      });
    })
  }

  async getUserData() {
    this.depositbalance = this.userProfileData.deposit_balance;
    this.paymentOptions = this.userProfileData.data.payment_type ? this.userProfileData.data.payment_type.split(',') : [];
    this.lobOptions = this.userProfileData.data.lob ? this.userProfileData.data.lob.split(',') : [];
    this.uniqueLobs = this.lobOptions.filter((item, index) => this.lobOptions.indexOf(item) === index);
    await this.selectedLOB();
    this.plans = this.userProfileData.data.plan ? this.userProfileData.data.plan.split(',') : [];
    this.zones = this.userProfileData.data.zone ? this.userProfileData.data.zone.split(',') : [];
    var role_id = this.userProfileData.roles.length > 0 ? this.userProfileData.roles[0] : '';
    if (role_id) {
      this.service.getRolename({ role_id: role_id }).subscribe(getroleresponse => {
        if (getroleresponse.status === 0) {
          this.sessionStorageService.setItem('role', (getroleresponse.data[0].role_name).toLowerCase());
          if ((getroleresponse.data[0].role_name).toLowerCase() == 'proxy') {
            getroleresponse.data[0].role_name = 'csm';
          }
          var role_details = {
            "role": (getroleresponse.data[0].role_name).toLowerCase().replace(/\s/g, ''),
            "user_type": "employee"
          };
          this.service.getRoledetails(role_details).subscribe(responserolemaster => {
            if (responserolemaster.status === 0 || responserolemaster.data.length !== 0) {
              this.sol_id = responserolemaster?.data[0]?.sol_id;
            }
          })
        }
      });
    }
  }

  openViewLocation(view_location_template: TemplateRef<any>) {
    this.modalRefLocation = this.modalService.show(view_location_template, this.config);
  }
  openViewPayemnt(view_payment_template: TemplateRef<any>,lob) {
    this.modalRefPayment = this.modalService.show(view_payment_template, this.config);
    this.lobshown = lob;
  }

  openViewDetail(view_detail_template: TemplateRef<any>) {
    this.modalRefPayment = this.modalService.show(view_detail_template, this.config);
  }

  openLargeCard(ind) {
    this.showCardDetail = !this.showCardDetail;
    this.currentIndex = ind;
    this.shadowLoader = true;
    setTimeout(() => {
      this.shadowLoader = false;
    }, 500);
  }

  selectedLOB() {
    return new Promise((resolve, reject) => {
      this.uniqueLobs.forEach((lobName, i) => {
        this.service.callmasterlob(lobName).subscribe(response => {
          if (response.status == 0) {
            if (response.data.length > 0) {
              this.productsObject[lobName] = response.data;
              resolve(true);
            }
          }
          i++;
        });
      });
    });
  }

  filterSearch() {
    this.filteredData = this.locationlist;
    this.filteredData = this.locationlist.filter((obj) => obj.toLowerCase().includes(this.searchContent.toLowerCase()));
  }

  callpremiumcomma(x) {
    //ven added 29-04-2021
    // check if the number is positive, negative or zero
    if(x !==null){
      if (x >= 0) {
        return x.toString().split(".")[0].length > 3
          ? x
            .toString()
            .substring(0, x.toString().split(".")[0].length - 3)
            .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
          : x.toString();
      } else {
        return 0;
      }
    }
  }
}
