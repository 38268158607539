import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  Component,
  HostListener,
  Injectable,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { Globals } from "src/app/globals";
import { BaseServiceService } from "src/app/services/base-service.service";
import {LIST2APIFIELDS,
  REGEX,
  REMOVES_LEADING_AND_TRAILING_SPECIAL_CHARACTER_REGEX,
  SEARCH_CONST,
} from "src/app/services/constants";
import { SessionStorageService } from "src/app/services/session-storage.service";
import { SwalService } from "src/app/services/swal.service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
declare var bootstrap; // To make bootstrap 5 work

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = "/";
  currentDate = new Date();
  dateLimit: any;
  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date1 = value.split(this.DELIMITER);
      return {
        day: parseInt(date1[0], 10),
        month: parseInt(date1[1], 10),
        year: parseInt(date1[2], 10),
      };
    }
    return null;
  }
  format(date1: NgbDateStruct | null): string {
    return date1
      ? date1.day + this.DELIMITER + date1.month + this.DELIMITER + date1.year
      : "";
  }
}

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.sass"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class SearchComponent implements OnInit, OnDestroy {
  showFilterDiv: boolean = false;
  selectLobLoader: boolean = false;
  lob_product: any;
  successModalRef: BsModalRef;
  savePremium: boolean;
  sharePremium: boolean;
  contactModalRef: BsModalRef;
  shareModelRef: BsModalRef;
  conditionlRequired: boolean = true;
  currentDate: any = {};
  endMaxDate: any = {};
  modalRef: BsModalRef;
  config = { class: "theme-modal" };
  openMenu = false;
  closeMenu = false;
  hidenav: boolean = false;
  selectedType: any = "By Email ID";
  minDate: any;
  model: any = {};
  autoLoadData: any;
  clickbutton = false;
  disDownload = true;
  submit = false;
  @ViewChild("f") userData: NgForm;
  finalcardstatus: any;
  finalcardstatusmsg: any;
  dropdownList = [];
  obj: any;
  dropdownListLta = []; //lta muruga
  plan_list_lta = []; //lta muruga
  plan_list_bta = []; //bta anavarathan
  card_index: number; // muruga 26/20/2020
  selectedItems = [];
  dropdownSettings: IDropdownSettings;
  submitfilter = false;
  // @ViewChild('f1') filterData: NgForm;
  particularlobdata: any = [];
  masterlobdata: any = [];
  fianlcorrespondingproduct: any = [];
  products: any;
  linkDisable: boolean = false;
  data: any;
  allLobData: any = [];
  lobData: any = [];
  particularLobData: any;
  FromDate = false;
  ToDate = false;
  startDate: any;
  endDate: any;
  diff_Days: any;
  searchText: string;
  slctdLob: any;
  searchSpinner: boolean = false;
  showFilterOnly: boolean = false;
  producerCodeList = [];
  displayproductName = [];
  headerList: string[] = [
    "ProductName",
    "CustomerName",
    "NewProduct",
    "ProducerName",
    "channel",
    "Policynumber",
    "Premium",
    "cancelcertificate",
    "status",
    "quicklinks",
  ];

  lob_value: any;
  selectedLobValue: string;
  selectedStageVal: any = "Quote";
  urlValue: any;
  pro_ID: any;
  cardlist: any = [];
  cardlisttemp: any = [];
  cradlisttempLTA: any = []; /*********muruga 30/10/2020********* */
  modelpop: any = {};
  product_id: any;
  policy_id: any;
  dropdownSettings1 = {};
  productdropdownSettings2 = {};
  businesstypedropdownSettings = {};
  locationlist = [];
  tolowercase: string = "";
  days: any;
  fieldshow: any = {};
  reassingStartDate: any = {};
  reassingEndDate: any = {};
  temp: any = [];
  searchnavigationlist: any = [];
  // product_group_id: string = '';
  policyId: "";
  quoteResponse: any = [];
  cancelClick: boolean = false;
  role =
    sessionStorage.getItem("role") == null
      ? ""
      : sessionStorage.getItem("role");
  cops = this.role.includes("cops");
  privileage: any;
  producticonslist: any = [];
  profileData: any = [];
  premiumdisplay: boolean = false;
  lob_data: any = [];
  quoteData: any = [];
  lobDataindex = 6;
  lobDatamorebutton: boolean = true;
  responsefromapicart: any = [];
  page: number = 1;
  profileres: any = [];
  currentrole: any;
  searchresplist: any;
  requestfield: any = "";
  searchparavalue: any;
  today: any;
  //added 8-12-2020
  selectedStageList: any = [];
  selectedValList: any = [];
  displaydropdown: boolean = false;
  //added 8-12-2020
  apitolowercase: string = ""; //added 9-12-2020
  concatlist: any = [];
  concatlisttwo: any = [];
  urlcurrentstage: any;
  tenurelist: any = [];
  varientlist: any = [];
  plantypelist: any = [];
  businesstypelist: any = [];
  bundletypelist: any = [];
  memberisemployeelist: any = [];
  vehiclestatuslist: any = [];
  customertypelist: any = [];
  gcchanneltypelist: any = [];
  gclocationlist: any = [];
  dropdownSettings2 = {};
  dropdownSettings3 = {};
  isUW: boolean = false;
  getPolicyData: any;
  nonRatorList: any = [];
  ratorList: any = [];
  selectedLob: any = "";
  gridItem: boolean = true;
  listItem: boolean = false;
  mobNstpStatus: boolean = false;
  loading: boolean = false;
  showLobs: boolean = false;
  lobValue: string;
  lobDataCopy: any = [];
  quoteRes;
  contactDetailForm: FormGroup;
  customerEmail: FormGroup;
  salutationArr = [
    this.translate.instant("staticTs.mr"),
    this.translate.instant("staticTs.mrs"),
    this.translate.instant("staticTs.ms"),
  ];
  invalidMsg: Boolean = false;
  itemsPerPageList: string = "50";
  itemsPerPageGrid: string = "50";
  itemsPerPage = 50;
  previousButton = "<< Previous";
  nextButton = "Next >>";
  pageIndex = 0;
  isSearchClicked = false;
  isTravel = false;
  lta = false;
  personalaccident = false;
  receiptSearchBtn: boolean = false;
  selectedCertificatePolicy = null;
  certificatePolicyList = [];
  disableVarient: boolean = false;
  filteredRoute: any = undefined;
  showSubTransactionType: boolean = false;
  sortOrder: any;
  productCategory: any;
  quotes_stat: any;
  memberisEmployee: any;
  planType: any;
  tenure: any;
  varient: any;
  businessType: any;
  businessTypeName: string[] = [];
  filterLocation: any;
  subtractionType: any;
  netPremium: any;
  sumInsuredFrom: any;
  sumInsuredTo: any;
  gcChannel: [];
  gcLocation: any;
  ltaBundleType: any;
  ltaPlanType: any;
  motorBundleType: any;
  motorvehicleType: any;
  customerType: any;
  insDesFrom: any;
  insDecTo: any;
  btaPlanType: any;
  prod_id: any;
  productsfinallist: any = [];
  userProduct;
  any = [];
  current_lob: any;
  isSearchSession = false;
  showFilterOption = false;
  smeStatus: any;
  producerCode: any;
  productName: any;
  allProductIds = [];
  allProductNames = [];
  role_id = "";
  crossSell = true;
  isHealth: boolean = false;
  isMotor: boolean = false;
  isCrossSellFilter = false;

  // DOE array
  doeArr = [];
  doeFilter: any;

  subTransactionTypes = [];
  multipleServerUrl: any;

  constructor(
    private swalService: SwalService,
    private sessionStorageService: SessionStorageService,
    public globals: Globals,
    public http: HttpClient,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    public router: Router,
    public service: BaseServiceService,
    public route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public translate: TranslateService
  ) {
    this.loading = true;
    this.spinner.show();
    ///// Backoption with Searched Filters
    this.BackToSearchWithSlctdFilters();
    //////
    const lobVal = this.route.snapshot?.queryParams?.lob_value;
    this.lobValue =
      lobVal && lobVal?.charAt(0)?.toUpperCase() + lobVal?.slice(1);
    this.showLobs = !this.lobValue;
    if (this.filteredRoute != undefined) {
      this.showLobs = false;
    }
    this.modelpop.quotestatus = [];
    this.modelpop.gcchannel = [];
    this.modelpop.gclocation = [];
    this.service.profile().subscribe((responseprofile) => {
      this.profileres = responseprofile.data;
      if (responseprofile.status !== -106) {
        this.privileage = responseprofile.data.privileges.includes(
          "BTA_Certificate_Cancel"
        );
      } else {
        this.privileage = "";
      }
    });

    /*****************LTA muruga 23/10/2020************ */
    // this.plan_list_lta = ["Silver", "Silver Plus", "Silver with Family", "Silver Plus with Family", "Annual Gold", "Annual Platinum", "Gold", "Platinum", "Senior", "Standard", "Annual Multi Trip", "Plan A", "Plan B", "Ultimate", "Ultimate Plus", "Supreme"]
    // this.plan_list_bta = ["First Class - Including America","First Class - Excluding America","First Class - Worldwide","Business Class - Including America","Business Class - Excluding America","Business Class - Worldwide","Economy - Including America","Economy - Excluding America","Economy - Worldwide" ]

    this.dropdownListLta = [
      {
        id: 2,
        itemName: this.translate.instant("staticTs.pendingForApproval"),
      },
      { id: 3, itemName: this.translate.instant("staticTs.approved") },
      { id: 4, itemName: this.translate.instant("staticTs.rejected") },
    ];
    this.selectedItems = [];

    /** Multiselect Settings */
    this.dropdownSettings1 = this.createDropdownSettings({
      enableCheckAll: false,
      textField: "itemName",
    });
    this.dropdownSettings2 = this.createDropdownSettings({
      enableCheckAll: true,
      textField: "channel_type",
      idField: "channel_type",
    });

    this.businesstypedropdownSettings = this.createDropdownSettings({
      enableCheckAll: false,
      textField: "value",
      idField: "id",
    });

    this.productdropdownSettings2 = this.createDropdownSettings({
      enableCheckAll: false,
      textField: "product_name",
      idField: "product_id",
    });
    this.dropdownSettings3 = this.createDropdownSettings({
      textField: "name",
      idField: "name",
      enableCheckAll: true,
    });
  }

  async ngOnInit() {
    const searchFilterDetails = this.sessionStorageService.getItem(
      "search_filter_details"
    );
    this.isSearchSession = searchFilterDetails?.length > 0;
    this.loading = true;
    this.spinner.show();

    this.sessionStorageService.setItem("leftmenuactive", "search");

    // If user id is locked, don't proceed
    if (
      this.sessionStorageService.getItem("lockstatus")?.toUpperCase() ==
      "LOCKED"
    ) {
      Swal.fire("Portal Locked");
      return false;
    }

    var data = JSON.parse(searchFilterDetails);

    await this.route.queryParams.subscribe((params) => {
      this.searchparavalue = "";
      const { lob_value, stage, product_id, start_date, end_date, days } =
        params;
      if (lob_value != undefined && lob_value != "") {
        this.prod_id = product_id;
        this.searchparavalue = lob_value;

		if(days == undefined || days == null || days == ''){
			this.days = 2;
			this.calldatediff(days);
		} else {
		this.model.ad_startDate = {
			day: parseInt(start_date?.slice(0, 2)),
			month: parseInt(start_date?.slice(3, 5)),
			year: parseInt(start_date?.slice(6)),
			};
	
			this.model.ad_endDate = {
			day: parseInt(end_date?.slice(0, 2)),
			month: parseInt(end_date?.slice(3, 5)),
			year: parseInt(end_date?.slice(6)),
			};
		}
		

        this.model.ad_startDate = {
          day: parseInt(start_date?.slice(0, 2)),
          month: parseInt(start_date?.slice(3, 5)),
          year: parseInt(start_date?.slice(6)),
        };

        this.model.ad_endDate = {
          day: parseInt(end_date?.slice(0, 2)),
          month: parseInt(end_date?.slice(3, 5)),
          year: parseInt(end_date?.slice(6)),
        };

        if (stage == "UW" || stage == "PP") {
          this.selectedStageVal = "Proposal";
          this.tolowercase = "proposal";
          this.urlcurrentstage = params["stage"]; //added 28-12-2020 UW and PP purpose
        } else {
          this.selectedStageVal = stage;
          this.tolowercase = stage.toLowerCase();
          this.urlcurrentstage = stage;
        }

        this.days = days;

        if (
          !this.days &&
          data &&
          data.startDate !== undefined &&
          data.startDate !== null &&
          data.startDate !== "" &&
          (!data.endDate ||
            data.endDate === undefined ||
            data.endDate === null ||
            data.endDate === "")
        ) {
          this.days = 5;
        }

        if (
          (isNaN(this.model.ad_startDate.day) ||
            isNaN(this.model.ad_startDate.month) ||
            isNaN(this.model.ad_startDate.year)) &&
          (isNaN(this.model.ad_endDate.day) ||
            isNaN(this.model.ad_endDate.month) ||
            isNaN(this.model.ad_endDate.year)) &&
          (data == null ||
            data.startDate == undefined ||
            data.startDate == null ||
            data.startDate != "") &&
          (data == null ||
            data.endDate == undefined ||
            data.endDate == null ||
            data.endDate == "")
        ) {
          this.calldatediff(this.days); //date diff call
        }
      } else {
        this.tolowercase = "quote";
        this.selectedStageVal = "Quote";
        this.urlcurrentstage = "Quote";
        this.calldatediff(3); //changing default days from 7 to 3. search page optimization
        this.cardlist = [];
        this.cardlisttemp = [];
        this.responsefromapicart = []; //28-11-2020 ven
        this.finalcardstatusmsg = undefined;
        ////// resolve bug 100 by shokeen
        this.lobValue = "";
        this.showLobs = true;
        this.displaydropdown = false;
        this.submit = false;
      }

      if (this.prod_id?.length > 0) {
        this.modelpop.product_id = this.prod_id;
      }

      if (
        sessionStorage.getItem("search_filter_details") != undefined &&
        sessionStorage.getItem("search_filter_details") != ""
      ) {
        // data.finalcardstatus = this.tolowercase;
        if (this.prod_id != undefined && this.prod_id != "") {
          data.modelpop.product_id = this.prod_id;
        }
        if (this.lob_value?.length > 0) {
          data.selectedLob = this.lob_value;
        }
        sessionStorage.setItem("search_filter_details", JSON.stringify(data));
      }
    });

    this.hideShowNav();
    this.getAllLobs();
    let location = this.sessionStorageService.getItem("locationlist");

    if (location != "" && location != undefined) {
      this.locationlist = JSON.parse(location);
    }
    const sortedLoc = [...this.locationlist].sort();
    sortedLoc.forEach((element) => {
      this.gclocationlist.push({ name: element });
    });

    //get gc channel data
    this.service.getchanneldata().subscribe((result) => {
      this.gcchanneltypelist = result.data;
    });

    let date = new Date();
    this.currentDate = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };
    this.endMaxDate = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };

    this.initializeForm();

    this.autoPopulateSearchForm(data);
    //  this.checkFilter();
    this.assignFilterValues();
  }

  ngOnDestroy() {
    this.sessionStorageService.removeItem("search_filters");
    this.sessionStorageService.removeItem("search_form_details");
    this.sessionStorageService.removeItem("search_filter_details");
    this.sessionStorageService.removeItem("backFromCustomerdiary");
  }

  // Function to create dropdown settings objects with common configurations
  createDropdownSettings(options = {}) {
    return {
      singleSelection: false,
      text: this.translate.instant("staticTs.select"),
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      itemsShowLimit: 1,
      allowSearchFilter: true,
      classes: "myclass custom-class",
      autoPosition: false,
      ...options, // Merge any additional options provided
    };
  }

  BackToSearchWithSlctdFilters = () => {
    const search_filter = this.sessionStorageService.getItem("search_filters");
    if (search_filter != undefined) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: JSON.parse(search_filter),
        queryParamsHandling: "merge",
      });
      this.filteredRoute = this.route.queryParams;
    }
  };

  sortfilter(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth" });
    if (this.modelpop.product_id == undefined) {
      this.disableVarient = true;
    }
    setTimeout(() => {
      //this.modalRef = this.modalService.show(sortfilter_template, Object.assign({}, { class: 'theme-modal modal-lg filter-modal', ignoreBackdropClick: true }));
      this.changeparam(this.lob_value);
      if (this.fieldshow.producer && this.isUW) this.getBrokerList();
    }, 100);
  }

  async selectedBusinessProduct(event) {
    this.businessTypeName.push(event.value);
    if (
      event.value == "Medicare Plus" ||
      event.value == "Criti-Medicare" ||
      event.value == "GMC-Employer-Employee"
    ) {
      this.modelpop.varient = undefined;
      this.disableVarient = true;
    } else {
      this.disableVarient = false;
    }
    if (
      event.value == "Non-Rater" &&
      ["aigcombined", "marinecargo"].includes(this.lob_value)
    ) {
      this.showSubTransactionType = true;
      let lob = this.lobData?.find((item) => {
        if (item.lob_value == this.selectedLobValue) return item;
      });
      await this.subTransactionTypeDropdown(lob?.lob);
    } else {
      this.showSubTransactionType = false;
    }
  }

  async selectedProduct(row) {
    if (row?.product_name) {
      this.displayproductName.push(row?.product_name);
    }
    if (
      row.product_name == "Medicare Plus" ||
      row.product_name == "Criti-Medicare" ||
      row.product_name == "GMC-Employer-Employee"
    ) {
      this.modelpop.varient = undefined;
      this.disableVarient = true;
    } else {
      this.disableVarient = false;
    }
    if (
      row.product_name == "Non-Rater" &&
      ["aigcombined", "marinecargo"].includes(this.lob_value)
    ) {
      this.showSubTransactionType = true;
      let lob = this.lobData?.find((item) => {
        if (item.lob_value == this.selectedLobValue) return item;
      });
      await this.subTransactionTypeDropdown(lob?.lob);
    } else {
      this.showSubTransactionType = false;
    }
  }

  onItemDeSelect(event) {
    const index = this.displayproductName.indexOf(event.product_name);
    const x = this.displayproductName.splice(index, 1);
    if (this.modelpop.business_type) {
      const ind = this.businessTypeName.indexOf(event.value);
      const y = this.businessTypeName.splice(ind, 1);
    }
    if (event.product_name?.toLowerCase() == "non-rater") {
      this.showSubTransactionType = false;
    }
  }

  cancelcertificate(cancel_certificate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      cancel_certificate,
      Object.assign({}, { class: "theme-modal modal-lg popup-small" })
    );
  }

  policyID(policyId) {
    this.policyId = policyId;
    this.cancelClick = true;
  }

  async changeparam(para) {
    return new Promise((resolve, reject) => {
      let paramsval: any;
      if (para.lob != undefined && para.lob != "") {
        paramsval = para.lob_value;
        this.sessionStorageService.setItem("current_lob", para.lob);
        this.current_lob = para.lob;
        this.selectedLob = para.lob.toLowerCase();
      } else {
        paramsval = para;
      }

      this.service.callmasterlob(paramsval).subscribe((response) => {
        this.masterlobdata = response.data;
        this.fianlcorrespondingproduct = [];
        this.productsfinallist = [];
        let tempcurtlobarray: any;
        tempcurtlobarray = this.masterlobdata.filter(
          (f) => f.group_name_2.toLowerCase() == paramsval.toLowerCase()
        );

        var temp = this.products?.filter((g) =>
          tempcurtlobarray.some((item) => {
            let gg: any = g;
            if (item.value == gg.product_id) {
              this.productsfinallist.push(g);
            }
          })
        );

        this.service.producticon().subscribe((respo) => {
          if (respo.status === 0) {
            this.producticonslist = respo.data;
            this.fianlcorrespondingproduct = [];

            for (let i in this.productsfinallist) {
              for (let j in this.producticonslist) {
                if (
                  this.productsfinallist[i].product_id ==
                  this.producticonslist[j].name
                ) {
                  this.productsfinallist[i].icon_name =
                    this.producticonslist[j].value;
                  this.fianlcorrespondingproduct.push(
                    this.productsfinallist[i]
                  );
                  this.allProductIds = this.fianlcorrespondingproduct.map(
                    (item) => {
                      return item.product_id;
                    }
                  );
                  this.allProductNames = this.fianlcorrespondingproduct.map(
                    (item) => {
                      return item.product_name;
                    }
                  );
                  this.displaydropdown = true;
                } else {
                  this.displaydropdown = true;
                }
              }
            }
          }
          resolve(true);
          this.loading = false;
          this.spinner.hide();
        });
      });
    });
  }

  back() {
    this.router.navigateByUrl("/allapplication");
  }

  calldatediff(arg) {
    let searchFilters = JSON.parse(sessionStorage.getItem("search_filters"));

    if (searchFilters && searchFilters.start_date && searchFilters.end_date) {
      this.model.ad_startDate = searchFilters.start_date;
      this.model.ad_endDate = searchFilters.end_date;
    } else {
      const current = new Date();
      let fdate: any = new Date();
      let oneweekbefore: any = fdate - 1000 * 60 * 60 * 24 * Number(arg);
      oneweekbefore = new Date(oneweekbefore);
      this.model.ad_startDate = {
        year: oneweekbefore.getFullYear(),
        month: oneweekbefore.getMonth() + 1,
        day: oneweekbefore.getDate(),
      };
      this.model.ad_endDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate(),
      };
    }
    this.reassingStartDate = this.model.ad_startDate;
    this.reassingEndDate = this.model.ad_endDate;
  }

  checkFilter() {
    this.showFilterDiv = false;
    for (let item in this.modelpop) {
      if (this.modelpop?.[item]?.length > 0) {
        this.showFilterDiv = true;
        return;
      }
    }
  }

  toggleFilterDiv() {
    this.showFilterOption = false;
    this.selectedStageVal = "Quote";
    this.tolowercase = "quote";
  }

  autoPopulateSearchForm(data) {
    if (data && data?.modelpop && Object.keys(data?.modelpop)?.length) {
      this.modelpop = data.modelpop;
      if (this.modelpop.sort == null || this.modelpop.sort == undefined) {
        this.modelpop.sort = "";
      }
      if (
        this.modelpop.product_id == null ||
        this.modelpop.product_id == undefined
      ) {
        this.modelpop.product_id = "";
      }
      if (
        this.modelpop.quotestatus == null ||
        this.modelpop.quotestatus == undefined
      ) {
        this.modelpop.quotestatus = "";
      }
      if (
        this.modelpop.gcchannel == null ||
        this.modelpop.gcchannel == undefined
      ) {
        this.modelpop.gcchannel = [];
      }
      if (
        this.modelpop.gclocation == null ||
        this.modelpop.gclocation == undefined
      ) {
        this.modelpop.gclocation = "";
      }
      if (
        this.modelpop.location == null ||
        this.modelpop.location == undefined
      ) {
        this.modelpop.location = "";
      }
      if (
        this.selectedType == "marinecargo" &&
        this.model.pop.subTransactionType == null
      ) {
        this.modelpop.subTransactionType = [];
      }
      if (
        this.modelpop.business_type == null ||
        this.modelpop.business_type == undefined
      ) {
        this.modelpop.business_type = "";
      }
      if (this.modelpop.varient == null || this.modelpop.varient == undefined) {
        this.modelpop.varient = "";
      }
      if (this.modelpop.tenure == null || this.modelpop.tenure == undefined) {
        this.modelpop.tenure = "";
      }
      if (
        this.modelpop.plan_type == null ||
        this.modelpop.plan_type == undefined
      ) {
        this.modelpop.plan_type = "";
      }
      if (
        this.modelpop.member_is_employee == null ||
        this.modelpop.member_is_employee == undefined
      ) {
        this.modelpop.member_is_employee = "";
      }
      if (
        this.modelpop.bta_plan_type == null ||
        this.modelpop.bta_plan_type == undefined
      ) {
        this.modelpop.bta_plan_type = "";
      }
      if (
        this.modelpop.motor_vehicle_status == null ||
        this.modelpop.motor_vehicle_status == undefined
      ) {
        this.modelpop.motor_vehicle_status = "";
      }
      if (
        this.modelpop.motor_bundle_type == null ||
        this.modelpop.motor_bundle_type == undefined
      ) {
        this.modelpop.motor_bundle_type = "";
      }
      if (
        this.modelpop.ins_dec_from == null ||
        this.modelpop.ins_dec_from == undefined
      ) {
        this.modelpop.ins_dec_from = "";
      }
      if (
        this.modelpop.ins_dec_to == null ||
        this.modelpop.ins_dec_to == undefined
      ) {
        this.modelpop.ins_dec_to = "";
      }
      if (
        this.modelpop.customer_type == null ||
        this.modelpop.customer_type == undefined
      ) {
        this.modelpop.customer_type = "";
      }
      if (
        this.modelpop.sum_insured_from == null ||
        this.modelpop.sum_insured_from == undefined
      ) {
        this.modelpop.sum_insured_from = "";
      }
      if (
        this.modelpop.sum_insured_to == null ||
        this.modelpop.sum_insured_to == undefined
      ) {
        this.modelpop.sum_insured_to = "";
      }
      if (
        this.modelpop.netpremium == null ||
        this.modelpop.netpremium == undefined
      ) {
        this.modelpop.netpremium = "";
      }
      if (
        this.modelpop.lta_bundle_type == null ||
        this.modelpop.lta_bundle_type == undefined
      ) {
        this.modelpop.lta_bundle_type = "";
      }
      if (
        this.modelpop.doeFilter == null ||
        this.modelpop.doeFilter == undefined
      ) {
        this.modelpop.doeFilter = "";
      }
      if (
        this.modelpop.lta_plan_type == null ||
        this.modelpop.lta_plan_type == undefined
      ) {
        this.modelpop.lta_plan_type = "";
      }
      this.finalcardstatus = data.finalcardstatus;
      if (this.finalcardstatus == null || this.finalcardstatus == undefined) {
        this.finalcardstatus = "";
      }
      // this.selectedType = data.selectedType;
      if (this.selectedType == null || this.selectedType == undefined) {
        this.selectedType = "";
      }
      this.slctdLob = data.selectedLob;
      if (
        (this.searchparavalue == "" || this.searchparavalue == null) &&
        this.slctdLob != "" &&
        this.searchparavalue != null
      ) {
        this.searchparavalue = this.slctdLob;
      }
      if (this.slctdLob == null || this.slctdLob == undefined) {
        this.slctdLob = "";
      }
      //this.model.ad_startDate = data.startDate;
      if (
        this.model.ad_startDate == null ||
        this.model.ad_startDate == undefined
      ) {
        this.model.ad_startDate = "";
      }
      //this.model.ad_endtDate = data.endDate;
      if (this.model.ad_endDate == null || this.model.ad_endDate == undefined) {
        this.model.ad_endDate = "";
      }
      this.receiptSearchBtn = data.receiptSearchBtn;
      if (this.receiptSearchBtn == null || this.receiptSearchBtn == undefined) {
        this.receiptSearchBtn = false;
      }
      this.disDownload = false;
      console.log(this.modelpop);
    }
  }

  CtokenNavigationWorkMenCompensation(_Index, resolve, arg) {
    if (sessionStorage.getItem("impersonate") == "true") {
      var data1 =
        "utype=" +
        sessionStorage.getItem("usertype") +
        "&proposalno=&ntid=" +
        sessionStorage.getItem("user_email_id") +
        "&epo=1605691026";
    } else {
      let email = sessionStorage.getItem("email");
      var data1 =
        "utype=" +
        sessionStorage.getItem("usertype") +
        "&proposalno=&ntid=" +
        email +
        "&epo=1605691026";
    }
    let producerCode = sessionStorage.getItem("producer_code")
      ? sessionStorage.getItem("producer_code")
      : sessionStorage.getItem("broker_code")
      ? sessionStorage.getItem("broker_code")
      : "";

    if (sessionStorage.getItem("usertype") === "others") {
      let tempdata1 = data1;
      let data2 = `&alias=${
        sessionStorage.getItem("alias_email")
          ? sessionStorage.getItem("alias_email")
          : ""
      }`;
      data1 = tempdata1.concat(data2);
    }
    this.service.getcToken({ data: data1 }).subscribe((response) => {
      if (response.status === 0) {
        resolve(response.data);
        const ctoken = response.data.response;
        let url = "";

        if (
          this.service.productArray[_Index].impersonate &&
          this.service.productArray[_Index].productName ===
            "Employee Compensation Rater"
        ) {
          if (
            arg.quote_id?.length >= 0 &&
            (arg.quote_no == undefined ||
              arg.quote_no == null ||
              arg.quote_no === "") &&
            (arg.proposal_no == undefined ||
              arg.proposal_no == null ||
              arg.proposal_no === "")
          ) {
            url = `${
              this.service.productArray[_Index].quoteSearch
            }?ctoken=${ctoken}#policy?quote_id=${arg.quote_id}&policy_id=${
              arg.policy_id
            }&product_id=${
              this.service.productArray[_Index].productId
            }&state_name=${sessionStorage.getItem(
              "state_name"
            )}&channel=${sessionStorage.getItem(
              "channel"
            )}&state_code=${sessionStorage.getItem(
              "state_code"
            )}&location=${sessionStorage.getItem(
              "location"
            )}&location_code=${sessionStorage.getItem(
              "location_code"
            )}&branch_gstin_no=${sessionStorage.getItem(
              "branch_gstin_no"
            )}&impersonate=${
              sessionStorage.getItem("impersonate") == null
                ? "false"
                : sessionStorage.getItem("impersonate")
            }&email=${
              sessionStorage.getItem("email") == null
                ? ""
                : sessionStorage.getItem("email")
            }&auth-token=${
              sessionStorage.getItem("in-auth-token") == null
                ? ""
                : sessionStorage.getItem("in-auth-token")
            }&sol_id=${
              sessionStorage.getItem("sol_id") == null
                ? ""
                : sessionStorage.getItem("sol_id")
            }&sp_code=${
              sessionStorage.getItem("sp_code") == null
                ? ""
                : sessionStorage.getItem("sp_code")
            }&sp_name=${
              sessionStorage.getItem("sp_name") == null
                ? ""
                : sessionStorage.getItem("sp_name")
            }`;
            // url = this.service.productArray[_Index].quoteSearch + 'policy_id=' + arg.policy_id + '&ctoken=' + ctoken;
          } else if (
            this.finalcardstatus === "quote" &&
            arg.quote_id?.length >= 0 &&
            arg.quote_no?.length > 0 &&
            (arg.proposal_no == undefined ||
              arg.proposal_no == null ||
              arg.proposal_no === "")
          ) {
            url =
              this.service.productArray[_Index].convertToProposal +
              arg.quote_id;
          } else if (
            this.finalcardstatus === "proposal" ||
            this.finalcardstatus === "policy"
          ) {
            url =
              this.service.productArray[_Index].proposalSearch + arg.quote_id;
          }
        } else if (
          !this.service.productArray[_Index].impersonate &&
          this.service.productArray[_Index].productName !=
            "Employee Compensation Rater" &&
          (this.service.productArray[_Index].lob === "Warranty products" ||
            this.service.productArray[_Index].lob === "Health")
        ) {
          url = `${this.service.productArray[_Index].urlLink}?quote_id=${
            arg.quote_id
          }&policy_id=${arg.policy_id}&state_name=${sessionStorage
            .getItem("state_name")
            .toLowerCase()
            .replace(/\s/g, "")}&channel=${sessionStorage
            .getItem("channel")
            .toLowerCase()
            .replace(/\s/g, "")}&state_code=${sessionStorage
            .getItem("state_code")
            .toLowerCase()
            .replace(/\s/g, "")}&location=${sessionStorage
            .getItem("location")
            .toLowerCase()
            .replace(/\s/g, "")}&location_code=${sessionStorage
            .getItem("location_code")
            .toLowerCase()
            .replace(/\s/g, "")}&branch_gstin_no=${sessionStorage
            .getItem("branch_gstin_no")
            .toLowerCase()
            .replace(/\s/g, "")}&impersonate=${
            sessionStorage.getItem("impersonate") == null
              ? "false"
              : sessionStorage.getItem("impersonate")
          }&email=${
            sessionStorage.getItem("email") == null
              ? ""
              : sessionStorage.getItem("email")
          }&producer_code=${producerCode}&sol_id=${sessionStorage.getItem(
            "sol_id"
          )}&auth-token=${
            sessionStorage.getItem("in-auth-token") == null
              ? ""
              : sessionStorage.getItem("in-auth-token")
          }&ctoken=${ctoken}`;
        } else {
          url = `${
            this.service.productArray[_Index].searchUrl
          }?ctoken=${ctoken}#policy?quote_id=${arg.quote_id}&policy_id=${
            arg.policy_id
          }&product_id=${
            this.service.productArray[_Index].productId
          }&state_name=${sessionStorage.getItem(
            "state_name"
          )}&channel=${sessionStorage.getItem(
            "channel"
          )}&state_code=${sessionStorage.getItem(
            "state_code"
          )}&location=${sessionStorage.getItem(
            "location"
          )}&location_code=${sessionStorage.getItem(
            "location_code"
          )}&branch_gstin_no=${sessionStorage.getItem(
            "branch_gstin_no"
          )}&impersonate=${
            sessionStorage.getItem("impersonate") == null
              ? "false"
              : sessionStorage.getItem("impersonate")
          }&email=${
            sessionStorage.getItem("email") == null
              ? ""
              : sessionStorage.getItem("email")
          }&auth-token=${
            sessionStorage.getItem("in-auth-token") == null
              ? ""
              : sessionStorage.getItem("in-auth-token")
          }`;
        }
        // let url = this.service.workman_path + 'product_id=' + this.service.workman_product_id+'&ctoken='+ctoken;
        //  window.location.href = url;
        // openinglink in new tab
        window.open(url, "_blank");
        return false;
      }
    });
    return false;
  }

  CtokenNavigationWorkMenCompensationProduct(_Index, resolve) {
    let privilege = this._CheckRolePrivilege();
    if (privilege) {
      if (sessionStorage.getItem("impersonate") == "true") {
        var data1 =
          "utype=" +
          sessionStorage.getItem("usertype") +
          "&proposalno=&ntid=" +
          sessionStorage.getItem("user_email_id") +
          "&epo=1605691026";
      } else {
        let email = sessionStorage.getItem("email");
        var data1 =
          "utype=" +
          sessionStorage.getItem("usertype") +
          "&proposalno=&ntid=" +
          email +
          "&epo=1605691026";
      }

      if (sessionStorage.getItem("usertype") === "others") {
        let tempdata1 = data1;
        let data2 = `&alias=${
          sessionStorage.getItem("alias_email")
            ? sessionStorage.getItem("alias_email")
            : ""
        }`;
        data1 = tempdata1.concat(data2);
      }

      this.service.getcToken({ data: data1 }).subscribe(
        (response) => {
          if (response.status === 0) {
            resolve(response.data);
            const ctoken = response.data.response;
            let url = "";
            let producerCode = sessionStorage.getItem("producer_code")
              ? sessionStorage.getItem("producer_code")
              : sessionStorage.getItem("broker_code")
              ? sessionStorage.getItem("broker_code")
              : "";
            if (
              this.service.productArray[_Index]?.extraParams &&
              this.service.productArray[_Index].impersonate
            ) {
              url = `${
                this.service.productArray[_Index].urlLink
              }?ctoken=${ctoken}#policy?product_id=${
                this.service.productArray[_Index].productId
              }&state_name=${sessionStorage
                .getItem("state_name")
                .toLowerCase()
                .replace(/\s/g, "")}&channel=${sessionStorage
                .getItem("channel")
                .toLowerCase()
                .replace(/\s/g, "")}&state_code=${sessionStorage
                .getItem("state_code")
                .toLowerCase()
                .replace(/\s/g, "")}&location=${sessionStorage
                .getItem("location")
                .toLowerCase()
                .replace(/\s/g, "")}&location_code=${sessionStorage
                .getItem("location_code")
                .toLowerCase()
                .replace(/\s/g, "")}&branch_gstin_no=${sessionStorage
                .getItem("branch_gstin_no")
                .toLowerCase()
                .replace(/\s/g, "")}&impersonate=${
                sessionStorage.getItem("impersonate") == null
                  ? "false"
                  : sessionStorage.getItem("impersonate")
              }&email=${
                sessionStorage.getItem("email") == null
                  ? ""
                  : sessionStorage.getItem("email")
              }&auth-token=${
                sessionStorage.getItem("in-auth-token") == null
                  ? ""
                  : sessionStorage.getItem("in-auth-token")
              }&sol_id=${
                sessionStorage.getItem("sol_id") == null
                  ? ""
                  : sessionStorage.getItem("sol_id")
              }&sp_code=${
                sessionStorage.getItem("sp_code") == null
                  ? ""
                  : sessionStorage.getItem("sp_code")
              }&sp_name=${
                sessionStorage.getItem("sp_name") == null
                  ? ""
                  : sessionStorage.getItem("sp_name")
              }`;
              // url = `${this.service.productArray[_Index].urlLink}?product_id=${this.service.productArray[_Index].productId}&state_name=${sessionStorage.getItem("state_name").toLowerCase().replace(/\s/g, '')}&channel=${sessionStorage.getItem("channel").toLowerCase().replace(/\s/g, '')}&state_code=${sessionStorage.getItem("state_code").toLowerCase().replace(/\s/g, '')}&location=${sessionStorage.getItem("location").toLowerCase().replace(/\s/g, '')}&location_code=${sessionStorage.getItem("location_code").toLowerCase().replace(/\s/g, '')}&branch_gstin_no=${sessionStorage.getItem("branch_gstin_no").toLowerCase().replace(/\s/g, '')}&impersonate=${sessionStorage.getItem("impersonate") == null ? 'false' : sessionStorage.getItem("impersonate")}&email=${sessionStorage.getItem("email") == null ? '' : sessionStorage.getItem("email")}&auth-token=${sessionStorage.getItem("in-auth-token") == null ? '' : sessionStorage.getItem("in-auth-token")}&ctoken=${ctoken}`;
            } else if (
              this.service.productArray[_Index]?.extraParams &&
              !this.service.productArray[_Index].impersonate
            ) {
              url = `${this.service.productArray[_Index].urlLink}?product_id=${
                this.service.productArray[_Index].productId
              }&state_name=${sessionStorage
                .getItem("state_name")
                .toLowerCase()
                .replace(/\s/g, "")}&channel=${sessionStorage
                .getItem("channel")
                .toLowerCase()
                .replace(/\s/g, "")}&state_code=${sessionStorage
                .getItem("state_code")
                .toLowerCase()
                .replace(/\s/g, "")}&location=${
                this.service.productArray[_Index]?.productName == "EW"
                  ? sessionStorage.getItem("location")
                  : sessionStorage
                      .getItem("location")
                      ?.toLowerCase()
                      ?.replace(/\s/g, "")
              }&location_code=${sessionStorage
                .getItem("location_code")
                .toLowerCase()
                .replace(/\s/g, "")}&branch_gstin_no=${sessionStorage
                .getItem("branch_gstin_no")
                .toLowerCase()
                .replace(/\s/g, "")}&impersonate=${
                sessionStorage.getItem("impersonate") == null
                  ? "false"
                  : sessionStorage.getItem("impersonate")
              }&email=${
                sessionStorage.getItem("email") == null
                  ? ""
                  : sessionStorage.getItem("email")
              }&producer_code=${producerCode}&auth-token=${
                sessionStorage.getItem("in-auth-token") == null
                  ? ""
                  : sessionStorage.getItem("in-auth-token")
              }&ctoken=${ctoken}&sol_id=${
                sessionStorage.getItem("sol_id") == null
                  ? ""
                  : sessionStorage.getItem("sol_id")
              }&sp_code=${
                sessionStorage.getItem("sp_code") == null
                  ? ""
                  : sessionStorage.getItem("sp_code")
              }&sp_name=${
                sessionStorage.getItem("sp_name") == null
                  ? ""
                  : sessionStorage.getItem("sp_name")
              }`;
            } else {
              url =
                this.service.productArray[_Index].urlLink +
                "?product_id=" +
                this.service.productArray[_Index].productId +
                "&ctoken=" +
                ctoken;
            }

            // let url = this.service.productArray[_Index].urlLink + 'product_id=' + this.service.productArray[_Index].productId+'&ctoken='+ctoken;
            // let url = this.service.workman_path + 'product_id=' + this.service.workman_product_id+'&ctoken='+ctoken;
            //  window.location.href = url;
            // openinglink in new tab
            console.log("check with deployment updated");
            window.open(url, "_blank");
            return false;
          } else if (response.status === -114) {
            this.swalService.error(response.txt);
          } else {
            this.swalService.error(response.txt);
          }
        },
        (err) => {
          this.swalService.error("Server Timeout");
        }
      );
      return false;
    }
  }

  _CheckRolePrivilege() {
    const role = sessionStorage
      .getItem("role")
      .toLowerCase()
      .replace(/\s/g, "");

    if (role == "customersupport" || role == "customersupportretention") {
      this.swalService.error(
        this.translate.instant("staticTs.doNotHaveRights")
      );
      return false;
    } else if (role == "underwriter") {
      this.swalService.error(
        this.translate.instant("staticTs.notAllowedForUnderwriter")
      );
      return false;
    } else {
      return true;
    }
  }

  async navigation(arg, index, ConfirmationMessage_template, item) {
    index = index + (this.page - 1) * item;
    if (this.receiptSearchBtn) {
      this.tolowercase = "proposal"; //for receipt search card always redirect to proposal - discussed with bipro
      this.finalcardstatus = "proposal";
    }
    if (arg.product_id) {
      if (
        arg.product_id == this.service.home_product_id ||
        arg.product_id == this.service.aigcv2_product_id ||
        arg.product_id == this.service.aigCombined
      ) {
        // if(arg.product_id == this.service.home_product_id ){
        this.productsPageHome(arg);
        return false;
      }
    }

    let productIdInServiceArrFound = false;
    for (let i = 0; i < this.service.productArray.length; i++) {
      if (
        this.service.productArray[i].productId == arg.product_id &&
        this.service.productArray[i].search === true
      ) {
        productIdInServiceArrFound = true;
        await new Promise((resolve) => {
          this.CtokenNavigationWorkMenCompensation(i, resolve, arg);
        });
      }
    }

    //  this.productsPage(arg);
    if (this.cancelClick == false && !productIdInServiceArrFound) {
      this.loading = true;
      await this.spinner.show();

      this.card_index = index; //muruga 26/20/2020
      let email = sessionStorage.getItem("email");
      let comp: any = "";
      if (
        this.finalcardstatus == "policy" ||
        this.finalcardstatus == "certificate"
      ) {
        comp = "proposal";
      } else {
        comp = this.finalcardstatus;
      }
      /*****************LTA & BTA muruga 20/11/2020**********Waats policy functionality***/
      if (this.finalcardstatus == "waats policy") {
        if (this.lob_value.toLowerCase() == "lta") {
          await this.service.httpPdfDownload(
            location.protocol +
              "//" +
              this.urlValue +
              "/api/v1/travelpreprocess/pdf?policy=" +
              arg.policy_no,
            arg.policy_no
          ); //.substring(4, ));
        } else if (this.lob_value.toLowerCase() == "travel") {
          ///await this.service.httpPdfDownload(location.protocol + '//' + this.urlValue + '/api/v1/travelpreprocess/pdf?policy=' + arg.policy_no, arg.policy_no);  // muruga 20/11/2020
          await this.service.httpPdfDownload(
            location.protocol +
              "//" +
              this.urlValue +
              "/api/v1/travelpreprocess/pdf?policy=" +
              arg.policy_no +
              "&product=BTA",
            arg.policy_no
          ); // muruga 23/11/2020
        }
      } /*****************Muruga 26/10/2020*********** NSTP process Take over*** */
      // LTA Redirecting issue
      else if (
        this.lob_value.toLowerCase() == "lta" &&
        this.profileData?.data?.is_underwriter != 1 &&
        arg?.cust_fld_1?.toLowerCase() == "renewal"
      ) {
        await this.ltaRenewalNavigation(
          arg,
          this.selectedStageVal.toLowerCase()
        );
        return;
      } else if (
        this.lob_value.toLowerCase() == "lta" &&
        email == this.cardlist[index]["assigned_to"] &&
        this.profileData.data.is_underwriter == 1 &&
        this.cardlist[index].data.search &&
        this.cardlist[index].data.search.nstp_status != "" &&
        this.cardlist[index].data.search.nstp_status != undefined
      ) {
        sessionStorage.setItem("product_id", arg.product_id);
        if (arg?.cust_fld_1?.toLowerCase() == "renewal") {
          await this.ltaRenewalNavigation(arg, "proposal");
          return;
        }
        if (arg.product_id == environment.lta2_productId) {
          comp = "proposal";
        }
        this.service.searchnavigation(arg.product_id).subscribe((resnav) => {
          if (resnav.status === 0) {
            this.searchnavigationlist = resnav.data;
            for (let i in this.searchnavigationlist) {
              if (this.searchnavigationlist[i].value == comp) {
                sessionStorage.setItem("product_id", arg.product_id);
                const { origin } = window.location;
                window.location.href = `${origin}/${this.searchnavigationlist[i].name}${arg.quote_id}`;
              }
            }
          }
        });
      } else if (
        this.lob_value.toLowerCase() == "lta" &&
        email != this.cardlist[index]["assigned_to"] &&
        this.profileData.data.is_underwriter == 1 &&
        this.cardlist[index].data.search &&
        this.cardlist[index].data.search.nstp_status != "" &&
        this.cardlist[index].data.search.nstp_status != undefined
      ) {
        if (
          this.cardlist[index].data.search.nstp_status.toLowerCase() ==
          "pending for approval"
        ) {
          // if(arg?.cust_fld_1?.toLowerCase() == 'renewal'){
          // 	await this.ltaRenewalNavigation(arg);
          // 	return;
          // }
          this.modalRef = this.modalService.show(
            ConfirmationMessage_template,
            this.config
          );
        } else {
          if (arg?.cust_fld_1?.toLowerCase() == "renewal") {
            await this.ltaRenewalNavigation(arg, "proposal");
            return;
          }
          this.service.searchnavigation(arg.product_id).subscribe((resnav) => {
            if (resnav.status === 0) {
              this.searchnavigationlist = resnav.data;
              for (let i in this.searchnavigationlist) {
                if (this.searchnavigationlist[i].value == comp) {
                  sessionStorage.setItem("product_id", arg.product_id);
                  const { origin } = window.location;
                  window.location.href = `${origin}/${this.searchnavigationlist[i].name}${arg.quote_id}`;
                }
              }
            }
          });
        }
      }
      // search navigation for uw smp channel draft case
      else if (
        this.lob_value.toLowerCase() == "marinecargo" &&
        this.profileData.data.is_underwriter == 1 &&
        this.cardlist[index].data.search &&
        this.cardlist[index].data.search.nstp_status != "" &&
        this.cardlist[index].data.search.nstp_status != undefined &&
        comp == "draft"
      ) {
        this.service.searchnavigation(arg.product_id).subscribe((responav) => {
          if (responav.status === 0) {
            this.searchnavigationlist = responav.data;

            if (
              arg.quote?.data?.transaction_type === "new flow" &&
              arg?.quote?.data?.query_option_yn?.toLowerCase() === "yes" &&
              arg?.data?.search?.nstp_status?.toLowerCase() === "queried"
            ) {
              let navUrl = this.searchnavigationlist?.filter((item) => {
                return item.value === "query";
              });
              if (navUrl?.length > 0 && navUrl[0]?.name?.length > 0) {
                window.location.href = `${origin}/${navUrl[0]?.name}${arg.quote_id}`;
                return;
              }
            }

            if (arg.quote?.data?.transaction_type === "new flow") {
              let navObject = this.searchnavigationlist?.filter((item) => {
                return item.description === "endorsement";
              });
              if (navObject?.length > 0 && navObject[0]?.name) {
                window.location.href = `${origin}/${navObject[0].name}${arg.quote_id}`;
                return;
              }
            }

            for (let i in this.searchnavigationlist) {
              if (
                comp == "draft" &&
                this.searchnavigationlist[i].value == "quote"
              ) {
                sessionStorage.setItem("product_id", arg.product_id);

                const { origin } = window.location;

                window.location.href = `${origin}/${this.searchnavigationlist[i].name}${arg.quote_id}`;
                return;
              }
            }
          }
        });
      } else if (
        this.lob_value.toLowerCase() == "lta" &&
        arg?.cust_fld_1?.toLowerCase() == "renewal" &&
        this.finalcardstatus === "proposal"
      ) {
        await this.ltaRenewalNavigation(arg, "proposal");
        return;
      } else if (
        this.lob_value.toLowerCase() == "home" &&
        arg?.data?.search?.business_type?.toLowerCase() == "renewal"
      ) {
        await this.homeRenewalNavigation(arg, this.finalcardstatus);
        return;
      } else {
        if (this.cardlist[index]?.data?.search?.crosell_id) {
          arg.product_id = this.cardlist[index]?.data?.search?.parent_product_id;
          if(this.cardlist[index]?.data?.search?.parent_quote_id != undefined && this.cardlist[index]?.data?.search?.parent_quote_id != ''){
            arg.quote_id = this.cardlist[index]?.data?.search?.parent_quote_id
          }
        }
        this.service.searchnavigation(arg.product_id).subscribe((respon) => {
          if (respon.status === 0) {
            this.searchnavigationlist = respon.data;
            this.aigcNBNewFlow(arg, comp);
            if (
              arg?.quote?.data?.query_option_yn?.toLowerCase() === "yes" &&
              arg?.data?.search?.nstp_status?.toLowerCase() === "queried"
            ) {
              let navUrl = this.searchnavigationlist?.filter((item) => {
                return item.value === "query";
              });
              if (navUrl?.length > 0 && navUrl[0]?.name?.length > 0) {
                window.location.href = `${origin}/${navUrl[0]?.name}${arg.quote_id}`;
                return;
              }
            }
            if (arg?.quote?.data?.tag?.toUpperCase() === "EDI") {
              let navUrl = this.searchnavigationlist?.filter((item) => {
                return item.value === "proposal";
              });
              window.location.href = `${origin}/${navUrl[0].name}${arg.quote_id}`;
              return;
            }
            if (arg.quote?.data?.transaction_type === "new flow") {
              let navObject = this.searchnavigationlist?.filter((item) => {
                return item.description === "endorsement";
              });
              if (navObject?.length > 0 && navObject[0]?.name) {
                window.location.href = `${origin}/${navObject[0].name}${arg.quote_id}`;
                return;
              }
            }
            if (
              this.selectedLob.toLowerCase() === "lta" &&
              arg?.product_id === environment.lta2_productId &&
              arg?.proposal_no?.length > 0
            ) {
              // LTA Quote Stage redirection for proposal generated cases
              let navUrl = this.searchnavigationlist?.filter((item) => {
                return item.value === "proposal";
              });
              window.location.href = `${origin}/${navUrl[0].name}${arg.quote_id}`;
              return;
            }

            // GMCEE Redirection
            if (
              this.selectedLob.toLowerCase() === "personalaccident" &&
              arg?.product_id === environment.gmcEEMasterId
            ) {
              sessionStorage.setItem("product_id", arg.product_id);
              sessionStorage.setItem("searchPage", "true");

              let navUrl = this.searchnavigationlist?.filter((item) => {
                return item.value === "quote";
              });

              window.location.href = `${origin}/${navUrl[0].name}${arg.quote_id}`;
              return;
            }

            for (let ind in this.searchnavigationlist) {
              console.log(arg); // endorsement navigation not dependant on stage , only single URL for all stages

              if (
                this.searchnavigationlist[ind].value == comp ||
                this.searchnavigationlist[ind].value?.toLowerCase() == comp ||
                (comp == "draft" &&
                  this.searchnavigationlist[ind].value == "quote")
              ) {
                // || (comp == 'draft' && this.searchnavigationlist[ind].value =='quote' ) added for draft navigation- chandini
                sessionStorage.setItem("product_id", arg.product_id);
                const { origin } = window.location;
                if (this.cardlist[index]?.data?.search?.cross_sell || this.cardlist[index]?.data?.search?.crosell_id) {
                  let quote_id = arg?.data?.search?.quote_id
                    ? arg?.data?.search?.quote_id[0]
                    : arg?.quote_id;
                  window.location.href = `${origin}/${this.searchnavigationlist[ind].name}${quote_id};type=cross-sell`;
                  return;
                }

                if (arg?.data?.search?.fetchDataBoolean) {
                  this.motorOptimisedNavigation(
                    this.searchnavigationlist[ind],
                    arg
                  );
                  return;
                }

                window.location.href = `${origin}/${this.searchnavigationlist[ind]?.name}${arg?.quote_id}`;
                return;
              }
            }
          }
        });
      }

      await this.spinner.hide();
      this.loading = false;
    }

    this.updateSearchFilterInSession();
  }

  motorOptimisedNavigation(navigation, arg) {
    const nameArr = navigation.name.split("#");
    nameArr[1] = "#/cvOptimizedProposal/";

    const updateURL = nameArr.join("");
    window.location.href = `${origin}/${updateURL}${arg?.quote_id}`;
  }

  aigcNBNewFlow(arg, comp) {
    let value = comp;
    if (arg.quote?.data?.transaction_type === "NB-New Flow") {
      if (
        arg?.quote?.data?.query_option_yn?.toLowerCase() === "yes" &&
        arg?.data?.search?.nstp_status?.toLowerCase() === "queried"
      ) {
        comp = "query";
      }
      value = comp == "draft" ? "quote" : comp;
      let navObject = this.searchnavigationlist?.find((item) => {
        return item.description === "NB-New Flow" && item.value == value;
      });
      window.location.href = `${origin}/${navObject?.name}${arg?.quote_id}`;
      return;
    } else {
      let removeNewNBFlow = this.searchnavigationlist?.filter((item) => {
        return item.description != "NB-New Flow";
      });
      this.searchnavigationlist = removeNewNBFlow;
    }
  }

  async productsPageHome(product) {
    if (
      product.product_id == this.service.aigCombined ||
      product.product_id == this.service.aigcv2_product_id
    ) {
      const { origin } = window.location;
      sessionStorage.setItem("product_id", product.product_id);
      if (this.tolowercase === "draft" || this.tolowercase === "quote") {
        window.location.href = `${origin}/ipdsv2/qms/aigcv2/#/aigc/quote/preview-quote/${product.quote_id}`;
      } else if (
        this.tolowercase === "proposal" ||
        this.tolowercase === "policy"
      ) {
        window.location.href = `${origin}/ipdsv2/qms/aigcv2/#/aigc/proposal/review-proposal/${product.quote_id}`;
      }
      return false;
    }

    if (product.data.product_name) {
      
      if (product.product_id == this.service.home_product_id) {
        // if(productId=='M200000000004' ||'M000000001004'){
        //alert('Test');

        if (sessionStorage.getItem("impersonate") == "true") {
          var data1 =
            "utype=" +
            sessionStorage.getItem("usertype") +
            "&proposalno=&ntid=" +
            sessionStorage.getItem("user_email_id") +
            "&epo=1605691026";
        } else {
          
          let email = sessionStorage.getItem("email");
          var data1 =
            "utype=" +
            sessionStorage.getItem("usertype") +
            "&proposalno=&ntid=" +
            email +
            "&epo=1605691026";

          //     var data1 = "utype=" + sessionStorage.getItem('usertype') + "&proposalno=&ntid=" + sessionStorage.getItem('email') + "&epo=1605691026"
        }

        if (sessionStorage.getItem("usertype") === "others") {
          let tempdata1 = data1;
          let data2 = `&alias=${
            sessionStorage.getItem("alias_email")
              ? sessionStorage.getItem("alias_email")
              : ""
          }`;
          data1 = tempdata1.concat(data2);
        }

        this.service.getcToken({ data: data1 }).subscribe(
          (resp) => {
            if (resp.status === 0) {
              const ctoken = resp.data.response;

              window.location.href = `${
                this.service.ctoken_nav_url
              }?ctoken=${ctoken}#policy?quote_id=${
                product.quote_id
              }&state_name=${sessionStorage.getItem(
                "state_name"
              )}&channel=${sessionStorage.getItem(
                "channel"
              )}&state_code=${sessionStorage.getItem(
                "state_code"
              )}&location=${sessionStorage.getItem(
                "location"
              )}&location_code=${sessionStorage.getItem(
                "location_code"
              )}&branch_gstin_no=${sessionStorage.getItem(
                "branch_gstin_no"
              )}&impersonate=${
                sessionStorage.getItem("impersonate") == null
                  ? "false"
                  : sessionStorage.getItem("impersonate")
              }&email=${
                sessionStorage.getItem("email") == null
                  ? ""
                  : sessionStorage.getItem("email")
              }&auth-token=${
                sessionStorage.getItem("in-auth-token") == null
                  ? ""
                  : sessionStorage.getItem("in-auth-token")
              }`;
              return false;
              // window.location.href = `${this.service.ctoken_nav_url}??ctoken=${ctoken}#policy?quote_id=${product.quote_id}`;
            } else if (resp.status === -114) {
              this.swalService.error(resp.txt);
            } else {
              this.swalService.error(resp.txt);
            }
          },
          (err) => {
            this.swalService.error("Server Timeout");
          }
        );
        return false;
      } else {
        Swal.fire(
          this.translate.instant("staticTs.oops"),
          "Product Not Mapped",
          this.translate.instant("staticTs.error")
        );
      }
    } else {
      Swal.fire(
        this.translate.instant("staticTs.oops"),
        "Product Not Mapped",
        this.translate.instant("staticTs.error")
      );
    }
  }

  onEventStopPropogation(event) {
    event.stopPropagation();
  }

  /**
   * Close existing opened drop menus
   */
  closeDropMenu(event?) {
    var dropdownElementList = [].slice.call(
      document.querySelectorAll(".dropdown-btn")
    );
    dropdownElementList.map(function (dropdownToggleEl) {
      if (dropdownToggleEl.classList.contains("show")) {
        new bootstrap.Dropdown(dropdownToggleEl).hide();
      }
    });

    if (event) {
      event.stopPropagation();
      let dropdown = new bootstrap.Dropdown(
        document.getElementById(event.currentTarget.id)
      );
      dropdown.show();
    }
  }

  async productsPage(productId, url, product) {
    this.storeFilterDetails();
    let lob = this.lobData.find((item) => {
      if (item.lob_value == this.selectedLob) return item.lob;
    });
    this.current_lob = lob.lob;
    sessionStorage.setItem("current_lob", this.current_lob);
    sessionStorage.setItem("lob_value", this.selectedLob);
    let Url = document.location.href;
    sessionStorage.setItem("backFromCustomerdiary", Url);
    sessionStorage.setItem("product_id", product.product_id);

    let isCustomerDiary = false;
    for (let product of this.service.customerDiaryProducts) {
      if (product != null && product["productId"] == productId) {
        isCustomerDiary = true;
      }
    }

    if (isCustomerDiary) {
      sessionStorage.setItem("product_id", productId);
      window.location.href = `${window.location.origin}${this.service.customerDiaryPath}`;
    } else {
      // return false;
      let productIdInServiceArrFound = false;
      for (let i = 0; i < this.service.productArray.length; i++) {
        if (
          this.service.productArray[i].productId == productId &&
          this.service.productArray[i].allEachApp
        ) {
          productIdInServiceArrFound = true;
          await new Promise((resolve) => {
            this.CtokenNavigationWorkMenCompensationProduct(i, resolve);
          });
        }
      }

      if (!productIdInServiceArrFound) {
        if (
          sessionStorage.getItem("role").toLowerCase().replace(/\s/g, "") ==
            "customersupport" ||
          sessionStorage.getItem("role").toLowerCase().replace(/\s/g, "") ==
            "customersupportretention"
        ) {
          Swal.fire(
            this.translate.instant("staticTs.oops"),
            this.translate.instant("staticTs.doNotHaveRights"),
            this.translate.instant("staticTs.error")
          );
          return false;
        }
        if (
          this.service.aigcv2_product_id?.length > 0 &&
          this.service.aigcv2_product_id === productId
        ) {
          // if(productId=='M200000000004' ||'M000000001004'){
          if (
            sessionStorage.getItem("role").toLowerCase().replace(/\s/g, "") ==
            "underwriter"
          ) {
            Swal.fire(
              this.translate.instant("staticTs.oops"),
              this.translate.instant("staticTs.notAllowedForUnderwriter"),
              this.translate.instant("staticTs.error")
            );
            return false;
          }
          if (sessionStorage.getItem("impersonate") == "true") {
            var data1 =
              "utype=" +
              sessionStorage.getItem("usertype") +
              "&proposalno=&ntid=" +
              sessionStorage.getItem("user_email_id") +
              "&epo=1605691026";
          } else {
            //Architectural changes
            let email = sessionStorage.getItem("email");
            var data1 =
              "utype=" +
              sessionStorage.getItem("usertype") +
              "&proposalno=&ntid=" +
              email +
              "&epo=1605691026";
            //   var data1 = "utype=" + sessionStorage.getItem('usertype') + "&proposalno=&ntid=" + sessionStorage.getItem('email') + "&epo=1605691026"
          }

          if (sessionStorage.getItem("usertype") === "others") {
            let tempdata1 = data1;
            let data2 = `&alias=${
              sessionStorage.getItem("alias_email")
                ? sessionStorage.getItem("alias_email")
                : ""
            }`;
            data1 = tempdata1.concat(data2);
          }

          this.service.getcToken({ data: data1 }).subscribe(
            (response) => {
              if (response.status === 0) {
                const ctoken = response.data.response;
                let url;

                console.log(
                  `${
                    this.service.ctoken_nav_url
                  }?ctoken=${ctoken}#policy?product_id=${
                    this.service.aigcv2_product_id
                  }&state_name=${sessionStorage
                    .getItem("state_name")
                    .toLowerCase()
                    .replace(/\s/g, "")}&channel=${sessionStorage
                    .getItem("channel")
                    .toLowerCase()
                    .replace(/\s/g, "")}&state_code=${sessionStorage
                    .getItem("state_code")
                    .toLowerCase()
                    .replace(/\s/g, "")}&location=${sessionStorage
                    .getItem("location")
                    .toLowerCase()
                    .replace(/\s/g, "")}&location_code=${sessionStorage
                    .getItem("location_code")
                    .toLowerCase()
                    .replace(/\s/g, "")}&branch_gstin_no=${sessionStorage
                    .getItem("branch_gstin_no")
                    .toLowerCase()
                    .replace(/\s/g, "")}&impersonate=${
                    sessionStorage.getItem("impersonate") == null
                      ? "false"
                      : sessionStorage.getItem("impersonate")
                  }&email=${
                    sessionStorage.getItem("email") == null
                      ? ""
                      : sessionStorage.getItem("email")
                  }&auth-token=${
                    sessionStorage.getItem("auth-token") == null
                      ? ""
                      : sessionStorage.getItem("auth-token")
                  }`
                );
                // return false;
                /// window.location.href = `${this.service.ctoken_nav_url}?ctoken=${ctoken}#policy?product_id=${this.service.aigcv2_product_id}&state_name=${sessionStorage.getItem("state_name").toLowerCase().replace(/\s/g, '')}&channel=${sessionStorage.getItem("channel").toLowerCase().replace(/\s/g, '')}&state_code=${sessionStorage.getItem("state_code").toLowerCase().replace(/\s/g, '')}&location=${sessionStorage.getItem("location").toLowerCase().replace(/\s/g, '')}&location_code=${sessionStorage.getItem("location_code").toLowerCase().replace(/\s/g, '')}&branch_gstin_no=${sessionStorage.getItem("branch_gstin_no").toLowerCase().replace(/\s/g, '')}&impersonate=${sessionStorage.getItem("impersonate") == null ? 'false' : sessionStorage.getItem("impersonate")}&email=${sessionStorage.getItem("email") == null ? '' : sessionStorage.getItem("email")}&auth-token=${sessionStorage.getItem("in-auth-token") == null ? '' : sessionStorage.getItem("in-auth-token")}`;
                url = `${
                  this.service.ctoken_nav_url
                }?ctoken=${ctoken}#policy?product_id=${
                  this.service.aigcv2_product_id
                }&state_name=${sessionStorage
                  .getItem("state_name")
                  .toLowerCase()
                  .replace(/\s/g, "")}&channel=${sessionStorage
                  .getItem("channel")
                  .toLowerCase()
                  .replace(/\s/g, "")}&state_code=${sessionStorage
                  .getItem("state_code")
                  .toLowerCase()
                  .replace(/\s/g, "")}&location=${sessionStorage
                  .getItem("location")
                  .toLowerCase()
                  .replace(/\s/g, "")}&location_code=${sessionStorage
                  .getItem("location_code")
                  .toLowerCase()
                  .replace(/\s/g, "")}&branch_gstin_no=${sessionStorage
                  .getItem("branch_gstin_no")
                  .toLowerCase()
                  .replace(/\s/g, "")}&impersonate=${
                  sessionStorage.getItem("impersonate") == null
                    ? "false"
                    : sessionStorage.getItem("impersonate")
                }&email=${
                  sessionStorage.getItem("email") == null
                    ? ""
                    : sessionStorage.getItem("email")
                }&auth-token=${
                  sessionStorage.getItem("in-auth-token") == null
                    ? ""
                    : sessionStorage.getItem("in-auth-token")
                }`;
                // openinglink in new tab
                window.open(url, "_blank");
                return false;
              } else if (response.status === -114) {
                this.swalService.error(response.txt);
              } else {
                this.swalService.error(response.txt);
              }
            },
            (err) => {
              this.swalService.error("Server Timeout");
            }
          );
          return false;
        }
        //BHARAT GRIHA RAKSHA
        if (
          this.service.home_product_id?.length > 0 &&
          this.service.home_product_id === productId
        ) {
          // if(productId=='M200000000004' ||'M000000001004'){

          if (
            sessionStorage.getItem("role").toLowerCase().replace(/\s/g, "") ==
            "underwriter"
          ) {
            Swal.fire(
              this.translate.instant("staticTs.oops"),
              this.translate.instant("staticTs.notAllowedForUnderwriter"),
              this.translate.instant("staticTs.error")
            );
            return false;
          }
          if (sessionStorage.getItem("impersonate") == "true") {
            var data1 =
              "utype=" +
              sessionStorage.getItem("usertype") +
              "&proposalno=&ntid=" +
              sessionStorage.getItem("user_email_id") +
              "&epo=1605691026";
          } else {
            //Architectural Changes 16th Sept
            let email = sessionStorage.getItem("email");
            var data1 =
              "utype=" +
              sessionStorage.getItem("usertype") +
              "&proposalno=&ntid=" +
              email +
              "&epo=1605691026";

            //     var data1 = "utype=" + sessionStorage.getItem('usertype') + "&proposalno=&ntid=" + sessionStorage.getItem('email') + "&epo=1605691026"
          }

          if (sessionStorage.getItem("usertype") === "others") {
            let tempdata1 = data1;
            let data2 = `&alias=${
              sessionStorage.getItem("alias_email")
                ? sessionStorage.getItem("alias_email")
                : ""
            }`;
            data1 = tempdata1.concat(data2);
          }

          this.service.getcToken({ data: data1 }).subscribe(
            (response) => {
              if (response.status === 0) {
                const ctoken = response.data.response;
                console.log(
                  `${
                    this.service.ctoken_nav_url
                  }?ctoken=${ctoken}#policy?product_id=${
                    this.service.home_product_id
                  }&state_name=${sessionStorage.getItem(
                    "state_name"
                  )}&channel=${sessionStorage.getItem(
                    "channel"
                  )}&state_code=${sessionStorage.getItem(
                    "state_code"
                  )}&location=${sessionStorage.getItem(
                    "location"
                  )}&location_code=${sessionStorage.getItem(
                    "location_code"
                  )}&branch_gstin_no=${sessionStorage.getItem(
                    "branch_gstin_no"
                  )}&impersonate=${
                    sessionStorage.getItem("impersonate") == null
                      ? "false"
                      : sessionStorage.getItem("impersonate")
                  }&email=${
                    sessionStorage.getItem("email") == null
                      ? ""
                      : sessionStorage.getItem("email")
                  }&auth-token=${
                    sessionStorage.getItem("auth-token") == null
                      ? ""
                      : sessionStorage.getItem("auth-token")
                  }`
                );
                let url;

                // window.location.href = `${this.service.ctoken_nav_url}?ctoken=${ctoken}#policy?product_id=${this.service.home_product_id}&state_name=${sessionStorage.getItem("state_name")}&channel=${sessionStorage.getItem("channel")}&state_code=${sessionStorage.getItem("state_code")}&location=${sessionStorage.getItem("location")}&location_code=${sessionStorage.getItem("location_code")}&branch_gstin_no=${sessionStorage.getItem("branch_gstin_no")}&impersonate=${sessionStorage.getItem("impersonate") == null ? 'false' : sessionStorage.getItem("impersonate")}&email=${sessionStorage.getItem("email") == null ? '' : sessionStorage.getItem("email")}&auth-token=${sessionStorage.getItem("in-auth-token") == null ? '' : sessionStorage.getItem("in-auth-token")}`;
                url = `${
                  this.service.ctoken_nav_url
                }?ctoken=${ctoken}#policy?product_id=${
                  this.service.home_product_id
                }&state_name=${sessionStorage.getItem(
                  "state_name"
                )}&channel=${sessionStorage.getItem(
                  "channel"
                )}&state_code=${sessionStorage.getItem(
                  "state_code"
                )}&location=${sessionStorage.getItem(
                  "location"
                )}&location_code=${sessionStorage.getItem(
                  "location_code"
                )}&branch_gstin_no=${sessionStorage.getItem(
                  "branch_gstin_no"
                )}&impersonate=${
                  sessionStorage.getItem("impersonate") == null
                    ? "false"
                    : sessionStorage.getItem("impersonate")
                }&email=${
                  sessionStorage.getItem("email") == null
                    ? ""
                    : sessionStorage.getItem("email")
                }&auth-token=${
                  sessionStorage.getItem("in-auth-token") == null
                    ? ""
                    : sessionStorage.getItem("in-auth-token")
                }`;
                // openinglink in new tab
                window.open(url, "_blank");
              } else if (response.status === -114) {
                this.swalService.error(response.txt);
              } else {
                this.swalService.error(response.txt);
              }
            },
            (err) => {
              this.swalService.error("Server Timeout");
            }
          );
          return false;
        } else {
          if (window.location.origin.includes("localhost")) {
            const params = new URLSearchParams({
              "in-auth-token": sessionStorage.getItem("in-auth-token"),
              email: sessionStorage.getItem("email"),
              channel: sessionStorage.getItem("channel"),
              location: sessionStorage.getItem("location"),
              role: sessionStorage.getItem("role"),
              state_code: sessionStorage.getItem("state_code"),
              state_name: sessionStorage.getItem("state_name"),
              producer_code: sessionStorage.getItem("producer_code"),
              package_pointer: sessionStorage.getItem("package_pointer"),
              productId: productId,
            });

            window.location.href = `http://localhost:4202/#/customerDiary/customerDetails/existing?${params.toString()}`;

            return false;
          }

          if (
            this.service.environment === "PRODUCTION" ||
            this.service.environment === "BETA"
          ) {
            this.service.productPanel().subscribe(
              (response) => {
                if (response.status === 0) {
                  if (response.data.length === 0) {
                    Swal.fire(
                      this.translate.instant("staticTs.oops"),
                      "No Product Panels Listed!",
                      this.translate.instant("staticTs.error")
                    );
                  } else {
                    var productpaneldata = response.data.filter(
                      (obj) => obj.name === productId
                    );
                    if (productpaneldata.length === 0) {
                      Swal.fire(
                        this.translate.instant("staticTs.oops"),
                        "No Product Panel Available for this Product!",
                        this.translate.instant("staticTs.error")
                      );
                    } else {
                      sessionStorage.setItem(
                        "product",
                        productpaneldata[0].value
                      );
                      sessionStorage.setItem("product_id", productId);

                      const { origin } = window.location;

                      window.location.href = `${origin}/${productpaneldata[0].value}`;
                    }
                  }
                } else if (response.status === -114) {
                  this.swalService.error(response.txt);
                  sessionStorage.clear();
                  this.router.navigateByUrl(`/login`);
                } else {
                  this.swalService.error(response.txt);
                }
              },
              (err) => {
                this.swalService.error("Server Timeout");
              }
            );
          } else {
            sessionStorage.setItem("product_id", productId);
            window.location.href = `${window.location.origin}${this.service.customerDiaryPath}`;
          }
        }
      }
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.hideShowNav();
    if (window.innerWidth < 1000) {
      this.openMenu = false;
    }
  }

  hideShowNav() {
    if (window.innerWidth > 1000) {
      this.hidenav = false;
    } else {
      this.hidenav = true;
    }
  }

  async onLobClick(data) {
    this.clear();
    // this.selectedStageVal= null;

    this.submit = false;
    this.selectLobLoader = true;
    this.cardlist = []; //change dynamic list
    this.finalcardstatusmsg = undefined; //change dynamic list msg
    // this.modelpop = {}; ////change dynamic popup
    this.fieldshow = {}; // popup fields dynamic
    // this.modelpop = {};
    this.modelpop.quotestatus = [];
    this.modelpop.gcchannel = [];
    this.modelpop.gclocation = [];
    this.model.searchby = "";
    this.model.max = "";
    this.model.min = "";
    this.lob_value = data;
    this.selectedLobValue = data;
    this.displaydropdown = false;
    this.selectedLob = "";
    this.disDownload = true;
    this.isHealth = data === "health" ? true : false;
    this.isMotor = data === "motor" ? true : false;
    this.isTravel = data === "travel" ? true : false;
    this.lta = data === "lta" ? true : false;
    this.personalaccident = data === "personalaccident" ? true : false;
    this.subTransactionTypes = [];

    this.service.lob_Group(data).subscribe((response) => {
      if (response.status == 0) {
        if (response.data.length != 0) {
          // in case we have multiple servers
          this.multipleServerUrl = [
            ...new Set(response.data.map((item) => item.value)),
          ];

          this.urlValue = response.data[0].value;
          this.pro_ID = response.data.map((obj) => obj.name).join();
          this.selectedLob = response.data[0].group_name_2;

          // dynamic fields show in popup
          let description = response.data[0].description;
          let desval = description.split(",");
          for (let i in desval) {
            this.fieldshow[desval[i]] = true;
          }

          // dynamic fields show in popup
          this.clickbutton = true;
          this.advancepopup_dropdown(data);
          this.selectLobLoader = false;
        } else {
          this.clickbutton = false;
          this.selectLobLoader = false;
          Swal.fire(
            this.translate.instant("staticTs.oops"),
            "Product Not Mapped",
            this.translate.instant("staticTs.error")
          );
        }
      } else {
        this.clickbutton = false;
        this.selectLobLoader = false;
        this.swalService.error(response.txt);
      }
    });
    /*added ven 8-12-2020 */
    this.service.lob_group_map_dropdown(data).subscribe((resdrop) => {
      if (resdrop.status == 0 && resdrop.data.length > 0) {
        this.selectLobLoader = true;
        // Remove below hard code values before deployment
        // Hard Code Certificate option

        let hardcodeCertificate = {
          id: "certificate",
          value: "Certificate",
        };

        let hardcodeCertificateNumber = {
          id: "certificateNo",
          value: "Certificate No",
        };
        ////////////////////////////////////
        let descriptiondrop = resdrop.data[0].description;
        let des: any = descriptiondrop.split(";");
        this.selectedStageList = JSON.parse(des[0]);
        this.selectedValList = JSON.parse(des[1]);
        // Remove below hard code values before deployment
        // Hard Code Certificate option

        /////////////////////////////////////////
        ///Hardcode
        if (this.isTravel == true || this.personalaccident == true) {
          this.selectedStageList.push(hardcodeCertificate);
          this.selectedValList.push(hardcodeCertificateNumber);
        }
        let hardcodeByCartId = {
          id: "cart_id",
          value: "By Cart ID",
        };
        if (
          (this.isHealth || this.isMotor) &&
          this.route.snapshot?.queryParams?.type == "crossSell"
        ) {
          this.selectedValList.push(hardcodeByCartId);
        }
        // if(this.personalaccident == true){
        // 	this.selectedValList.push(hardcodeCertificateNumber);
        // }
        // Hardcode
        // this.displaydropdown = true;
        this.selectLobLoader = false;
        // if(data === 'financialline'){
        // this.removeDraft();
        // }
      }
    });
    /*added ven 8-12-2020*/
    if (
      this.searchparavalue == null ||
      this.searchparavalue == undefined ||
      this.searchparavalue == ""
    ) {
      await this.changeparam(this.lob_value);
    }
  }

  getBrokerList() {
    if (this.cardlist.length > 0 && this.producerCodeList.length == 0) {
      let arr = [];
      this.cardlist.forEach((element) => {
        if (
          !["", null, undefined, "-"].includes(element?.broker_code) &&
          !arr.includes(element?.broker_code)
        ) {
          arr.push(element?.broker_code);
        }
      });
      this.producerCodeList = arr;
    }
  }

  removeDraft() {
    // if(data === lob){
    let index = this.selectedStageList.findIndex(
      (obj) => obj?.value?.toLowerCase() === "draft"
    );
    if (index > -1) {
      this.selectedStageList.splice(index, 1);
      //   }
    }
  }

  /*added ven 10-02-2021 used to advancepopup dynamic*/
  advancepopup_dropdown(data) {
    this.service
      .lob_group_map_advancepopup_dropdown(data)
      .subscribe((resdrop) => {
        if (resdrop.status == 0 && resdrop.data.length > 0) {
          let reslist: any = resdrop.data;
          this.tenurelist = [];
          this.varientlist = [];
          this.plantypelist = [];
          this.businesstypelist = [];
          this.dropdownList = [];
          this.bundletypelist = [];
          this.memberisemployeelist = [];
          this.vehiclestatuslist = [];
          this.customertypelist = [];
          for (let i in reslist) {
            if (reslist[i].name == "tenurelist") {
              this.tenurelist = JSON.parse(reslist[i].description);
            }
            if (reslist[i].name == "varientlist") {
              this.varientlist = JSON.parse(reslist[i].description);
            }
            if (reslist[i].name == "plantypelist") {
              this.plantypelist = JSON.parse(reslist[i].description);
            }
            if (reslist[i].name == "businesstypelist") {
              this.businesstypelist = JSON.parse(reslist[i].description);
            }
            if (reslist[i].name == "quotestatuslist") {
              this.dropdownList = JSON.parse(reslist[i].description);
            }
            if (reslist[i].name == "bundletypelist") {
              this.bundletypelist = JSON.parse(reslist[i].description);
            }
            if (reslist[i].name == "memberisemployeelist") {
              this.memberisemployeelist = JSON.parse(reslist[i].description);
            }
            if (reslist[i].name == "vehiclestatuslist") {
              this.vehiclestatuslist = JSON.parse(reslist[i].description);
            }
            if (reslist[i].name == "customertypelist") {
              this.customertypelist = JSON.parse(reslist[i].description);
            }
          }
        }
      });
  }
  /*added ven 10-02-2021*/
  getAllLobs() {
    this.service.getLobs().subscribe((response) => {
      this.loading = true;
      this.spinner.show();
      if (response.status === 0) {
        this.allLobData = response.data;
      } else {
        this.loading = false;
        this.spinner.hide();
      }
    });
    this.getUserLobData();
  }
  selectedVal(data) {
    //cmd ven 8-12-2020 this.selectedType = data.text;
    this.selectedType = data;
    if (
      this.selectedType == "By Quote Number" ||
      this.selectedType == "By Quote Id" ||
      this.selectedType == "By Proposal Number" ||
      this.selectedType == "By Proposal Id" ||
      this.selectedType == "By Policy Number" ||
      this.selectedType == "Certificate No" ||
      this.selectedType == "By Renewal Master Policy Number" ||
      this.selectedType == "By Non Rator Quote Id" ||
      this.selectedType == "By Renewal Number" ||
      this.selectedType == "By Renewal Id"
    ) {
      this.model.ad_startDate = undefined;
      this.model.ad_endDate = undefined;
      this.conditionlRequired = false;
      this.premiumdisplay = false;
      this.model.searchby = "";
      this.model.max = "";
      this.model.min = "";
    } else {
      this.conditionlRequired = true;
      this.model.ad_startDate = this.reassingStartDate;
      this.model.ad_endDate = this.reassingEndDate;
      if (this.selectedType == "By Premium Amount") {
        this.premiumdisplay = true;
        this.model.max = "";
        this.model.min = "";
        this.model.searchby = "";
      } else {
        this.premiumdisplay = false;
        this.model.searchby = "";
        this.model.max = "";
        this.model.min = "";
      }
    }
    this.tolowercase = this.selectedStageVal.toLowerCase();
  }
  async getUserLobData() {
    this.profileData = await new Promise((resolve) => {
      this.profileApi(resolve);
    });
    let email = this.profileData.email;
    this.isUW = this.profileData.is_underwriter == "1" ? true : false;
    setTimeout(() => {
      this.service.getallLobs(email).subscribe(
        async (response) => {
          this.loading = true;
          this.spinner.show();
          if (response.status === 0) {
            this.particularLobData = response.data[0];
            let nameArr = this.particularLobData.lob.split(",");
            this.allLobData.filter((f) =>
              nameArr.some((item) => {
                if (item == f.lob_value) {
                  this.lobData.push(f);
                }
              })
            );
            /**** Sort LOBs in order keeping Health, Motor, Travel in start ****/
            if (this.lobData && this.lobData.length) {
              this.lobData = this.lobData.sort((a, b) =>
                a.lob_value < b.lob_value ? -1 : 1
              );
              let j = 0;
              this.lobData.find((obj) => {
                if (obj.lob == "Health") {
                  this.lobDataCopy[j++] = obj;
                }
              });
              this.lobData.find((obj) => {
                if (obj.lob == "Motor") {
                  this.lobDataCopy[j++] = obj;
                }
              });
              this.lobData.find((obj) => {
                if (obj.lob == "Travel") {
                  this.lobDataCopy[j++] = obj;
                }
              });

              for (let i = 0; i < this.lobData.length; i++) {
                if (
                  this.lobData[i].lob != "Health" &&
                  this.lobData[i].lob != "Motor" &&
                  this.lobData[i].lob != "Travel"
                ) {
                  this.lobDataCopy[j++] = this.lobData[i];
                }
              }
              this.lobData = this.lobDataCopy;
            }
            /**** Sort LOBs in order keeping Health, Motor, Travel in End ****/

            await this.allproduct();
            this.singleLobDataLoad();
          } else if (response.status === -114) {
            this.spinner.hide();
            this.loading = false;
            this.swalService.error(response.txt);
            sessionStorage.clear();
            this.router.navigateByUrl(`/login`);
          } else {
            this.loading = false;
            this.spinner.hide();
            this.swalService.error(response.txt);
          }
        },
        (err) => {
          this.loading = false;
          this.spinner.hide();
          this.swalService.error("Server Timeout");
        }
      );
    }, 1000);
  }
  policyStatus() {}
  //all product from profile api
  allproduct() {
    return new Promise((resolve, reject) => {
      var headers_data = {
        "in-auth-token": sessionStorage.getItem("in-auth-token"),
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      };
      this.service.profile_ipds(headers_data).subscribe(
        (response) => {
          if (response.status === 0) {
            //getRolename
            this.role_id = response.data.roles[0];

            var newArr = response.data.products.map((v) => ({
              ...v,
              url: environment.url_one,
            }));

            this.products = newArr;

            this.service.profile_ipds_two(headers_data).subscribe(
              (response_two) => {
                this.loading = true;
                this.spinner.show();
                if (response_two.status === 0) {
                  newArr = response_two.data.products.map((v) => ({
                    ...v,
                    url: environment.url_two,
                  }));

                  this.products = this.products.concat(newArr);

                  this.service.profile_ipds_three(headers_data).subscribe(
                    (response_three) => {
                      if (response_three.status === 0) {
                        setTimeout(async () => {
                          this.callsearch_filter();
                        }, 0);
                        resolve(true);
                        newArr = response_three.data.products.map((v) => ({
                          ...v,
                          url: environment.url_three,
                        }));

                        this.products = this.products.concat(newArr);
                        // this.isSearchSession ? this.onLobClick(this.slctdLob) : this.searchparavalue?.length > 0 ? this.onLobClick(this.searchparavalue) : '';
                        // if(this.isSearchSession || this.searchparavalue?.length > 0){
                        // 	this.onLobClick(this.slctdLob);
                        //  }

                        //  this.callsearch_filter();
                      } else {
                        setTimeout(async () => {
                          this.callsearch_filter();
                        }, 0);
                        resolve(true);
                      }
                      this.loading = false;
                      this.spinner.hide();
                    },
                    (err) => {
                      setTimeout(async () => {
                        this.callsearch_filter();
                      }, 0);
                      resolve(true);
                      this.loading = false;
                      this.spinner.hide();
                      this.swalService.error("Server Timeout");
                      // this.isSearchSession ? this.onLobClick(this.slctdLob) : this.searchparavalue?.length > 0 ? this.onLobClick(this.searchparavalue) : '';
                      // if(this.isSearchSession || this.searchparavalue?.length > 0){
                      // 	this.onLobClick(this.slctdLob);
                      //  }
                    }
                  );
                } else {
                  setTimeout(async () => {
                    this.callsearch_filter();
                  }, 0);
                  resolve(true);
                  this.loading = false;
                  this.spinner.hide();
                  // this.isSearchSession ? this.onLobClick(this.slctdLob) : this.searchparavalue?.length > 0 ? this.onLobClick(this.searchparavalue) : '';
                  // if(this.isSearchSession || this.searchparavalue?.length > 0){
                  // 	this.onLobClick(this.slctdLob);
                  //  }
                }
              },
              (err) => {
                setTimeout(async () => {
                  this.callsearch_filter();
                }, 0);
                resolve(true);
                this.loading = false;
                this.spinner.hide();
                this.swalService.error("Server Timeout");
                // this.isSearchSession ? this.onLobClick(this.slctdLob) : this.searchparavalue?.length > 0 ? this.onLobClick(this.searchparavalue) : '';
                // if(this.isSearchSession || this.searchparavalue?.length > 0){
                // 	this.onLobClick(this.slctdLob);
                //  }
              }
            );
          } else if (response.status === -114) {
            setTimeout(async () => {
              this.callsearch_filter();
            }, 0);
            resolve(true);
            this.swalService.error(response.txt);
            sessionStorage.clear();
            this.router.navigateByUrl(`/login`);
          } else {
            setTimeout(async () => {
              this.callsearch_filter();
            }, 0);
            resolve(true);
            this.loading = false;
            this.spinner.hide();
            this.swalService.error(response.txt);
            // this.isSearchSession ? this.onLobClick(this.slctdLob) : this.searchparavalue?.length > 0 ? this.onLobClick(this.searchparavalue) : '';
            // if(this.isSearchSession || this.searchparavalue?.length > 0){
            // 	this.onLobClick(this.slctdLob);
            //  }
          }
        },
        (err) => {
          setTimeout(async () => {
            this.callsearch_filter();
          }, 0);
          resolve(true);
          this.loading = false;
          this.spinner.hide();
          this.swalService.error("Server Timeout");
        }
      );
    });
  }

  singleLobDataLoad() {
    if (this.lobData?.length == 1) {
      this.slctdLob = this.lobData[0]?.lob_value;
      console.log("sel", this.slctdLob);
      this.onLobClick(this.slctdLob);
    }
  }

  async callsearch_filter() {
    if (this.profileres.is_broker == 1) {
      this.searchresplist = ["assigned_to_team", "created_by_me"];
      if (this.searchparavalue != "" && this.searchparavalue != undefined) {
        this.onLobClick(this.searchparavalue);
        await this.changeparam(this.searchparavalue);
        this.searchparavalue = "";
        // this.search();
      } else {
        this.loading = false;
        // this.spinner.hide(); // Commenting to avoid multiple spinner issue
      }
    } else {
      await this.getRoleName();
      let req: any = { role_value: this.currentrole };
      this.service.role_search_criteria(req).subscribe((searchresp) => {
        if (searchresp.status == 0 && searchresp.data.length > 0) {
          let searchlist = searchresp.data[0].search_filter;

          if (searchlist !== null && searchlist !== undefined) {
            searchlist = searchlist.trim();
          }

          if (searchlist.length > 1) {
            searchlist = searchlist.replace(/,\s*$/, ""); //removing comma at the end of the string
          }
          this.searchresplist = searchlist.split(",");
          if (this.searchparavalue != "" && this.searchparavalue != undefined) {
            setTimeout(async () => {
              this.onLobClick(this.searchparavalue);
              await this.changeparam(this.searchparavalue);
              this.searchparavalue = "";
              // this.search();
            }, 1000);
          }
        }
      });
    }
  }
  //all product from profile api
  selectedStage(stage) {
    //cmd 8-12-2020  this.selectedStageVal = stage.text;
    this.selectedStageVal = stage; // added 8-12-2020
    this.tolowercase = this.selectedStageVal.toLowerCase();
    if (this.selectedStageVal == "Certificate") {
      this.onChangeCertificatePolicy();
      this.conditionlRequired = false;
      let producer_code = "";
      // Call GOTG Master Policy List API
      if (sessionStorage?.getItem("producer_code")?.length > 0) {
        producer_code = sessionStorage.getItem("producer_code");
      }
      let params = {
        producer_code: producer_code,
      };
      if (sessionStorage?.getItem("usertype") == "others") {
        this.service.profile().subscribe((responseprofile) => {
          let data = responseprofile.data.data.master_policy;
          let des: any = data.split(";");
          let parsedPolicyList = JSON.parse(des[0]);
          for (let i = 0; i < parsedPolicyList.length; i++) {
            this.certificatePolicyList.push(parsedPolicyList[i].policy_no);
          }
        });
      } else {
        if (this.isTravel === true) {
          this.service.gotgMasterPolicyList(params).subscribe((response) => {
            if (response.status == 0 && response.data.length > 0) {
              this.certificatePolicyList = response.data;
            }
          });
        } else if (this.personalaccident === true) {
          this.service
            .personalAccidentPolicyList(params)
            .subscribe((response) => {
              if (response.status == 0 && response.data.length > 0) {
                this.certificatePolicyList = response.data.map((item) => {
                  return item["TXT_MASTER_POL_NUMBER"];
                });
                // this.certificatePolicyList = response.data;
              }
            });
        }
      }
    } else if (
      this.selectedType == "By Quote Number" ||
      this.selectedType == "By Quote Id" ||
      this.selectedType == "By Proposal Number" ||
      this.selectedType == "By Proposal Id" ||
      this.selectedType == "By Policy Number" ||
      this.selectedType == "By Renewal Master Policy Number" ||
      this.selectedType == "By Non Rator Quote Id" ||
      this.selectedType == "By Renewal Number" ||
      this.selectedType == "By Renewal Id"
    ) {
      this.conditionlRequired = false;
    } else {
      this.conditionlRequired = true;
    }
  }

  closeFilter() {
    let closeFilter = document.getElementById("closeFilter");
    closeFilter.click();
  }

  filter() {
    this.submitfilter = true;
    if (this.userData.valid) {
      this.page = 1;
      if (
        (this.model.searchby != "" && this.model.searchby != undefined) ||
        (this.model.max != "" &&
          this.model.max != undefined &&
          this.model.min != "" &&
          this.model.min != undefined)
      ) {
        this.cardlisttemp = this.cardlist;
      } // this is filter by and sort list
      //quote wise
      if (
        (this.finalcardstatus == "quote" || this.finalcardstatus == "draft") &&
        this.modelpop.sort == "ascending"
      ) {
        this.cardlist = this.cardlisttemp.sort((a, b) => {
          return Number(moment(a.u_ts)) - Number(moment(b.u_ts));
        });
      } else if (
        (this.finalcardstatus == "quote" || this.finalcardstatus == "draft") &&
        this.modelpop.sort == "descending"
      ) {
        this.cardlist = this.cardlisttemp.sort((a, b) => {
          return Number(moment(b.u_ts)) - Number(moment(a.u_ts));
        });
      } else if (
        (this.finalcardstatus == "quote" ||
          this.finalcardstatus == "proposal" ||
          this.finalcardstatus == "policy" ||
          this.finalcardstatus == "draft") &&
        this.modelpop.sort == "min"
      ) {
        this.cardlist = this.cardlisttemp.sort((a1, b1) => {
          if (a1.data.search == undefined || a1.data.search == "") {
            a1.data.search = {};
            a1.data.search.premium = "0";
          }
          if (b1.data.search == undefined || b1.data.search == "") {
            b1.data.search = {};
            b1.data.search.premium = "0";
          }
          if (
            b1.data.search.premium == undefined ||
            b1.data.search.premium == ""
          ) {
            b1.data.search.premium = "0";
          }
          if (
            a1.data.search.premium == undefined ||
            a1.data.search.premium == ""
          ) {
            a1.data.search.premium = "0";
          }
          if (a1.data.search.premium != undefined) {
            a1.data.search.premium_valuewithoutcomma =
              typeof a1.data.search.premium == "number"
                ? a1.data.search.premium.toString().replace(/,/g, "")
                : a1.data.search.premium.replace(/,/g, "");
          }
          if (b1.data.search.premium != undefined) {
            b1.data.search.premium_valuewithoutcomma =
              typeof b1.data.search.premium == "number"
                ? b1.data.search.premium.toString().replace(/,/g, "")
                : b1.data.search.premium.replace(/,/g, "");
          }
          return (
            Number(a1.data.search.premium_valuewithoutcomma) -
            Number(b1.data.search.premium_valuewithoutcomma)
          );
        });
      } else if (
        (this.finalcardstatus == "quote" ||
          this.finalcardstatus == "proposal" ||
          this.finalcardstatus == "policy" ||
          this.finalcardstatus == "draft") &&
        this.modelpop.sort == "max"
      ) {
        this.cardlist = this.cardlisttemp.sort((a, b) => {
          if (a.data.search == undefined || a.data.search == "") {
            a.data.search = {};
            a.data.search.premium = "0";
          }
          if (b.data.search == undefined || b.data.search == "") {
            b.data.search = {};
            b.data.search.premium = "0";
          }
          if (
            b.data.search.premium == undefined ||
            b.data.search.premium == ""
          ) {
            b.data.search.premium = "0";
          }
          if (
            a.data.search.premium == undefined ||
            a.data.search.premium == ""
          ) {
            a.data.search.premium = "0";
          }
          if (a.data.search.premium != undefined) {
            a.data.search.premium_valuewithoutcomma =
              a.data.search.premium_valuewithoutcomma =
                typeof a.data.search.premium == "number"
                  ? a.data.search.premium.toString().replace(/,/g, "")
                  : a.data.search.premium.replace(/,/g, "");
          }
          if (b.data.search.premium != undefined) {
            b.data.search.premium_valuewithoutcomma =
              typeof b.data.search.premium == "number"
                ? b.data.search.premium.toString().replace(/,/g, "")
                : b.data.search.premium.replace(/,/g, "");
          }
          return (
            Number(b.data.search.premium_valuewithoutcomma) -
            Number(a.data.search.premium_valuewithoutcomma)
          );
        });
      }
      //quote wise
      //proposal wise
      else if (
        (this.finalcardstatus == "proposal" ||
          this.finalcardstatus == "policy") &&
        this.modelpop.sort == "ascending"
      ) {
        this.cardlist = this.cardlisttemp.sort((a, b) => {
          let aa = a.policy_start_date
            ? a.policy_start_date
            : a.data.search.policy_start_date;
          let bb = b.policy_start_date
            ? b.policy_start_date
            : b.data.search.policy_start_date;
          return Number(moment(aa)) - Number(moment(bb));
        });
      } else if (
        (this.finalcardstatus == "proposal" ||
          this.finalcardstatus == "policy") &&
        this.modelpop.sort == "descending"
      ) {
        this.cardlist = this.cardlisttemp.sort((a, b) => {
          let aa = a.policy_start_date
            ? a.policy_start_date
            : a.data.search.policy_start_date;
          let bb = b.policy_start_date
            ? b.policy_start_date
            : b.data.search.policy_start_date;
          return Number(moment(bb)) - Number(moment(aa));
        });
      }
      if (this.modelpop.sort != undefined && this.modelpop.sort != "") {
        this.temp = this.cardlist;
      } else {
        this.temp = this.cardlisttemp;
      }

      if (
        this.modelpop.product_id != undefined &&
        this.modelpop.product_id?.length !== 0
      ) {
        this.temp = this.temp.filter((x) => {
          for (let i = 0; i < this.modelpop.product_id.length; i++) {
            if (x.product_id == this.modelpop.product_id[i].product_id) {
              return x;
            }
          }
        });
        console.log(this.temp);
      }
      if (this.modelpop.location != undefined && this.modelpop.location != "") {
        this.temp = this.temp.filter((x) => {
          if (x.data.search != undefined && x.data.search != "") {
            if (x.data.search.location != undefined) {
              if (
                x.data.search.location.toLowerCase() ==
                this.modelpop.location.toLowerCase()
              ) {
                return x;
              }
            }
          }
        });
      }
      // SubTransaction Type wise filter
      if (
        this.modelpop.subTransactionType != undefined &&
        this.modelpop.subTransactionType != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x.quote.data.sub_transaction_type ==
            this.modelpop.subTransactionType
          ) {
            return x;
          }
        });
      }
      // SubTransaction Type wise filter Ends

      if (
        this.modelpop.netpremium != undefined &&
        this.modelpop.netpremium != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (x.data.search != undefined && x.data.search != "") {
            if (x.data.search.premium != undefined) {
              if (
                Number(x.data.search.premium) ==
                Number(this.modelpop.netpremium)
              ) {
                return x;
              }
            }
          }
        });
      }
      if (
        this.modelpop.sum_insured_from != undefined &&
        this.modelpop.sum_insured_from != "" &&
        this.modelpop.sum_insured_to != undefined &&
        this.modelpop.sum_insured_to != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (x.data.search != undefined && x.data.search != "") {
            if (
              x.data.search.sum_insured == undefined ||
              x.data.search.sum_insured == ""
            ) {
              x.data.search.sum_insured = "0";
            }
            if (x.data.search.sum_insured != undefined) {
              let findcomma = isNaN(x.data.search.sum_insured);
              if (findcomma) {
                x.data.search.sum_insured_valuewithoutcomma =
                  x.data.search.sum_insured.replace(/,/g, "");
              } else {
                x.data.search.sum_insured_valuewithoutcomma =
                  x.data.search.sum_insured;
              }
            }

            if (
              Number(x.data.search.sum_insured_valuewithoutcomma) >=
                Number(this.modelpop.sum_insured_from) &&
              Number(x.data.search.sum_insured_valuewithoutcomma) <=
                Number(this.modelpop.sum_insured_to)
            ) {
              return x;
            }
          }
        });
      }
      let temp_quotestatus = [];
      if (
        this.modelpop.quotestatus != undefined &&
        this.modelpop.quotestatus.length != 0
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x.data.search != undefined &&
            x.data.search != "" &&
            x.data.search.nstp_status != undefined &&
            x.data.search.nstp_status != ""
          ) {
            this.modelpop.quotestatus.some((item) => {
              if (
                item.itemName.toLowerCase() ===
                x.data.search.nstp_status.toLowerCase()
              ) {
                temp_quotestatus.push(x);
                return x;
              }
            });
          }
        });
        this.temp = temp_quotestatus;
      }

      //channel and location

      let gcChannelfilter = [];
      if (
        this.modelpop.gcchannel != undefined &&
        this.modelpop.gcchannel.length != 0
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x.data?.search != undefined &&
            x.data?.search != "" &&
            x.data?.search?.channel != undefined &&
            x.data?.search?.channel != ""
          ) {
            this.modelpop.gcchannel.some((item) => {
              if (
                item.channel_type.toLowerCase() ===
                x.data?.search?.channel.toLowerCase()
              ) {
                gcChannelfilter.push(x);
                return x;
              }
            });
          }
        });
        this.temp = gcChannelfilter;
      }
      let gcLocationfilter = [];
      if (
        this.modelpop.gclocation != undefined &&
        this.modelpop.gclocation.length != 0
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x?.data?.search?.location !== undefined &&
            x?.data?.search?.location !== ""
          ) {
            this.modelpop.gclocation.some((item) => {
              if (
                item.name.toLowerCase() ===
                x.data?.search?.location.toLowerCase()
              ) {
                gcLocationfilter.push(x);
                return x;
              }
            });
          }
        });
        this.temp = gcLocationfilter;
      }
      //lta BUNDLE TYPE
      if (
        this.modelpop.lta_bundle_type != undefined &&
        this.modelpop.lta_bundle_type != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x.data.search != undefined &&
            x.data.search != "" &&
            x.data.search.triptype != undefined &&
            x.data.search.triptype != ""
          ) {
            if (
              x.data.search.triptype.toLowerCase() ==
              this.modelpop.lta_bundle_type.toLowerCase()
            ) {
              return x;
            }
          }
        });
      }
      //lta BUNDLE TYPE

      // DOE Filter starts
      if (
        this.modelpop.doeFilter != undefined &&
        this.modelpop.doeFilter != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x.quote.data != undefined &&
            x.quote.data != "" &&
            x.quote.data.agent_first_name != undefined &&
            x.quote.data.agent_first_name != ""
          ) {
            if (
              x.quote.data.agent_first_name.toLowerCase() ==
              this.modelpop.doeFilter.toLowerCase()
            ) {
              return x;
            }
          }
        });
      }
      // DOE Filter ends

      //lta plane type
      if (
        this.modelpop.lta_plan_type != undefined &&
        this.modelpop.lta_plan_type != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x.data.search != undefined &&
            x.data.search != "" &&
            x.data.search.selected_plan != undefined &&
            x.data.search.selected_plan != ""
          ) {
            if (
              x.data.search.selected_plan.toLowerCase() ==
              this.modelpop.lta_plan_type.toLowerCase()
            ) {
              return x;
            }
          }
        });
      }
      //lta plane type
      /*****************BTA anavarathan 20/11/2020************ */

      if (this.lob_value == "travel") {
        this.temp = this.cradlisttempLTA;

        let short = this.modelpop.sort;
        function GetSortOrder(prop) {
          if (short == "ascending") {

            return function (a, b) {
              return (
                new Date(a["c_ts"]).getTime() - new Date(b["c_ts"]).getTime()
              );
            };
          } else if (short == "descending") {
            return function (a, b) {
              return (
                new Date(b["c_ts"]).getTime() - new Date(a["c_ts"]).getTime()
              );
            };
          }
        }

        this.temp = this.temp.sort(GetSortOrder("premium")); //Pass the attribute to be sorted on
      }
      if (
        this.lob_value == "travel" &&
        this.modelpop.bta_plan_type != undefined
      ) {
        var filter = {
          selected_product: "Group Business Travel Accident",
          selected_plan: this.modelpop.bta_plan_type,
        };
        this.temp = this.temp.filter(function (item) {
          for (var key in filter) {
            if (item.hasOwnProperty("data")) {
              if (item.data.hasOwnProperty("search")) {
                if (filter[key] != "" && filter[key] != undefined) {
                  if (
                    item.data.search[key] === undefined ||
                    item.data.search[key] != filter[key]
                  ) {
                    return false;
                  }
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          }
          return true;
        });
      }

      if (
        this.modelpop.business_type != undefined &&
        this.modelpop.business_type != ""
      ) {
        this.temp = this.temp.filter((x) => {
          console.log(this.modelpop.business_type);

          if (
            x.data.search != undefined &&
            x.data.search != "" &&
            x.data.search.business_type != undefined &&
            x.data.search.business_type != ""
          ) {
            for (let i = 0; i < this.modelpop.business_type.length; i++) {
              if (
                x.data.search.business_type.toLowerCase() ==
                this.modelpop.business_type[i].value.toLowerCase()
              ) {
                return x;
              }
            }
          }
        });
      }
      if (this.modelpop.varient != undefined && this.modelpop.varient != "") {
        this.temp = this.temp.filter((x) => {
          if (
            x.data.search != undefined &&
            x.data.search != "" &&
            x.data.search.varient != undefined &&
            x.data.search.varient != ""
          ) {
            if (
              x.data.search.varient.toLowerCase() ==
              this.modelpop.varient.toLowerCase()
            ) {
              return x;
            }
          }
        });
      }
      if (this.modelpop.tenure != undefined && this.modelpop.tenure != "") {
        this.temp = this.temp.filter((x) => {
          if (
            x.data.search != undefined &&
            x.data.search != "" &&
            x.data.search.tenure != undefined &&
            x.data.search.tenure != ""
          ) {
            if (
              x.data.search.tenure.toLowerCase() ==
              this.modelpop.tenure.toLowerCase()
            ) {
              return x;
            }
          }
        });
      }
      if (
        this.modelpop.plan_type != undefined &&
        this.modelpop.plan_type != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x.data.search != undefined &&
            x.data.search != "" &&
            x.data.search.plan_type != undefined &&
            x.data.search.plan_type != ""
          ) {
            if (
              x.data.search.plan_type.toLowerCase() ==
              this.modelpop.plan_type.toLowerCase()
            ) {
              return x;
            }
          }
        });
      }
      if (
        this.modelpop.member_is_employee != undefined &&
        this.modelpop.member_is_employee != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x.data.search != undefined &&
            x.data.search != "" &&
            x.data.search.member_is_employee != undefined &&
            x.data.search.member_is_employee != ""
          ) {
            if (
              x.data.search.member_is_employee.toLowerCase() ==
              this.modelpop.member_is_employee.toLowerCase()
            ) {
              return x;
            }
          }
        });
      }
      //motor
      if (
        this.modelpop.motor_vehicle_status != undefined &&
        this.modelpop.motor_vehicle_status != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x.data.search != undefined &&
            x.data.search != "" &&
            x.data.search.policy_plan_name != undefined &&
            x.data.search.policy_plan_name != ""
          ) {
            if (
              x.data.search.policy_plan_name.toLowerCase() ==
              this.modelpop.motor_vehicle_status.toLowerCase()
            ) {
              return x;
            }
          }
        });
      }
      if (
        this.modelpop.motor_bundle_type != undefined &&
        this.modelpop.motor_bundle_type != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x.data.search != undefined &&
            x.data.search != "" &&
            x.data.search.bundle_type != undefined &&
            x.data.search.bundle_type != ""
          ) {
            if (
              x.data.search.bundle_type.toLowerCase() ==
              this.modelpop.motor_bundle_type.toLowerCase()
            ) {
              return x;
            }
          }
        });
      }
      if (
        this.modelpop.customer_type != undefined &&
        this.modelpop.customer_type != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (
            x.data.search != undefined &&
            x.data.search != "" &&
            x.data.search.customer_type != undefined &&
            x.data.search.customer_type != ""
          ) {
            if (
              x.data.search.customer_type.toLowerCase() ==
              this.modelpop.customer_type.toLowerCase()
            ) {
              return x;
            }
          }
        });
      }
      //Producer Code filter
      if (
        this.modelpop.producercode != undefined &&
        this.modelpop.producercode != "" &&
        this.modelpop.producercode != null
      ) {
        let data = [];
        this.modelpop.producercode.forEach((element) => {
          let data1 = this.temp.filter((x) => {
            if (x?.broker_code == element) {
              return x;
            }
          });
          data1.forEach((elements) => {
            data.push(elements);
          });
        });
        this.temp = data;
      }
      // SME Status Filter
      if (
        this.modelpop.smestatus != undefined &&
        this.modelpop.smestatus != "" &&
        this.modelpop.smestatus != null
      ) {
        switch (this.modelpop.smestatus.toLowerCase()) {
          case "non-sme":
            this.temp = this.temp.filter((x) => {
              if (
                x.data.search?.sme_flag != undefined &&
                x.data.search?.sme_flag != "" &&
                x.data.search?.sme_flag != null
              ) {
                if (x.data.search?.sme_flag.toLowerCase() == "non-sme") {
                  return x;
                }
              }
            });

          case "sme":
            this.temp = this.temp.filter((x) => {
              if (
                x.data.search?.sme_flag == undefined ||
                x.data.search?.sme_flag == "" ||
                x.data.search?.sme_flag == null ||
                x.data.search?.sme_flag.toLowerCase() == "sme"
              ) {
                return x;
              }
            });
        }
      }

      if (
        this.modelpop.ins_dec_from != undefined &&
        this.modelpop.ins_dec_from != "" &&
        this.modelpop.ins_dec_to != undefined &&
        this.modelpop.ins_dec_to != ""
      ) {
        this.temp = this.temp.filter((x) => {
          if (x.data.search != undefined && x.data.search != "") {
            if (
              x.data.search.insured_declared_value == undefined ||
              x.data.search.insured_declared_value == ""
            ) {
              x.data.search.insured_declared_value = "0";
            }
            if (x.data.search.insured_declared_value != undefined) {
              let findcomma = isNaN(x.data.search.insured_declared_value);
              if (findcomma) {
                x.data.search.insured_declared_value_valuewithoutcomma =
                  x.data.search.insured_declared_value.replace(/,/g, "");
              } else {
                x.data.search.insured_declared_value_valuewithoutcomma =
                  x.data.search.insured_declared_value;
              }
            }
            if (
              Number(x.data.search.insured_declared_value_valuewithoutcomma) >=
                Number(this.modelpop.ins_dec_from) &&
              Number(x.data.search.insured_declared_value_valuewithoutcomma) <=
                Number(this.modelpop.ins_dec_to)
            ) {
              return x;
            }
          }
        });
      }
      if (this.cardlist.length) {
        this.disDownload = false;
      } else {
        this.disDownload = true;
      }
      //motor
      this.cardlist = this.temp;

      // =======================non rater separation ================================>
      this.nonRatorList = [];
      this.ratorList = [];
      this.nonRatorList = this.cardlist.filter(
        (cards) => cards.product_id == this.service.nonRaterProductId
      ); //filter quotes with non-rater product id
      this.ratorList = this.cardlist.filter(
        (cards) => cards.product_id != this.service.nonRaterProductId
      );
      var selctedlobnrproductlist = this.nonRatorList.filter((nr1) =>
        nr1.data && nr1.data.search
          ? nr1.data.search.product_name?.replace(/\s/g, "").toLowerCase() ==
            this.selectedLob
          : false
      );
      this.cardlist = [...this.ratorList, ...selctedlobnrproductlist];
      console.log(this.finalcardstatusmsg);
      console.log(this.cardlist);
      this.finalcardstatusmsg =
        this.cardlist.length +
        this.translate.instant("staticTs.resultFound") +
        this.selectedStageVal +
        this.translate.instant("staticTs.list");
      if (this.cardlist.length == 0) {
        this.showFilterOnly = true;
      } else {
        this.showFilterOnly = false;
      }

      //product category
      this.storeFilterDetails();
      this.closeFilter();
      this.clearFilterProductDependents();
    }
    this.assignFilterValues();
    this.checkFilter();
    console.log("displayproductName", this.displayproductName);
  }

  assignFilterValues() {
    this.sortOrder = this.modelpop.sort;
    this.productName = this.displayproductName;
    this.productCategory = this.modelpop.product_id;
    this.quotes_stat = this.modelpop.quotestatus;
    this.memberisEmployee = this.modelpop.member_is_employee;
    this.planType = this.modelpop.plan_type;
    this.tenure = this.modelpop.tenure;
    this.varient = this.modelpop.varient;
    this.businessType = this.modelpop.business_type;
    this.filterLocation = this.modelpop.location;
    this.subtractionType = this.modelpop.subTransactionType;
    this.netPremium = this.modelpop.netpremium;
    this.sumInsuredFrom = this.modelpop.sum_insured_from;
    this.sumInsuredTo = this.modelpop.sum_insured_to;
    this.gcChannel = this.modelpop.gcchannel;
    this.gcLocation = this.modelpop.gclocation;
    this.ltaBundleType = this.modelpop.lta_bundle_type;
    this.ltaPlanType = this.modelpop.lta_plan_type;
    this.motorBundleType = this.modelpop.motor_bundle_type;
    this.motorvehicleType = this.modelpop.motor_vehicle_status;
    this.customerType = this.modelpop.customer_type;
    this.insDesFrom = this.modelpop.ins_dec_from;
    this.insDecTo = this.modelpop.ins_dec_to;
    this.btaPlanType = this.modelpop.bta_plan_type;
    this.smeStatus = this.modelpop.smestatus;
    this.producerCode = this.modelpop.producercode;
    this.doeFilter = this.modelpop.doeFilter;
  }

  storeFilterDetails = () => {
    sessionStorage.removeItem("search_filter_details");
    let search_filter_details = {
      modelpop: this.modelpop,
      finalcardstatus: this.finalcardstatus,
      selectedType: this.selectedType,
      selectedLob: this.slctdLob,
      startDate: this.model.ad_startDate,
      endDate: this.model.ad_endDate,
      receiptSearchBtn: this.receiptSearchBtn,
      fieldshow: this.fieldshow,
      // cardlist: this.cardlist,
      // finalcardstatusmsg: this.finalcardstatusmsg,
    };
    sessionStorage.setItem(
      "search_filter_details",
      JSON.stringify(search_filter_details)
    );
  };

  number_keyPress(event: any) {
    const pattern = /[0-9\/]/;
    let inputChar = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    // If the key is backspace, tab, left, right or delete
    var keystobepassedout =
      "$Backspace$Delete$Home$Tab$ArrowLeft$ArrowRight$ArrowUp$ArrowDown$End$";
    if (keystobepassedout.indexOf("$" + event.key + "$") != -1) {
      return true;
    }
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
      return false;
    }
  }

  clearFilterProductDependents() {
    this.disableVarient = false;
    this.showSubTransactionType = false;
  }

  storeSearchFormDetailsInSession() {
    if (sessionStorage.getItem("search_filters") != undefined) {
      sessionStorage.setItem(
        "search_form_details",
        JSON.stringify(this.userData.value)
      );
    }
  }

  updateSearchFilterInSession() {
    if (sessionStorage.getItem("search_filters") != undefined) {
      let defaultFilters = Object.assign(
        {},
        JSON.parse(sessionStorage.getItem("search_filters"))
      );
      defaultFilters.stage = this.selectedStageVal;
      sessionStorage.setItem("search_filters", JSON.stringify(defaultFilters));
      this.storeSearchFormDetailsInSession();
      this.BackToSearchWithSlctdFilters();
    }
  }

  ltaRenewalNavigation(item, comp) {
    return new Promise((resolve, reject) => {
      var arr = [],
        url;
      this.service
        .getRenewalNavigation(item?.product_id)
        .subscribe((response) => {
          if (response.status == 0) {
            arr = response?.data;
            url = arr.filter((element) => {
              if (comp == element?.value) {
                return element?.name;
              }
            });
            const { origin } = window.location;
            //console.log(`https://uatipds1.cloware.in/${url[0].name}${item.parent_policy_no}?renewalQuoteID=${item.quote_id}`);
            window.location.href = `${origin}/${url[0]?.name}${item?.parent_policy_no}?renewalQuoteID=${item.quote_id}`;
            resolve(true);
          }
        });
    });
  }
  homeRenewalNavigation(arg, stage) {
    stage =
      stage.toLowerCase() == "draft"
        ? "quote"
        : stage.toLowerCase() == "policy"
        ? "proposal"
        : stage;
    return new Promise((resolve, reject) => {
      let dataUrl;
      this.service
        .getRenewalNavigation(this.service.bgrNeumorphicProductId)
        .subscribe(
          (response) => {
            if (response.status == 0) {
              dataUrl = response?.data.filter((item) => {
                return item?.value === stage.toLowerCase();
              });
              window.location.href = `${origin}/${dataUrl[0]?.name}${arg?.data?.search?.rv_policy_no}?renQuoteID=${arg?.quote_id}`;
              resolve(true);
            } else {
              this.swalService.error(response.txt);
            }
          },
          (err) => {
            this.swalService.error("Server Timeout");
          }
        );
    });
  }

  validateSpecial() {
    if (
      (this.selectedType === "By Quote Number" ||
        this.selectedType == "By Quote Id" ||
        this.selectedType === "By Proposal Number" ||
        this.selectedType == "By Proposal Id" ||
        this.selectedType === "By Policy Number" ||
        this.selectedType == "By Non Rator Quote Id" ||
        this.selectedType == "By Renewal Number" ||
        this.selectedType == "By Renewal Id") &&
      this.model.searchby
    ) {
      this.model.searchby = this.model.searchby.replace(
        REMOVES_LEADING_AND_TRAILING_SPECIAL_CHARACTER_REGEX,
        "$1$2"
      );
    }
  }

  async search(pageIndex: number, isSearchButtonClicked: boolean) {
    if(isSearchButtonClicked) {
      this.pageIndex = 0;
    }
    if (
      (this.selectedType === "By Quote Number" ||
        this.selectedType == "By Quote Id" ||
        this.selectedType === "By Proposal Number" ||
        this.selectedType == "By Proposal Id" ||
        this.selectedType === "By Policy Number" ||
        this.selectedType == "By Non Rator Quote Id" ||
        this.selectedType == "By Renewal Number" ||
        this.selectedType == "By Renewal Id") &&
        this.model.searchby.trim() === ""
          ) {
      this.swalService.error(`${this.selectedType} is empty`);
      return;
    }

    if (
      (this.selectedType === "By Quote Number" ||
        this.selectedType == "By Quote Id" ||
        this.selectedType === "By Proposal Number" ||
        this.selectedType == "By Proposal Id" ||
        this.selectedType === "By Policy Number" ||
        this.selectedType == "By Non Rator Quote Id" ||
        this.selectedType == "By Renewal Number" ||
        this.selectedType == "By Renewal Id") &&
      this.model.searchby
    ) {
      this.model.searchby = this.model.searchby.replace(
        REMOVES_LEADING_AND_TRAILING_SPECIAL_CHARACTER_REGEX,
        "$1$2"
      );
    }
    if (
      this.model.ad_startDate != undefined &&
      this.model.ad_startDate != null &&
      this.model.ad_startDate != "" &&
      this.model.ad_endDate != undefined &&
      this.model.ad_endDate != null &&
      this.model.ad_endDate != ""
    ) {
      var Days = Math.floor(
        (Date.UTC(
          this.model.ad_endDate.year,
          this.model.ad_endDate.month - 1,
          this.model.ad_endDate.day
        ) -
          Date.UTC(
            this.model.ad_startDate.year,
            this.model.ad_startDate.month - 1,
            this.model.ad_startDate.day
          )) /
          (1000 * 60 * 60 * 24)
      );

      if (Days > 35) {
        this.cardlist = [];
        this.submit = false;
        Swal.fire(
          "Please change the date",
          "Start Date and End Date difference should be less than 35 days",
          "info"
        );
        return;
      }
    }
    this.producerCodeList = [];
    this.loading = true;
    this.showFilterOption = true;
    this.isCrossSellFilter = false;
    //await this.spinner.show();
    this.searchSpinner = true;
    if (this.modelpop == undefined || this.modelpop == "") this.clear();
    this.updateSearchFilterInSession();

    this.submit = true;
    this.cardlisttemp = [];
    this.cardlist = [];
    this.responsefromapicart = []; //added 9-12-2020
    this.concatlist = [];

    if (this.receiptSearchBtn) {
      for (let i = 0; i < this.multipleServerUrl.length; i++) {
        start = this.model.ad_startDate
            ? this.model.ad_startDate.year +
              "-" +
              ("0" + this.model.ad_startDate.month).slice(-2) +
              "-" +
              ("0" + this.model.ad_startDate.day).slice(-2)
            : "";
        end = this.model.ad_endDate
          ? this.model.ad_endDate.year +
            "-" +
            ("0" + this.model.ad_endDate.month).slice(-2) +
            "-" +
            ("0" + this.model.ad_endDate.day).slice(-2)
          : "";
        const url = this.multipleServerUrl[i];

        let dataurl =
          location.protocol +
          "//" +
          url +
          "/api/v1/ipds_payment/receipt_search";
        this.service
          .receipt_search(dataurl, { email: sessionStorage.getItem("email"), start_date: start, end_date: end })
          .subscribe((response) => {
            if (response.status == 0) {
              this.finalcardstatus = this.tolowercase;

              if (response["data"].length > 0) {
                let duplicate1 = response.data;
                this.cardlist = [...new Set(duplicate1)];
                ////// Hide Download Policy Button
                this._HideDownloadPolicyIcon();
              }
              this.finalcardstatusmsg =
                this.cardlist.length +
                this.translate.instant("staticTs.resultFoundReceipt");

              this.searchSpinner = false;
              this.submit = true;
              this.loading = false;
            } else {
              this.swalService.error(response.txt);

              this.searchSpinner = false;
              this.submit = true;
              this.loading = false;
            }
          });
      }
    } else {
      if (this.userData.valid) {
        this.finalcardstatusmsg =
          this.cardlist.length +
          this.translate.instant("staticTs.resultFound") +
          this.selectedStageVal +
          this.translate.instant("staticTs.list");
        this.page = 1;
        var start;
        var end;
        if (this.model.ad_startDate && this.model.ad_endDate) {
          start = this.model.ad_startDate
            ? this.model.ad_startDate.year +
              "-" +
              ("0" + this.model.ad_startDate.month).slice(-2) +
              "-" +
              ("0" + this.model.ad_startDate.day).slice(-2)
            : "";
          end = this.model.ad_endDate
            ? this.model.ad_endDate.year +
              "-" +
              ("0" + this.model.ad_endDate.month).slice(-2) +
              "-" +
              ("0" + this.model.ad_endDate.day).slice(-2)
            : "";
        } else {
          start = "";
          end = "";
        }

        if (this.tolowercase == "draft") {
          this.apitolowercase = "quote";
        } else if (
          this.tolowercase == "proposal" ||
          this.tolowercase == "certificate"
        ) {
          this.apitolowercase = "policy"; 
        } else {
          this.apitolowercase = this.tolowercase;
        }

        /******************Muruga****20/11/2020************************** */
        if (this.tolowercase == "waats policy") {
          this.cardlist = [];
          if (this.model.searchby != "" && this.model.searchby != undefined) {
            let waatsurl = "";
            for (let i = 0; i < this.multipleServerUrl.length; i++) {
              const url = this.multipleServerUrl[i];

              if (this.lob_value.toLowerCase() == "lta") {
                waatsurl =
                  location.protocol +
                  "//" +
                  url +
                  "/api/v1/travelpreprocess/policydata?policy=" +
                  this.model.searchby;
              } else if (this.lob_value.toLowerCase() == "travel") {
                waatsurl =
                  location.protocol +
                  "//" +
                  url +
                  "/api/v1/travelpreprocess/policydata?policy=" +
                  this.model.searchby +
                  "&product=BTA";
              }

              await this.service
                .WaatsPolicy(waatsurl)
                .subscribe(async (response) => {
                  this.finalcardstatus = this.tolowercase;
                  if (response.status == 0) {
                    if (this.tolowercase == "waats policy") {
                      this.cardlist.push(response.data);
                    }

                    this.searchSpinner = false;
                    this.submit = true;
                    this.loading = false;
                  }
                });
            }
          }
        } else {
          let count = 0;
          for (let i = 0; i < this.searchresplist?.length; i++) {
            count = count + 1;
            if (this.tolowercase == "quote" || this.tolowercase == "draft") {
              if (this.searchresplist[i] != "") {
                this.requestfield =
                  "&fields=quote_id,quote.quote_date,quote.data.sub_transaction_type,quote.data.transaction_type,quote.data.query_option_yn,quote.data.agent_first_name,quote.data.tag" +
                  "&filter=" +
                  this.searchresplist[i];
              } else {
                this.requestfield =
                  "&fields=quote_id,quote.quote_date,quote.data.sub_transaction_type,quote.data.transaction_type,quote.data.query_option_yn,quote.data.agent_first_name,quote.data.tag";
              }
            } else if (this.tolowercase == "proposal") {
              this.requestfield =
                "&fields=quote_id,quote.data.agent_first_name,quote.quote_date,quote.data.sub_transaction_type,quote.data.transaction_type,quote.data.query_option_yn,quote.data.tag&filter=" +
                this.searchresplist[i] +
                "&status=0";

                console.log("requestfield proposal", this.requestfield)
            } else if (this.tolowercase == "policy") {
              this.requestfield =
                "&fields=quote_id,quote.data.agent_first_name,quote.quote_date,quote.data.sub_transaction_type,quote.data.transaction_type,quote.data.query_option_yn,quote.data.tag&filter=" +
                this.searchresplist[i];

                console.log("requestfield policy", this.requestfield)
            } else {
              if (this.searchresplist[i] != "") {
                // added 24-12-2020
                if (
                  (this.selectedType === "By Quote Number" ||
                    this.selectedType == "By Quote Id" ||
                    this.selectedType === "By Proposal Number" ||
                    this.selectedType == "By Proposal Id" ||
                    this.selectedType === "By Policy Number" ||
                    this.selectedType === "Certificate No" ||
                    this.selectedType == "By Non Rator Quote Id" ||
                    this.selectedType == "By Renewal Number" ||
                    this.selectedType == "By Renewal Id") &&
                  this.model.searchby
                ) {
                  // added ven 11-03-2021 D0013262
                  // if(this.lobData == 'lta' && this.isUW){
                  // 	this.requestfield = "&fields=quote.data.agent_first_name,quote.quote_date,quote.data.sub_transaction_type,quote.data.transaction_type,quote.data.query_option_yn&filter=";
                  // }else{
                  this.requestfield =
                    "&fields=quote_id,quote.data.agent_first_name,quote.quote_date,quote.data.sub_transaction_type,quote.data.transaction_type,quote.data.query_option_yn,quote.data.tag&filter=" +
                    this.searchresplist[i];
                  // }

                  console.log("requestfield single search", this.requestfield)
                } else {
                  // if(this.lobData == 'lta' && this.isUW){
                  // 	"&fields=quote.quote_date,quote.data.sub_transaction_type,quote.data.transaction_type,quote.data.query_option_yn&filter=&status=0";
                  // }else{
                  this.requestfield =
                    "&fields=quote_id,quote.data.agent_first_name,quote.quote_date,quote.data.sub_transaction_type,quote.data.transaction_type,quote.data.query_option_yn,quote.data.tag&filter=" +
                    this.searchresplist[i] +
                    "&status=0";

                    console.log("requestfield single search else", this.requestfield)
                  // }
                }
              } else {
                this.requestfield = "";
                console.error('Search: Something went wrong');
                console.log("requestfield", this.requestfield)
              }
            }

            this.today = new Date();
            var dd: any = String(this.today.getDate()).padStart(2, "0");
            var mm = String(this.today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = this.today.getFullYear();
            dd = !this.globals.leapYear(yyyy) && mm == "02" ? dd - 1 : dd;
            this.today = yyyy + "-" + mm + "-" + dd;
            if (this.model.searchby) {
              this.model.searchby = this.model.searchby.trim();
            }

            for (let i = 0; i < this.multipleServerUrl.length; i++) {
              const url = this.multipleServerUrl[i];
              if (
                this.selectedType === "By Quote Number" ||
                this.selectedType == "By Quote Id" ||
                this.selectedType == "By Non Rator Quote Id" ||
                (this.selectedType == "By Renewal Id" && this.model.searchby)
              ) {
                let quoteNoOrQuoteId;
                if (this.selectedType === "By Quote Number") {
                  quoteNoOrQuoteId = "&g_search_quote_no=";
                } else if (this.selectedType == "By Quote Id") {
                  quoteNoOrQuoteId = "&quote_id=";
                } else if (
                  this.selectedType == "By Non Rator Quote Id" ||
                  this.selectedType == "By Renewal Id"
                ) {
                  quoteNoOrQuoteId = "&cust_fld_1=";
                }
                // || this.selectedStageVal ==='Proposal' || this.selectedStageVal ==='Policy'
                // let dataurl =
                //   location.protocol +
                //   "//" +
                //   url +
                //   "/api/v1/quote/list?nodata=1&schema=1&order=u_ts%20desc&u_ts__start=" +
                //   this.today +
                //   "T00:00:00" +
                //   "&^product_group_id=" +
                //   this.pro_ID +
                //   this.requestfield +
                //   quoteNoOrQuoteId +
                //   this.model.searchby; //Q200000020141
                  let dataurl =
                    location.protocol +
                    "//" +
                    this.urlValue +
                    "/api/v1/policy/list2?" +
                    "&fields=" +
                    LIST2APIFIELDS +
                    quoteNoOrQuoteId +
                    this.model.searchby
                this.service
                  .Product_grpID(dataurl)
                  .subscribe(async (response) => {
                    if (response.status == 0) {
                      this.finalcardstatus = this.tolowercase;

                      if (response["data"].length > 0) {
                        let duplicate1 = response.data;
                        this.cardlist = [...new Set(duplicate1)];
                        ////// Hide Download Policy Button
                        this._HideDownloadPolicyIcon();
                        this.finalcardstatusmsg =
                          this.cardlist.length +
                          this.translate.instant("staticTs.resultFound") +
                          this.selectedStageVal +
                          this.translate.instant("staticTs.list");
                      }

                      this.searchSpinner = false;
                      this.submit = true;
                      this.loading = false;
                    } else {
                      this.swalService.error(response.txt);

                      this.searchSpinner = false;
                      this.submit = true;
                      this.loading = false;
                    }
                  });
              } else if (
                (this.selectedType === "By Proposal Number" ||
                  this.selectedType == "By Proposal Id") &&
                this.model.searchby
              ) {
                let proposalNoOrproposalId;
                if (this.selectedType === "By Proposal Number") {
                  proposalNoOrproposalId = "&g_search_proposal_no=";
                } else if (this.selectedType == "By Proposal Id") {
                  proposalNoOrproposalId = "&proposal_id=";
                }
                // let dataurl =
                //   location.protocol +
                //   "//" +
                //   url +
                //   "/api/v1/proposal/list?nodata=1&schema=1&order=u_ts%20desc&u_ts__start=" +
                //   this.today +
                //   "T00:00:00" +
                //   "&^product_group_id=" +
                //   this.pro_ID +
                //   this.requestfield +
                //   proposalNoOrproposalId +
                //   this.model.searchby; //Q200000020141
                let dataurl =
                  location.protocol +
                  "//" +
                  this.urlValue +
                  "/api/v1/policy/list2?" +
                  "&fields=" +
                  LIST2APIFIELDS +
                  proposalNoOrproposalId +
                  this.model.searchby 
                this.service
                  .Product_grpID(dataurl)
                  .subscribe(async (response) => {
                    this.finalcardstatus = this.tolowercase;
                    if (response.status == 0) {
                      if (response["data"].length > 0) {
                        let duplicate = response.data;
                        this.cardlist = [...new Set(duplicate)];
                        ////// Hide Download Policy Button
                        this._HideDownloadPolicyIcon();
                        this.finalcardstatusmsg =
                          this.cardlist.length +
                          this.translate.instant("staticTs.resultFound") +
                          this.selectedStageVal +
                          this.translate.instant("staticTs.list");
                      }
                      this.searchSpinner = false;
                      this.submit = true;
                      this.loading = false;
                    } else {
                      this.swalService.error(response.txt);

                      this.searchSpinner = false;
                      this.submit = true;
                      this.loading = false;
                    }
                  });
              } else if (
                (this.selectedType === "By Policy Number" ||
                  this.selectedType == "By Renewal Number") &&
                this.model.searchby
              ) {
                // let policyRenewalTag = "policy_no=";
                // let stage = "policy";
                // if (this.selectedType == "By Renewal Number") {
                //   policyRenewalTag = "cust_fld_1=";
                //   stage = this.apitolowercase;
                // }
                // let dataurl =
                //   location.protocol +
                //   "//" +
                //   url +
                //   "/api/v1/" +
                //   stage +
                //   "/list?nodata=1&schema=1&order=u_ts%20desc&u_ts__start=" +
                //   this.today +
                //   "T00:00:00" +
                //   "&^product_group_id=" +
                //   this.pro_ID +
                //   this.requestfield +
                //   "&" +
                //   policyRenewalTag +
                //   this.model.searchby; //Q200000020141
                let policyRenewalNoTag = "&g_policy_no=";
                if (this.selectedType == "By Renewal Number") {
                  policyRenewalNoTag = "&data.renewal_quote_id=";
                }
                let dataurl =
                  location.protocol +
                  "//" +
                  this.urlValue +
                  "/api/v1/policy/list2?" +
                  "&fields=" +
                  LIST2APIFIELDS +
                  policyRenewalNoTag +
                  this.model.searchby 
                this.service
                  .Product_grpID(dataurl)
                  .subscribe(async (response) => {
                    this.finalcardstatus = this.tolowercase; //policy
                    if (response.status == 0) {
                      if (response["data"].length > 0) {
                        this.quoteResponse = response.data;
                        let duplicate = response.data;
                        this.cardlist = [...new Set(duplicate)];
                        ////// Hide Download Policy Button
                        this._HideDownloadPolicyIcon();
                        this.finalcardstatusmsg =
                          this.cardlist.length +
                          this.translate.instant("staticTs.resultFound") +
                          this.selectedStageVal +
                          this.translate.instant("staticTs.list");
                      }
                    }

                    this.searchSpinner = false;
                    this.submit = true;
                    this.loading = false;
                  });
              } else if (
                this.selectedType === "By Renewal Master Policy Number" &&
                this.model.searchby
              ) {
                // let dataurl =
                //   location.protocol +
                //   "//" +
                //   url +
                //   "/api/v1/policy/list?nodata=1&schema=1&order=u_ts%20desc&u_ts__start=" +
                //   this.today +
                //   "T00:00:00" +
                //   "&^product_group_id=" +
                //   this.pro_ID +
                //   this.requestfield +
                //   "&parent_policy_no=" +
                //   this.model.searchby;
                let dataurl =
                  location.protocol +
                  "//" +
                  this.urlValue +
                  "/api/v1/policy/list2?" +
                  "&fields=" +
                  LIST2APIFIELDS +
                 "&parent_policy_no=" +
                  this.model.searchby + `&start=${pageIndex}&count=50`
                this.service
                  .Product_grpID(dataurl)
                  .subscribe(async (response) => {
                    this.finalcardstatus = this.tolowercase; // renewal master policy number
                    if (response.status == 0) {
                      if (response["data"].length > 0) {
                        this.quoteResponse = response.data;
                        let duplicate = response.data;
                        this.cardlist = [...new Set(duplicate)];
                        ////// Hide Download Policy Button
                        this._HideDownloadPolicyIcon();
                        this.finalcardstatusmsg =
                          this.cardlist.length +
                          this.translate.instant("staticTs.resultFound") +
                          this.selectedStageVal +
                          this.translate.instant("staticTs.list");
                      }
                    }

                    this.searchSpinner = false;
                    this.submit = true;
                    this.loading = false;
                  });
              }
              // GOTG Search in Travel
              else if (
                this.selectedType === "Certificate No" &&
                this.model.searchby
              ) {
                // let dataurl =
                //   location.protocol +
                //   "//" +
                //   url +
                //   "/api/v1/policy/list?nodata=1&schema=1&order=u_ts%20desc&u_ts__start=" +
                //   this.today +
                //   "T00:00:00" +
                //   "&^product_group_id=" +
                //   this.pro_ID +
                //   this.requestfield +
                //   "&cust_fld_4=" +
                //   this.model.searchby;
                let dataurl =
                  location.protocol +
                  "//" +
                  this.urlValue +
                  "/api/v1/policy/list2?" +
                  "&fields=" +
                  LIST2APIFIELDS +
                  "&cust_fld_4=" +
                  this.model.searchby 
                if (this.personalaccident) {
                  // dataurl =
                  //   location.protocol +
                  //   "//" +
                  //   url +
                  //   "/api/v1/policy/list?nodata=1&schema=1&order=u_ts%20desc&u_ts__start=" +
                  //   this.today +
                  //   "T00:00:00" +
                  //   "&^product_group_id=" +
                  //   this.pro_ID +
                  //   this.requestfield +
                  //   "&cust_fld_1=" +
                  //   this.model.searchby;
                  dataurl =
                    location.protocol +
                    "//" +
                    this.urlValue +
                    "/api/v1/policy/list2?order=u_ts%20desc&u_ts=[]" + moment().subtract(3,'months').format('YYYY-MM-DD') +',' + moment().add(1,'days').format('YYYY-MM-DD')+
                    "&product_id=^" +
                    this.allProductIds +
                    "&fields=" +
                    LIST2APIFIELDS +
                    "&cust_fld_1=" +
                    this.model.searchby +
                    "&quote.status=^0,1,2,3,4&index=in_jdata_u_ts_product_id_status" + `&start=${pageIndex}&count=50`
                }
                if (this.selectedCertificatePolicy?.length) {
                  dataurl +=
                  "%20" + "&cust_fld_2=" + this.selectedCertificatePolicy + "%20";
                }
                this.service
                  .Product_grpID(dataurl)
                  .subscribe(async (response) => {
                    this.finalcardstatus = this.tolowercase; //certificate
                    if (response.status == 0) {
                      if (response["data"].length > 0) {
                        this.quoteResponse = response.data;
                        let duplicate = response.data;
                        this.cardlist = [...new Set(duplicate)];
                        ////// Hide Download Policy Button
                        this._HideDownloadPolicyIcon();
                        this.finalcardstatusmsg =
                          this.cardlist.length +
                          this.translate.instant("staticTs.resultFound") +
                          this.selectedStageVal +
                          this.translate.instant("staticTs.list");
                      }
                    }

                    this.searchSpinner = false;
                    this.submit = true;
                    this.loading = false;
                  });
              } else {
                // let dataurl =
                //   location.protocol +
                //   "//" +
                //   url +
                //   "/api/v1/" +
                //   this.apitolowercase +
                //   "/list?^product_group_id=" +
                //   this.pro_ID +
                //   this.requestfield +
                //   "&u_ts__start=" +
                //   start +
                //   "T00:00:00" +
                //   "&u_ts__end=" +
                //   end +
                //   "T23:59:00" +
                //   "&count=2000"; //Q200000020141   // changing count from 10000 to 2000 for all products as told by praveen on whatsapp
                let dataurl;
                if(this.selectedStageVal?.toLowerCase() === "quote") {
                  dataurl = location.protocol +
                    "//" +
                    this.urlValue +
                    "/api/v1/policy" +
                    "/list2?product_id=^" +
                    this.allProductIds +
                    this.requestfield +
                    "&fields=" +
                    LIST2APIFIELDS +
                    "&u_ts=[]" +
                    start +
                    "T00:00:00" +
                    "," +
                    end +
                    "T23:59:00&quote.status=0&quote_no=!&proposal_no=" + `&start=${pageIndex}&count=50`
                    //Q200000020141   // changing count from 10000 to 2000, changing count from 10000 to 2000 for all products as told by praveen on whatsapp
                }
                if(this.selectedStageVal?.toLowerCase() === "proposal") {
                  dataurl =
                    location.protocol +
                    "//" +
                    this.urlValue +
                    "/api/v1/policy" +
                    "/list2?product_id=^" +
                    this.allProductIds +
                    this.requestfield +
                    "&fields=" +
                    LIST2APIFIELDS +
                    "&u_ts=[]" +
                    start +
                    "T00:00:00" +
                    "," +
                    end +
                    "T23:59:00&quote.status=0&proposal_no=!" + `&start=${pageIndex}&count=50`
                }
                if(this.selectedStageVal?.toLowerCase() === "policy") {
                  dataurl =
                    location.protocol +
                    "//" +
                    this.urlValue +
                    "/api/v1/" +
                    this.apitolowercase +
                    "/list2?product_id=^" +
                    this.allProductIds +
                    this.requestfield +
                    "&fields=" +
                    LIST2APIFIELDS +
                    "&u_ts=[]" +
                    start +
                    "T00:00:00" +
                    "," +
                    end +
                    "T23:59:00&&quote.status=2" + `&start=${pageIndex}&count=50` //Q200000020141   // changing count from 10000 to 2000, changing count from 10000 to 2000 for all products as told by praveen on whatsapp
              }
                await this.service
                  .Product_grpID(dataurl)
                  .subscribe(async (response) => {
                    this.quoteResponse = response.data;
                    if (response.status == 0) {
                      this.finalcardstatus = this.tolowercase;
                      if (
                        (this.model.searchby != "" &&
                          this.model.searchby != undefined) ||
                        (this.model.max != "" &&
                          this.model.max != undefined &&
                          this.model.min != "" &&
                          this.model.min != undefined)
                      ) {
                        this.cardlist = [];
                        let duplicate = response.data;
                        this.concatlist = this.concatlist.concat(duplicate);
                        // console.log(this.concatlist);
                        this.responsefromapicart = await this.concatlist.filter(
                          (arr, index, self) =>
                            index ===
                            self.findIndex((t) => t.quote_id === arr.quote_id)
                        ); //added 05-01-2021
                        await this.responsefromapicart.filter((o) => {
                          let stagesCheck = false;
                          // if(this.selectedLob === 'financialline'){
                          // 	stagesCheck = (this.tolowercase == 'quote' && (o.proposal_no == '' || o.proposal_no == null || o.proposal_no == undefined))
                          // 	|| (this.tolowercase == 'proposal' && o.proposal_no != '' && o.proposal_no != null && o.proposal_no != undefined && (o.policy_no == '' || o.policy_no == null))
                          // 	|| this.tolowercase == 'policy'
                          // }
                          // else{
                          stagesCheck =
                            (this.tolowercase == "quote" &&
                              o.quote_no != "" &&
                              o.quote_no != null &&
                              o.quote_no != undefined &&
                              (o.proposal_no == "" || o.proposal_no == null)) ||
                            (this.tolowercase == "proposal" &&
                              o.proposal_no != "" &&
                              o.proposal_no != null &&
                              o.proposal_no != undefined &&
                              (o.policy_no == "" ||
                                o.policy_no == null ||
                                o.policy_no == "null")) ||
                            this.tolowercase == "policy" ||
                            (this.tolowercase == "draft" &&
                              (o.quote_no == "" || o.quote_no == null) &&
                              (o.proposal_no == "" || o.proposal_no == null) &&
                              (o.policy_no == "" ||
                                o.policy_no == null ||
                                o.policy_no == "null"));

                          // }

                          if (stagesCheck) {
                            // added 24-12-2020
                            this.cardlisttemp.push(o);
                            if (
                              this.selectedType == "By Mobile Number" &&
                              o.data.search != undefined
                            ) {
                              if (
                                o.data.search.customer_mobileno ==
                                this.model.searchby
                              ) {
                                this.cardlist.push(o);
                              }
                            } else if (
                              this.selectedType == "By Name" &&
                              o.data.search != undefined &&
                              o.data.search.customer_name
                            ) {
                              if (
                                o.data.search.customer_name.toLowerCase() ==
                                this.model.searchby.toLowerCase()
                              ) {

                                this.cardlist.push(o);
                              }
                            } else if (
                              this.selectedType == "By Email ID" &&
                              o.data.search != undefined
                            ) {
                              if (o.data.search.customer_email != undefined) {
                                if (
                                  o.data.search.customer_email.toLowerCase() ==
                                  this.model.searchby.toLowerCase()
                                ) {
                                  this.cardlist.push(o);
                                }
                              }
                            } else if (
                              this.selectedType == "By Premium Amount" &&
                              o.data.search != undefined
                            ) {
                              if (o.data.search.premium != undefined) {
                                if (
                                  Number(o.data.search.premium) >=
                                    Number(this.model.min) &&
                                  Number(o.data.search.premium) <=
                                    Number(this.model.max)
                                ) {
                                  this.cardlist.push(o);
                                }
                              }
                            } else if (
                              this.selectedType == "By Lead Id" &&
                              o.data.search != undefined
                            ) {
                              if (
                                o.data.search.inspection_ticket_no ==
                                this.model.searchby
                              ) {
                                this.cardlist.push(o);
                              }
                            } else if (
                              /***********muruga 02/11/2020*************** */
                              this.selectedType == "Departure Date" &&
                              o != undefined
                            ) {
                              if (
                                this.model.searchby != "" &&
                                this.model.searchby != undefined
                              ) {
                                let startd =
                                  this.model.searchby.split("/")[1] +
                                  "/" +
                                  this.model.searchby.split("/")[0] +
                                  "/" +
                                  this.model.searchby.split("/")[2];
                                if (
                                  o.hasOwnProperty("data") &&
                                  o.data.hasOwnProperty("search")
                                ) {
                                  if (o.data.search.departure_date == startd) {
                                    this.cardlist.push(o);
                                  }
                                }
                              }
                            } else if (
                              this.selectedType == "Return Date" &&
                              o != undefined
                            ) {
                              if (
                                this.model.searchby != "" &&
                                this.model.searchby != undefined
                              ) {
                                let endd =
                                  this.model.searchby.split("/")[1] +
                                  "/" +
                                  this.model.searchby.split("/")[0] +
                                  "/" +
                                  this.model.searchby.split("/")[2];
                                if (
                                  o.hasOwnProperty("data") &&
                                  o.data.hasOwnProperty("search")
                                ) {
                                  if (
                                    o.data.search.return_date_single == endd
                                  ) {
                                    this.cardlist.push(o);
                                  }
                                }
                              }
                            } else if (
                              this.selectedType == "Passport No" &&
                              o != undefined
                            ) {
                              if (
                                o.hasOwnProperty("data") &&
                                o.data.hasOwnProperty("search")
                              ) {
                                for (let ind = 1; ind <= 10; ind++) {
                                  if (
                                    o.data.search["passport_" + ind] != "" &&
                                    o.data.search["passport_" + ind] !=
                                      undefined
                                  ) {
                                    if (
                                      o.data.search[
                                        "passport_" + ind
                                      ].toLowerCase() ==
                                      this.model.searchby.toLowerCase()
                                    ) {
                                      this.cardlist.push(o);
                                      break;
                                    }
                                  } else {
                                    break;
                                  }
                                }
                              }
                            } else if (
                              this.selectedType == "PAN Card No" &&
                              o != undefined
                            ) {
                              if (
                                o.hasOwnProperty("data") &&
                                o.data.hasOwnProperty("search")
                              ) {
                                /*******anavarathan BTA 20/11/2020************* */
                                if (this.lob_value == "travel") {
                                  for (let indx = 1; indx <= 10; indx++) {
                                    if (
                                      o.data.search["panNo_" + indx] != "" &&
                                      o.data.search["panNo_" + indx] !=
                                        undefined
                                    ) {
                                      if (
                                        o.data.search[
                                          "panNo_" + indx
                                        ].toLowerCase() ==
                                        this.model.searchby.toLowerCase()
                                      ) {
                                        this.cardlist.push(o);
                                        break;
                                      }
                                    } else {
                                      break;
                                    }
                                  }
                                } else if (
                                  /********anavarathan BTA 20/11/2020************* */
                                  o.data.search.proposer_pan ==
                                  this.model.searchby
                                ) {
                                  this.cardlist.push(o);
                                }
                              }
                            }
                            //  else if (
                            // 	this.selectedType == "By Quote Id" &&
                            // 	o != undefined
                            // ) {
                            // 	if (o.quote_id != undefined) {
                            // 		if (
                            // 			o.quote_id.toLowerCase() ==
                            // 			this.model.searchby.toLowerCase()
                            // 		) {
                            // 			this.cardlist.push(o);
                            // 		}
                            // 	}
                            // }
                            // else if (
                            // 	(this.selectedType == "By Non Rator Quote Id" ||
                            // 		this.selectedType == "By Renewal Number") &&
                            // 	o != undefined
                            // ) {
                            // 	if (o.cust_fld_1 != undefined || o.cust_fld_2 != undefined) {
                            // 		if (
                            // 			o.cust_fld_1?.toLowerCase() ==
                            // 			this.model.searchby.toLowerCase() || o.cust_fld_2?.toLowerCase() ==
                            // 			this.model.searchby.toLowerCase()
                            // 		) {
                            // 			this.cardlist.push(o);
                            // 		}
                            // 	}
                            // }
                            // By Sub-Transaction Type in Marine Cargo
                            else if (
                              this.selectedType == "By Sub Transaction Type" &&
                              o != undefined &&
                              (this.model.searchby != "" ||
                                this.model.searchby != undefined)
                            ) {
                              if (
                                o.quote.data.sub_transaction_type != undefined
                              ) {
                                if (
                                  o.quote.data.sub_transaction_type.toLowerCase() ==
                                  this.model.searchby.toLowerCase()
                                ) {
                                  this.cardlist.push(o);
                                }
                              }
                            } else if (
                              this.selectedType == "By Reg No" &&
                              o.data.search != undefined
                            ) {
                              if (o.data.search.regno) {
                                if (
                                  o.data.search.regno.toLowerCase() ==
                                  this.model.searchby.toLowerCase()
                                ) {
                                  this.cardlist.push(o);
                                }
                              }
                            } else if (
                              this.selectedType?.trim() ==
                                "By  Partner Application ID" &&
                              o.data.search != undefined
                            ) {
                              if (o.data.search.proposer_partno) {
                                if (
                                  o.data.search.proposer_partno.toLowerCase() ==
                                  this.model.searchby.toLowerCase()
                                ) {
                                  this.cardlist.push(o);
                                }
                              }
                            } else if (
                              this.selectedType == "By Master Policy Number" &&
                              o != undefined
                            ) {
                              if (
                                o?.data?.search?.master_policy_number ==
                                this.model.searchby
                              ) {
                                this.cardlist.push(o);
                              }
                            } else if (
                              this.selectedType == "By Loan account number" &&
                              o != undefined
                            ) {
                              if (
                                o?.data?.search?.loan_account_number ==
                                this.model.searchby
                              ) {
                                this.cardlist.push(o);
                              }
                            } else if (
                              this.selectedType == "By Cart ID" &&
                              o != undefined
                            ) {
                              if (o?.cart_id == this.model.searchby) {
                                this.cardlist.push(o);
                              }
                            }
                          }
                        });
                        this.cradlisttempLTA =
                          this.cardlist; /*******muruga LTA 30/10/2020************* */
                      } else {
                        let duplicate = response.data;

                        this.concatlist = this.concatlist.concat(duplicate);
                        if (this.searchresplist.length == count) {
                          this.responsefromapicart =
                            await this.concatlist.filter(
                              (arr, index, self) =>
                                index ===
                                self.findIndex(
                                  (t) => t.quote_id === arr.quote_id
                                )
                            );
                          await this.responsefromapicart.filter((o) => {
                            if (
                              (this.tolowercase == "quote" &&
                                o.quote_no != "" &&
                                o.quote_no != null &&
                                o.quote_no != undefined &&
                                (o.proposal_no == "" ||
                                  o.proposal_no == null)) ||
                              (this.tolowercase == "proposal" &&
                                o.proposal_no != "" &&
                                o.proposal_no != null &&
                                o.proposal_no != undefined &&
                                (o.policy_no == "" ||
                                  o.policy_no == null ||
                                  o.policy_no == "null")) ||
                              this.tolowercase == "policy" ||
                              (this.tolowercase == "draft" &&
                                (o.quote_no == "" || o.quote_no == null) &&
                                (o.proposal_no == "" ||
                                  o.proposal_no == null) &&
                                (o.policy_no == "" ||
                                  o.policy_no == null ||
                                  o.policy_no == "null"))
                            ) {
                              if (this.cardlisttemp.includes(o) == false) {
                                if (
                                  this.urlcurrentstage == "UW" ||
                                  this.urlcurrentstage == "PP"
                                ) {
                                  if (
                                    (this.urlcurrentstage == "UW" &&
                                      (o.stage == "nstp" ||
                                        o.stage == "pnstp")) ||
                                    (this.urlcurrentstage == "PP" &&
                                      o.stage == "payment")
                                  ) {
                                    this.cardlisttemp.push(o);
                                    this.cardlist.push(o);
                                  }
                                } else {
                                  this.cardlisttemp.push(o);
                                  this.cardlist.push(o);
                                }
                              }
                            }
                          });
                          this.cradlisttempLTA = this.cardlist;
                        }
                      }
                      // =======================non rater separation ================================>
                      this.nonRatorList = [];
                      this.ratorList = [];
                      this.nonRatorList = this.cardlist.filter(
                        (cards) =>
                          cards.product_id == this.service.nonRaterProductId
                      ); //filter quotes with non-rater product id
                      this.ratorList = this.cardlist.filter(
                        (cards) =>
                          cards.product_id != this.service.nonRaterProductId
                      );

                      var selctedlobnrproductlist = this.nonRatorList.filter(
                        (nr1) =>
                          nr1.data && nr1.data.search
                            ? nr1.data.search.product_name
                                ?.replace(/\s/g, "")
                                ?.toLowerCase() == this.selectedLob
                            : false
                      );
                      this.cardlist = [
                        ...this.ratorList,
                        ...selctedlobnrproductlist,
                      ];

                      this.finalcardstatusmsg =
                        this.cardlist.length +
                        this.translate.instant("staticTs.resultFound") +
                        this.selectedStageVal +
                        this.translate.instant("staticTs.list");
                      if (this.cardlist.length != 0) {
                        this.disDownload = false;
                      } else {
                        this.disDownload = true;
                      }
                    }
                    //// Hide Download Policy Button
                    this._HideDownloadPolicyIcon();
                    /// Update Cardlist with Filtered data in session storage

                    this.filter();
                    if (this.route.snapshot?.queryParams?.type == "crossSell") {
                      this.isCrossSellFilter = true;
                      let obj = {};
                      for (let item of this.cardlist) {
                        if (item?.data?.search?.crosell_id) {
                          if (!obj.hasOwnProperty(item?.data?.search?.crosell_id)) {
                            obj[item?.data?.search?.crosell_id] = {
                              data: {
                                search: {
                                  customer_email:
                                    item.data.search.customer_email,
                                  customer_mobileno:
                                    item.data.search.customer_mobileno,
                                  customer_name: item.data.search.customer_name,
                                  location: item.data.search.location,
                                  nstp_status: [item.data.search.nstp_status],
                                  premium: item.data.search.premium,
                                  product_name: [item.data.search.product_name],
                                  product_nameString:
                                    item.data.search.product_name,
                                  product_id: [item.product_id] ,
                                  proposal_no: [item.proposal_no],
                                  quote_no: [item.quote_no],
                                  quote_id: [item.quote_id],
                                  policy_no: [item.policy_no],
                                  u_ts: item.u_ts,
                                  cross_sell: item.data.search.cross_sell,
                                  crosell_id: item.data.search.crosell_id,
                                  base_product : item.data.search?.base_product,
                                  parent_product_id : item.data.search?.parent_product_id
                                },
                              },
                            };
                          } else {
                            obj[item.data.search.crosell_id]["data"]["search"][
                              "nstp_status"
                            ].push(item.data.search.nstp_status);
                            obj[item.data.search.crosell_id]["data"]["search"]["premium"] =
                              parseInt(
                                obj[item.data.search.crosell_id]["data"]["search"]["premium"]
                              ) + parseInt(item.data.search.premium);
                            obj[item.data.search.crosell_id]["data"]["search"][
                              "product_name"
                            ].push(item.data.search.product_name);
                            obj[item.data.search.crosell_id]["data"]["search"][
                              "product_nameString"
                            ] =
                              obj[item.data.search.crosell_id]["data"]["search"][
                                "product_nameString"
                              ] +
                              "," +
                              item.data.search.product_name;
                            obj[item.data.search.crosell_id]["data"]["search"][
                              "product_id"
                            ].push(item.product_id);
                            obj[item.data.search.crosell_id]["data"]["search"][
                              "proposal_no"
                            ].push(item.proposal_no);
                            obj[item.data.search.crosell_id]["data"]["search"][
                              "quote_no"
                            ].push(item.quote_no);
                            obj[item.data.search.crosell_id]["data"]["search"][
                              "quote_id"
                            ].push(item.quote_id);
                            obj[item.data.search.crosell_id]["data"]["search"][
                              "policy_no"
                            ].push(item.policy_no);
                          }
                        }
                      }
                      this.cardlist = Object.values(obj);
                      let newArr = [];
                      console.log("check cardlist 1",this.cardlist)
                      for (let card of this.cardlist) {
                        if (card.data.search.cross_sell || card.data.search.crosell_id) {
                          newArr.push(card);
                        }
                      }
                      this.cardlist = newArr;
                      console.log(this.cardlist);

                      // return;
                    }
                    this.getDeoArr(this.cardlist);

                    this.searchSpinner = false;
                    this.submit = true;
                    this.loading = false;
                  });
              }
            }
          }
        }
      }
    }
  }

  // Get Filtered DEO Array
  getDeoArr(cardList) {
    let arr = [];
    cardList.map((item) => {
      let val = item?.quote?.data?.agent_first_name;
      arr.push({ value: val });
    });

    this.doeArr = [
      ...new Map(arr.map((item) => [item["value"], item])).values(),
    ];
  }

  _HideDownloadPolicyIcon() {
    for (let i = 0; i < this.cardlist.length; i++) {
      this.cardlist[i].showDownloadPolicyBtn = true;
      if (
        this.cardlist[i].policy_no != null &&
        this.cardlist[i].policy_no != "null" &&
        this.cardlist[i].policy_no.substring(0, 2) == "NI"
      ) {
        this.cardlist[i].showDownloadPolicyBtn = false;
      } else {
        this.cardlist[i].showDownloadPolicyBtn = true;
      }
    }
  }

  /*****************muruga 26/10/2020********** Take over API******** */
  take_over() {
    this.modalRef.hide();
    let data = this.cardlist[this.card_index].data.search.quote_id;

    this.service.takeOver(data).subscribe(
      async (response) => {
        if (response.status === 0) {
          if (
            this.cardlist[this.card_index]?.cust_fld_1?.toLowerCase() ==
            "renewal"
          ) {
            await this.ltaRenewalNavigation(
              this.cardlist[this.card_index],
              "proposal"
            );
            return;
          }

          this.service
            .searchnavigation(
              this.cardlist[this.card_index].data.search.product_id
            )
            .subscribe((respo) => {
              if (respo.status === 0) {
                let comp: any = "proposal";
                this.searchnavigationlist = respo.data;
                for (let i in this.searchnavigationlist) {
                  if (this.searchnavigationlist[i].value == comp) {
                    sessionStorage.setItem(
                      "product_id",
                      this.cardlist[this.card_index].data.search.quote_id
                    );
                    const { origin } = window.location;
                    window.location.href = `${origin}/${
                      this.searchnavigationlist[i].name
                    }${this.cardlist[this.card_index].data.search.quote_id}`;
                  }
                }
              }
            });
        } else if (response.status === -114) {
          this.swalService.error(response.txt);
          sessionStorage.clear();
          this.router.navigateByUrl(`/login`);
        } else if (
          response.status == -104 ||
          response.status == -102 ||
          response.status == -103
        ) {
          this.swalService.error(response.txt);
        } else {
          this.swalService.error(response.txt);
        }
      },
      (err) => {
        this.swalService.error("Server Timeout");
      }
    );
  }

  /*******anavarathan BTA 20/11/2020************* */
  cancel_policy() {
    this.modalRef.hide();
    if (this.policyId) {
      let result = this.quoteResponse.filter((obj) => {
        return obj.policy_id === this.policyId;
      });
      result[0]["canceled"] = true;
      let policy_no = result[0]["policy_no"];
      if (policy_no) {
        this.service.cancelPolicy(this.policyId).subscribe((response) => {
          // response.data.response == 'Success' ? Swal.fire(this.translate.instant("staticTs.yourPolicy") + policy_no + ' is cancelled') : ''; //sonar
          if (response.data.response == "Success") {
            Swal.fire(
              this.translate.instant("staticTs.yourPolicy") +
                policy_no +
                this.translate.instant("staticTs.isCancelled")
            );
          }
          this.policyId = "";
          this.cancelClick = false;
        });
      }
      this.cancelClick = true;
    }
  }
  moreshowlobs() {
    this.lobDatamorebutton = false;
    this.lobDataindex = this.lobData.length;
  }
  content: any = [];
  array: any = [];
  file() {
    let quoteData = [];
    this.cardlist.forEach((element) => {
      if (this.finalcardstatus == "quote" && this.selectedLobValue == "lta") {
        this.obj = {
          //name: element ?.data ?.search ?.proposer_title + " " + element ?.data ?.search ?.customer_name,
          name:
            (element?.data?.search?.proposer_title
              ? element?.data?.search?.proposer_title
              : "") +
            " " +
            (element?.data?.search?.customer_name
              ? element?.data?.search?.customer_name
              : ""),
          email: element.data
            ? element.data.search
              ? element.data.search.customer_email
              : ""
            : "",
          created_by: element.created_by ? element.created_by : "",
          mobile: element.data
            ? element.data.search
              ? element.data.search.customer_mobileno
              : ""
            : "",
          product_name: element.data
            ? element.data.search
              ? element.data.search.selected_new_product_name
              : ""
            : "",
          premium: element.data
            ? element.data.search
              ? element.data.search.premium
              : ""
            : "",
          // application_date: element.data
          //   ? element.data.search
          //     ? element.data.search.application_date
          //     : ""
          //   : "",
          quoteNo: element.quote_no
            ? element.quote_no
            : element.data
            ? element.data.search
              ? element.data.search.quote_no
              : ""
            : "",
          quoteId: element.quote_id ? element.quote_id : "",
          quoteDate: element.quote
            ? element.quote
              ? element.quote.quote_date
              : ""
            : "",
          travelers: element.data
            ? element.data.search
              ? element.data.search.member_count == undefined
                ? ""
                : element.data.search.member_count
              : ""
            : "",
          coveragePeriod: element.data
            ? element.data.search
              ? element.data.search.coverage_period == undefined
                ? ""
                : element.data.search.coverage_period
              : ""
            : "",
          selectedZone: element.data
            ? element.data.search
              ? element.data.search.selected_zone == undefined
                ? ""
                : element.data.search.selected_zone
              : ""
            : "",
          status: element.data
            ? element.data.search
              ? element.data.search.nstp_status == undefined
                ? ""
                : element.data.search.nstp_status
              : ""
            : "",
        };
      }
      if (this.finalcardstatus == "policy" && this.selectedLobValue == "lta") {
        this.obj = {
          //name: element ?.data ?.search ?.proposer_title + " " + element ?.data ?.search ?.customer_name,
          name:
            (element?.data?.search?.proposer_title
              ? element?.data?.search?.proposer_title
              : "") +
            " " +
            (element?.data?.search?.customer_name
              ? element?.data?.search?.customer_name
              : ""),
          email: element.data
            ? element.data.search
              ? element.data.search.customer_email
              : ""
            : "",
          created_by: element.created_by ? element.created_by : "",
          mobile: element.data
            ? element.data.search
              ? element.data.search.customer_mobileno
              : ""
            : "",
          product_name: element.data
            ? element.data.search
              ? element.data.search.selected_new_product_name
              : ""
            : "",
          premium: element.data
            ? element.data.search
              ? element.data.search.premium
              : ""
            : "",
          // application_date: element.data
          //   ? element.data.search
          //     ? element.data.search.application_date
          //     : ""
          //   : "",
          policyNo: element.policy_no ? element.policy_no : "",
          policyId: element.policy_id
            ? element.policy_id
            : element?.data
            ? element?.data?.search
              ? element?.data?.search?.policy_id
              : ""
            : "",
          policyStartDate: element.policy_start_date
            ? element.policy_start_date
            : "",
          policyEndDate: element.policy_end_date ? element.policy_end_date : "",
          //quotationNo: element.data?element.data.search?element.data.search.quote_no,
          policyDate: element.quote
            ? element.quote
              ? element.quote.quote_date
              : ""
            : "",
          travelers: element.data
            ? element.data.search
              ? element.data.search.member_count == undefined
                ? ""
                : element.data.search.member_count
              : ""
            : "",
          coveragePeriod: element.data
            ? element.data.search
              ? element.data.search.coverage_period == undefined
                ? ""
                : element.data.search.coverage_period
              : ""
            : "",
          selectedZone: element.data
            ? element.data.search
              ? element.data.search.selected_zone == undefined
                ? ""
                : element.data.search.selected_zone
              : ""
            : "",
          status: element.data
            ? element.data.search
              ? element.data.search.nstp_status == undefined
                ? ""
                : element.data.search.nstp_status
              : ""
            : "",
        };
      }
      if (
        this.finalcardstatus == "proposal" &&
        this.selectedLobValue == "lta"
      ) {
        this.obj = {
          // name: element ?.data ?.search ?.proposer_title + " " + element ?.data ?.search ?.customer_name,
          name:
            (element?.data?.search?.proposer_title
              ? element?.data?.search?.proposer_title
              : "") +
            " " +
            (element?.data?.search?.customer_name
              ? element?.data?.search?.customer_name
              : ""),
          email: element.data
            ? element.data.search
              ? element.data.search.customer_email
              : ""
            : "",
          created_by: element.created_by ? element.created_by : "",
          mobile: element.data
            ? element.data.search
              ? element.data.search.customer_mobileno
              : ""
            : "",
          product_name: element.data
            ? element.data.search
              ? element.data.search.selected_new_product_name
              : ""
            : "",
          premium: element.data
            ? element.data.search
              ? element.data.search.premium
              : ""
            : "",
          // application_date: element.data
          //   ? element.data.search
          //     ? element.data.search.application_date
          //     : ""
          //   : "",
          proposalNo: element.proposal_no
            ? element.proposal_no
            : element.data
            ? element.data.search
              ? element.data.search.proposal_no
              : ""
            : "",
          proposalId: element.proposal_id
            ? element.proposal_id
            : element.data
            ? element.data.search
              ? element.data.search.proposal_id
              : ""
            : "",
          travelers: element.data
            ? element.data.search
              ? element.data.search.member_count == undefined
                ? ""
                : element.data.search.member_count
              : ""
            : "",
          coveragePeriod: element.data
            ? element.data.search
              ? element.data.search.coverage_period == undefined
                ? ""
                : element.data.search.coverage_period
              : ""
            : "",
          selectedZone: element.data
            ? element.data.search
              ? element.data.search.selected_zone == undefined
                ? ""
                : element.data.search.selected_zone
              : ""
            : "",
          status: element.data
            ? element.data.search
              ? element.data.search.nstp_status == undefined
                ? ""
                : element.data.search.nstp_status
              : ""
            : "",
          //quotationNo: element.data?element.data.search?element.data.search.quote_no,
          //proposal_date:element?.c_ts:"":"",
        };
      }
      if (
        this.finalcardstatus == "quote" &&
        this.selectedLobValue == "travel"
      ) {
        this.obj = {
          //name: element ?.data ?.search ?.proposer_title + " " + element ?.data ?.search ?.customer_name,
          name:
            (element?.data?.search?.proposer_title
              ? element?.data?.search?.proposer_title
              : "") +
            " " +
            (element?.data?.search?.customer_name
              ? element?.data?.search?.customer_name
              : ""),
          email: element.data
            ? element.data.search
              ? element.data.search.customer_email
              : ""
            : "",
          created_by: element.created_by ? element.created_by : "",
          mobile: element.data
            ? element.data.search
              ? element.data.search.customer_mobileno
              : ""
            : "",
          product_name: element.data
            ? element.data.search
              ? element.data.search.selected_new_product_name
              : ""
            : "",
          premium: element.data
            ? element.data.search
              ? element.data.search.premium
              : ""
            : "",
          // application_date: element.data
          //   ? element.data.search
          //     ? element.data.search.application_date
          //     : ""
          //   : "",
          quoteNo: element.quote_no
            ? element.quote_no
            : element.data
            ? element.data.search
              ? element.data.search.quote_no
              : ""
            : "",
          quoteId: element.quote_id ? element.quote_id : "",
          quoteDate: element.quote
            ? element.quote
              ? element.quote.quote_date
              : ""
            : "",
          travelers: element.data
            ? element.data.search
              ? element.data.search.member_count == undefined
                ? ""
                : element.data.search.member_count
              : ""
            : "",
          coveragePeriod: element.data
            ? element.data.search
              ? element.data.search.coverage_period == undefined
                ? ""
                : element.data.search.coverage_period
              : ""
            : "",
          status: element.data
            ? element.data.search
              ? element.data.search.nstp_status == undefined
                ? ""
                : element.data.search.nstp_status
              : ""
            : "",
        };
      }
      if (
        this.finalcardstatus == "policy" &&
        this.selectedLobValue == "travel"
      ) {
        this.obj = {
          //name: element ?.data ?.search ?.proposer_title + " " + element ?.data ?.search ?.customer_name,
          name:
            (element?.data?.search?.proposer_title
              ? element?.data?.search?.proposer_title
              : "") +
            " " +
            (element?.data?.search?.customer_name
              ? element?.data?.search?.customer_name
              : ""),
          email: element.data
            ? element.data.search
              ? element.data.search.customer_email
              : ""
            : "",
          created_by: element.created_by ? element.created_by : "",
          mobile: element.data
            ? element.data.search
              ? element.data.search.customer_mobileno
              : ""
            : "",
          product_name: element.data
            ? element.data.search
              ? element.data.search.selected_new_product_name
              : ""
            : "",
          premium: element.data
            ? element.data.search
              ? element.data.search.premium
              : ""
            : "",
          // application_date: element.data
          //   ? element.data.search
          //     ? element.data.search.application_date
          //     : ""
          //   : "",
          policyNo: element.policy_no ? element.policy_no : "",
          policyId: element.policy_id
            ? element.policy_id
            : element?.data
            ? element?.data?.search
              ? element?.data?.search?.policy_id
              : ""
            : "",
          policyStartDate: element.policy_start_date
            ? element.policy_start_date
            : "",
          policyEndDate: element.policy_end_date ? element.policy_end_date : "",
          //quotationNo: element.data?element.data.search?element.data.search.quote_no,
          policyDate: element.quote
            ? element.quote
              ? element.quote.quote_date
              : ""
            : "",
          travelers: element.data
            ? element.data.search
              ? element.data.search.member_count == undefined
                ? ""
                : element.data.search.member_count
              : ""
            : "",
          coveragePeriod: element.data
            ? element.data.search
              ? element.data.search.coverage_period == undefined
                ? ""
                : element.data.search.coverage_period
              : ""
            : "",
          status: element.data
            ? element.data.search
              ? element.data.search.nstp_status == undefined
                ? ""
                : element.data.search.nstp_status
              : ""
            : "",
        };
      }
      if (
        this.finalcardstatus == "proposal" &&
        this.selectedLobValue == "travel"
      ) {
        this.obj = {
          // name: element ?.data ?.search ?.proposer_title + " " + element ?.data ?.search ?.customer_name,
          name:
            (element?.data?.search?.proposer_title
              ? element?.data?.search?.proposer_title
              : "") +
            " " +
            (element?.data?.search?.customer_name
              ? element?.data?.search?.customer_name
              : ""),
          email: element.data
            ? element.data.search
              ? element.data.search.customer_email
              : ""
            : "",
          created_by: element.created_by ? element.created_by : "",
          mobile: element.data
            ? element.data.search
              ? element.data.search.customer_mobileno
              : ""
            : "",
          product_name: element.data
            ? element.data.search
              ? element.data.search.selected_new_product_name
              : ""
            : "",
          premium: element.data
            ? element.data.search
              ? element.data.search.premium
              : ""
            : "",
          // application_date: element.data
          //   ? element.data.search
          //     ? element.data.search.application_date
          //     : ""
          //   : "",
          proposalNo: element.proposal_no
            ? element.proposal_no
            : element.data
            ? element.data.search
              ? element.data.search.proposal_no
              : ""
            : "",
          proposalId: element.proposal_id
            ? element.proposal_id
            : element.data
            ? element.data.search
              ? element.data.search.proposal_id
              : ""
            : "",
          travelers: element.data
            ? element.data.search
              ? element.data.search.member_count == undefined
                ? ""
                : element.data.search.member_count
              : ""
            : "",
          coveragePeriod: element.data
            ? element.data.search
              ? element.data.search.coverage_period == undefined
                ? ""
                : element.data.search.coverage_period
              : ""
            : "",
          status: element.data
            ? element.data.search
              ? element.data.search.nstp_status == undefined
                ? ""
                : element.data.search.nstp_status
              : ""
            : "",
          //quotationNo: element.data?element.data.search?element.data.search.quote_no,
          //proposal_date:element?.c_ts:"":"",
        };
      }

      if (
        this.finalcardstatus == "draft" &&
        this.selectedLobValue != "lta" &&
        this.selectedLobValue != "travel"
      ) {
        this.obj = {
          //name: element ?.data ?.search ?.proposer_title + " " + element ?.data ?.search ?.customer_name,
          name:
            (element.data
              ? element.data.search
                ? element.data.search.proposer_title
                  ? element.data.search.proposer_title
                  : ""
                : ""
              : "") +
            " " +
            (element.data
              ? element.data.search
                ? element.data.search.customer_name
                  ? element.data.search.customer_name
                  : ""
                : ""
              : ""),

          email: element.data
            ? element.data.search
              ? element.data.search.customer_email
                ? element.data.search.customer_email
                : ""
              : ""
            : "",
          created_by: element.created_by ? element.created_by : "",
          mobile: element.data
            ? element.data.search
              ? element.data.search.customer_mobileno
                ? element.data.search.customer_mobileno
                : ""
              : ""
            : "",
          product_name: element.data
            ? element.data.search
              ? element.data.search.product_name
                ? element.data.search.product_name
                : ""
              : ""
            : "",
          premium: element.data
            ? element.data.search
              ? element.data.search.premium
                ? element.data.search.premium
                : ""
              : ""
            : "",
          // application_date: element.data
          //   ? element.data.search
          //     ? element.data.search.application_date
          //     : ""
          //   : "",
          quoteId: element.quote_id ? element.quote_id : "",
          quoteDate: element.quote
            ? element.quote
              ? element.quote.quote_date
                ? element.quote.quote_date
                : ""
              : ""
            : "",
          status: element.data
            ? element.data.search
              ? element.data.search.nstp_status == undefined
                ? ""
                : element.data.search.nstp_status
              : ""
            : "",
        };
      }

      if (
        this.finalcardstatus == "quote" &&
        this.selectedLobValue != "lta" &&
        this.selectedLobValue != "travel"
      ) {
        this.obj = {
          //name: element ?.data ?.search ?.proposer_title + " " + element ?.data ?.search ?.customer_name,
          name:
            (element?.data?.search?.proposer_title
              ? element?.data?.search?.proposer_title
              : "") +
            " " +
            (element?.data?.search?.customer_name
              ? element?.data?.search?.customer_name
              : ""),
          email: element.data
            ? element.data.search
              ? element.data.search.customer_email
              : ""
            : "",
          created_by: element.created_by ? element.created_by : "",
          mobile: element.data
            ? element.data.search
              ? element.data.search.customer_mobileno
              : ""
            : "",
          product_name: element.data
            ? element.data.search
              ? element.data.search.product_name
              : ""
            : "",
          premium: element.data
            ? element.data.search
              ? element.data.search.premium
              : ""
            : "",
          // application_date: element.data
          //   ? element.data.search
          //     ? element.data.search.application_date
          //     : ""
          //   : "",
          quoteNo: element.quote_no
            ? element.quote_no
            : element.data
            ? element.data.search
              ? element.data.search.quote_no
              : ""
            : "",
          quoteId: element.quote_id ? element.quote_id : "",
          quoteDate: element.quote
            ? element.quote
              ? element.quote.quote_date
              : ""
            : "",
          status: element.data
            ? element.data.search
              ? element.data.search.nstp_status == undefined
                ? ""
                : element.data.search.nstp_status
              : ""
            : "",
        };
      }
      if (
        this.finalcardstatus == "policy" &&
        this.selectedLobValue != "lta" &&
        this.selectedLobValue != "travel"
      ) {
        this.obj = {
          //name: element ?.data ?.search ?.proposer_title + " " + element ?.data ?.search ?.customer_name,
          name:
            (element?.data?.search?.proposer_title
              ? element?.data?.search?.proposer_title
              : "") +
            " " +
            (element?.data?.search?.customer_name
              ? element?.data?.search?.customer_name
              : ""),
          email: element.data
            ? element.data.search
              ? element.data.search.customer_email
              : ""
            : "",
          created_by: element.created_by ? element.created_by : "",
          mobile: element.data
            ? element.data.search
              ? element.data.search.customer_mobileno
              : ""
            : "",
          product_name: element.data
            ? element.data.search
              ? element.data.search.product_name
              : ""
            : "",
          premium: element.data
            ? element.data.search
              ? element.data.search.premium
              : ""
            : "",
          // application_date: element.data
          //   ? element.data.search
          //     ? element.data.search.application_date
          //     : ""
          //   : "",
          policyNo: element.policy_no ? element.policy_no : "",
          // policyId: element.data
          //   ? element.data.search
          //     ? element.data.search.policy_id
          //     : ""
          //   : "",
          policyId: element.policy_id
            ? element.policy_id
            : element?.data?.search?.policy_id
            ? element?.data?.search?.policy_id
            : "",
          policyStartDate: element.policy_start_date
            ? element.policy_start_date
            : "",
          policyEndDate: element.policy_end_date ? element.policy_end_date : "",
          //quotationNo: element.data?element.data.search?element.data.search.quote_no,
          policyDate: element.quote
            ? element.quote
              ? element.quote.quote_date
              : ""
            : "",
          status: element.data
            ? element.data.search
              ? element.data.search.nstp_status == undefined
                ? ""
                : element.data.search.nstp_status
              : ""
            : "",
        };
      }
      if (
        this.finalcardstatus == "proposal" &&
        this.selectedLobValue != "lta" &&
        this.selectedLobValue != "travel"
      ) {
        this.obj = {
          // name: element ?.data ?.search ?.proposer_title + " " + element ?.data ?.search ?.customer_name,
          name:
            (element?.data?.search?.proposer_title
              ? element?.data?.search?.proposer_title
              : "") +
            " " +
            (element?.data?.search?.customer_name
              ? element?.data?.search?.customer_name
              : ""),
          mobile: element.data
            ? element.data.search
              ? element.data.search.customer_mobileno
              : ""
            : "",
            created_by: element.created_by ? element.created_by : "",
          product_name: element.data
            ? element.data.search
              ? element.data.search.product_name
              : ""
            : "",
          premium: element.data
            ? element.data.search
              ? element.data.search.premium
              : ""
            : "",
          // application_date: element.data
          //   ? element.data.search
          //     ? element.data.search.application_date
          //     : ""
          //   : "",
          proposalNo: element.proposal_no ? element.proposal_no : "",
          proposalId: element.proposal_id
            ? element.proposal_id
            : element.data
            ? element.data.search
              ? element.data.search.proposal_id
                ? element.data.search.proposal_id
                : ""
              : ""
            : "",
          status: element.data
            ? element.data.search
              ? element.data.search.nstp_status == undefined
                ? ""
                : element.data.search.nstp_status
              : ""
            : "",
          //quotationNo: element.data?element.data.search?element.data.search.quote_no,
          //proposal_date:element?.c_ts:"":"",
        };
      }
      if (
        this.service.non_rater_product_group_id.includes(
          element.product_group_id
        ) &&
        (this.finalcardstatus == "quote" || this.finalcardstatus == "draft")
      ) {
        this.obj = {
          //name: element ?.data ?.search ?.proposer_title + " " + element ?.data ?.search ?.customer_name,
          name:
            (element.data
              ? element.data.search
                ? element.data.search.proposer_title
                  ? element.data.search.proposer_title
                  : ""
                : ""
              : "") +
            " " +
            (element.data
              ? element.data.search
                ? element.data.search.customer_name
                  ? element.data.search.customer_name
                  : ""
                : ""
              : ""),

          email: element.data
            ? element.data.search
              ? element.data.search.customer_email
                ? element.data.search.customer_email
                : ""
              : ""
            : "",
          created_by: element.created_by ? element.created_by : "",
          mobile: element.data
            ? element.data.search
              ? element.data.search.customer_mobileno
                ? element.data.search.customer_mobileno
                : ""
              : ""
            : "",
          product_name: element.data
            ? element.data.search
              ? element.data.search.product_name
                ? element.data.search.product_name
                : ""
              : ""
            : "",
          premium: element.data
            ? element.data.search
              ? element.data.search.premium
                ? element.data.search.premium
                : ""
              : ""
            : "",
          quoteId: element.cust_fld_1 ? element.cust_fld_1 : "",
          quoteDate: element.quote
            ? element.quote
              ? element.quote.quote_date
                ? element.quote.quote_date
                : ""
              : ""
            : "",
          status: element.data
            ? element.data.search
              ? element.data.search.nstp_status == undefined
                ? ""
                : element.data.search.nstp_status
              : ""
            : "",
        };
      }
      quoteData.push(this.obj);
    });
    // console.log(quoteData);
    // if(quoteData.length != 0){
    //   return true;
    // }
    // else{
    //   return false;
    // }
    if (quoteData.length != 0) {
      var newArry: any = [];
      for (let i = 0; i < quoteData.length; i++) {
        var o;
        if (
          newArry.length == 0 ||
          newArry.length < Object.keys(quoteData[i]).length
        ) {
          newArry = [];
          for (o in quoteData[i]) {
            newArry.push(o);
          }
        }
      }
      this.array.push(newArry);
      let finalarr = [];
      quoteData.forEach((element) => {
        let obj: Object = {};
        for (let i = 0; i < this.array[0]?.length; i++) {
          obj[this.array[0][i]] = element[this.array[0][i]]
            ? element[this.array[0][i]]
            : "-";
        }
        finalarr.push(obj);
      });
      for (let i = 0; i < finalarr.length; i++) {
        this.array.push(Object.values(finalarr[i]));
      }
      var CsvString = "";
      this.array.forEach((RowItem: any, RowIndex: any) => {
        RowItem.forEach((colItem: any, colIndex: any) => {
          CsvString += colItem + ",";
        });
        CsvString += "\r\n";
      });
      CsvString = "data:application/csv, " + encodeURIComponent(CsvString);
      var x = document.createElement("A");
      x.setAttribute("href", CsvString);
      const fileName =
        this.selectedLobValue +
        "-" +
        "data" +
        "-" +
        this.finalcardstatus +
        ".csv";
      x.setAttribute("download", fileName);

      document.body.appendChild(x);
      x.click();
      this.array = [];
    }
  }

  profileApi(resolve) {
    var token =
      sessionStorage.getItem("impersonate") == "true"
        ? sessionStorage.getItem("auth-token")
        : sessionStorage.getItem("in-auth-token");
    this.service.custom_profile(token).subscribe(
      (response) => {
        if (response.status === 0) {
          resolve(response.data);
        } else {
          this.swalService.error(response.txt);
        }
      },
      (err) => {
        this.swalService.error("Server Timeout");
      }
    );
  }
  pageChanged(eve) {
    this.page = eve;
  }

  async policyApi(policy_id, policyresolver) {
    this.service.policyService(policy_id).subscribe(
      (response) => {
        if (response.status === 0) {
          policyresolver(response.data);
        } else {
          this.swalService.error(response.txt);
        }
      },
      (err) => {
        this.swalService.error("Server Timeout");
      }
    );
  }

  ButtonClick(policy_no, event, searchObject) {
    event.stopPropagation();

    this.checkStatus(policy_no, searchObject);
  }

  checkStatus(policy, searchObject) {
    let statusValue: string;
    let res: any;
    var body = {
      "in-auth-token": sessionStorage.getItem("in-auth-token"),
    };
    this.service.getPolicyStatus(body, policy).subscribe(
      (response: any) => {
        if (response?.status === 0) {
          res = response;
          statusValue = res.data?.policy_cancel_status?.cancelled;
          statusValue == "true"
            ? (searchObject["iscancelled"] = true)
            : (searchObject["iscancelled"] = false);
          searchObject["buttonClick"] = true;
        } else {
          searchObject["buttonClick"] = false;
          Swal.fire(
            this.translate.instant("staticTs.oops"),
            this.translate.instant("staticTs.error")
          );
        }
      },
      (err) => {
        this.swalService.error("Server Timeout");
      }
    );
  }

  grid() {
    this.gridItem = true;
    this.listItem = false;
    this.page = 1;
  }
  list() {
    this.listItem = true;
    this.gridItem = false;
    this.page = 1;
  }

  initializeForm() {
    this.contactDetailForm = this.formBuilder.group({
      salutation: ["Mr"],
      customerEmail: [
        "",
        [Validators.pattern(REGEX.email), Validators.required],
      ],
    });
  }

  async downloadPolicy({ data, policy_id, pdf_date }) {
    const url = location.protocol + "//" + this.urlValue;
    if ((data.pdf_date || pdf_date?.length > 0) && pdf_date != "null") {
      // pdf date logic for CV products
      return await this.service.downloadPolicy(url, policy_id);
    }
    return Swal.fire(this.translate.instant("staticTs.policyNotReady"));
  }

  navigateToProduct(data, event) {
    event.stopPropagation();
    this.spinner.show();
    this.service.productPanel().subscribe(
      (response) => {
        if (response.status === 0) {
          const productId = data.product_id;
          if (response.data.length === 0) {
            this.swalService.error("No Product Panels Listed!");
          } else {
            var productpaneldata = response.data.filter(
              (obj) => obj.name === productId
            );
            if (productpaneldata.length === 0) {
              this.swalService.error(
                "No Product Panel Available for this Product!"
              );
            } else {
              sessionStorage.setItem("product", productpaneldata[0].value);
              sessionStorage.setItem("product_id", productId);

              const { origin } = window.location;
              this.spinner.hide();

              if (window.location.origin?.includes("localhost")) {
                window.location.href = `https://uatipds1.cloware.in/${productpaneldata[0].value}/#/clone?quote_id=${data.quote_id}`;
              } else {
                window.location.href = `${origin}/${productpaneldata[0].value}/#/clone?quote_id=${data.quote_id}`;
              }
            }
          }
        } else if (response.status === -114) {
          this.swalService.error(response.txt);
          sessionStorage.clear();
          this.router.navigateByUrl(`/login`);
        } else {
          this.swalService.error(response.txt);
        }

        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.swalService.error("Server Timeout");
      }
    );
  }

  async cloneData(data) {
    // if (this.lobValue === 'Motor') {
    //   await this.service.clone({ quoteId: data?.quote_id })?.subscribe((val: any) => {
    //     if (val?.data) {
    //       const res = val?.data[0];
    //       this.service.saveClonedData(res?.product_id, res?.quote_id);
    //     }
    //   })
    // }
  }
  openContactModal(template: TemplateRef<any>, { quote_id }) {
    // this.service.getDetailByQuoteId(quote_id).subscribe((result: any) => {
    //   this.quoteRes = result?.data[0];
    //   this.initializeForm();
    //   this.contactModalRef = this.modalService.show(
    //     contact_detail_template,
    //     this.config
    //   );
    // })
  }

  openShareModal(template: TemplateRef<any>, data) {
    this.contactDetailForm
      .get("customerEmail")
      .setValue(data.data.search.customer_email);
    this.product_id = data.product_id;
    this.policy_id = data.policy_id;
    this.shareModelRef = this.modalService.show(template, this.config);
  }

  async shareProposal(success_template: TemplateRef<any>) {
    // this.contactModalRef.hide();
    // this.service.finalizeQuote(this.quoteRes?.quote_id)?.subscribe((result) => {
    //   this.service.shareProposal(this.quoteRes?.policy_id);
    //   const {customer_name, product_group_id, lname_proposer,nstpStatus, customer_email, customer_mobileno, premium, product_name  } = result?.data[0]?.data.search;
    //   const fullName = `${customer_name } ${lname_proposer} `
    //   const body = {
    //     search: {
    //       customer_name:fullName || '',
    //       customer_email: customer_email || '',
    //       customer_mobileno: customer_mobileno || '',
    //       premium: premium || '',
    //       product_name: product_name || '',
    //       location: sessionStorage.getItem('location') || '',
    //       channel: sessionStorage.getItem('channel') || '',
    //     },
    //     product_nstp_status: nstpStatus || '',
    //     office_location_code: sessionStorage.getItem('location_code') || 0,
    //     premium: premium || '',
    //     product_name: product_name || '',
    //     product_group_id: product_group_id || '',
    //     policy_period: result[0]?.data.search?.riskDetails?.policyPeriodMonths || '',
    //     agent_location_code: sessionStorage.getItem('location_code') || '',
    //     value_of_risk: result[0]?.data.search?.productDetails?.valueOfTheRisk,
    //   };
    //   this.service.setPolicyMeta(body,this.quoteRes.policy_id)?.subscribe(() => {
    //     this.successModalRef = this.modalService.show(
    //       success_template,
    //       this.config
    //     );
    //   })
    // })
  }

  continue() {
    this.successModalRef.hide();
  }

  onReceiptCheckChange() {
    this.submit = false;
    this.receiptSearchBtn = !this.receiptSearchBtn;
    this.cardlist = [];
    if (this.receiptSearchBtn) {
      this.model.searchby = this.model.max = this.model.min = "";
    }
    // this.submit = false;
    // if()
    this.finalcardstatusmsg = "";
  }

  delete(item) {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to delete this Policy ?",
      text: item.policy_id,
      showCancelButton: true,
      showConfirmButton: true,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: `Delete`,
    }).then((result) => {
      if (result.isConfirmed) {
        let dataurl =
          location.protocol +
          "//" +
          this.urlValue +
          "/api/v1/ipds_payment/payment_cancel?payment_details_id=" +
          item.payment_details_id;
        this.service.receiptDelete(dataurl).subscribe((response) => {
          if (response.status == 0) {
            Swal.fire("Policy Canceled!", item.policy_id, "success");
            this.search(0, true);
          } else if (response.status == -114) {
            this.swalService.error(response.txt);
            sessionStorage.clear();
            this.router.navigateByUrl(`/login`);
          } else {
            Swal.fire(this.translate.instant("staticTs.oops") + "server error");
          }
        });
      }
    });
  }

  onChangeCertificatePolicy() {
    for (let i = 0; i < this.selectedValList.length; i++) {
      if (this.selectedValList[i].value == "Certificate No") {
        this.selectedType = this.selectedValList[i].value;
      }
    }
  }
  sendEmail(data): Observable<any> {
    return this.http.post(
      location.protocol +
        "//" +
        this.urlValue +
        "/api/v1/generic_api/send_email",
      data,
      { headers: new HttpHeaders(this.service.newheaders) }
    );
  }

  /**
   * @description Send email
   */
  emailSend() {
    this.sendEmail({
      product_id: this.product_id,
      policy_id: this.policy_id,
      email_id: this.contactDetailForm.value.customerEmail,
      attachment_type: this.selectedStageVal.toLowerCase(),
    }).subscribe((response) => {
      if (response.status === 0) {
        this.shareModelRef.hide();
        this.swalService.success(
          this.translate.instant("staticTs.emailSentTo") +
            this.contactDetailForm.value.customerEmail
        );
      } else {
        this.shareModelRef.hide();
        this.swalService.error(response.txt);
      }
    });
  }

  /**
   *
   * @param event
   * @param param "left" "right"
   * @description used to scroll filter div from left to right or vice versa
   */
  scrollDiv(event, param) {
    event.stopPropagation();
    const scroll = document.querySelector(".scroll");

    if (param === "left") {
      scroll.scrollBy(-200, 0);
    } else if (param === "right") {
      scroll.scrollBy(200, 0);
    }
  }

  /** Clear All filters */
  clear() {
    this.displayproductName = [];
    this.businessTypeName = [];
    this.modelpop = {};
    this.modelpop.quotestatus = [];
    this.modelpop.gcchannel = [];
    this.modelpop.gclocation = [];
    this.disableVarient = false;
    this.showSubTransactionType = false;
    this.sortOrder = "";
    this.productCategory = "";
    this.quotes_stat = "";
    this.memberisEmployee = "";
    this.planType = "";
    this.tenure = "";
    this.varient = "";
    this.businessType = "";
    this.filterLocation = "";
    this.subtractionType = "";
    this.netPremium = "";
    this.sumInsuredFrom = "";
    this.sumInsuredTo = "";
    this.gcChannel = [];
    this.gcLocation = "";
    this.ltaBundleType = "";
    this.ltaPlanType = "";
    this.motorBundleType = "";
    this.motorvehicleType = "";
    this.customerType = "";
    this.insDesFrom = "";
    this.insDecTo = "";
    this.btaPlanType = "";
    this.producerCode = "";
    this.smeStatus = "";
    this.doeFilter = "";

    if (this.showFilterDiv) this.filter();
    this.showFilterDiv = false;
  }

  async getRoleName() {
    return new Promise((resolve, reject) => {
      this.service.getRolename({ role_id: this.role_id }).subscribe(
        (roleresponse) => {
          if (roleresponse.status === 0) {
            this.currentrole = roleresponse.data[0].role_name;
            sessionStorage.setItem("loginrole", roleresponse.data[0].role_name);
            this.service.loginrole = sessionStorage.getItem("loginrole");
            resolve(true);
          } else if (roleresponse.status === -114) {
            resolve(true);
            this.swalService.error(roleresponse.txt);
            sessionStorage.clear();
            this.router.navigateByUrl(`/login`);
          } else {
            resolve(true);
            this.swalService.error(roleresponse.txt);
            this.loading = false;
            this.spinner.hide();
          }
        },
        (err) => {
          resolve(true);
          this.loading = false;
          this.spinner.hide();
          this.swalService.error("Server Timeout");
        }
      );
    });
  }

  onStartDateSelect() {
    let todayDate = new Date();
    let selectedStartDate = new Date(
      this.model.ad_startDate.year,
      this.model.ad_startDate.month - 1,
      this.model.ad_startDate.day
    );
    let endDate = new Date(
      moment(selectedStartDate).add(34, "days").format("YYYY-MM-DD")
    );

    let time_difference = todayDate.getTime() - selectedStartDate.getTime();
    let days_difference = time_difference / (1000 * 60 * 60 * 24);
    if (days_difference > 35) {
      this.endMaxDate = {
        day: endDate.getDate(),
        month: endDate.getMonth() + 1,
        year: endDate.getFullYear(),
      };
    } else {
      this.endMaxDate = {
        day: todayDate.getDate(),
        month: todayDate.getMonth() + 1,
        year: todayDate.getFullYear(),
      };
    }
    let time_difference1 = todayDate.getTime() - selectedStartDate.getTime();
    let days_difference1 = time_difference1 / (1000 * 60 * 60 * 24);
    if (days_difference1 < 4) {
      this.model.ad_endDate = {
        day: todayDate.getDate(),
        month: todayDate.getMonth() + 1,
        year: todayDate.getFullYear(),
      };
    } else {
      let threeDays = new Date(
        moment(selectedStartDate).add(3, "days").format("YYYY-MM-DD")
      );
      this.model.ad_endDate = {
        day: threeDays.getDate(),
        month: threeDays.getMonth() + 1,
        year: threeDays.getFullYear(),
      };
    }
    // this.model.ad_endDate = this.endMaxDate;
  }
  removeFilter(removeFilter) {
    if (this.modelpop?.product_id && this.modelpop.product_id.length > 0) {
      this.modelpop.product_id = this.modelpop.product_id.filter(
        (item) => item.product_name !== removeFilter
      );
      this.displayproductName = this.displayproductName.filter(
        (name) => name !== removeFilter
      );
      this.productCategory = this.productCategory.filter(
        (item) => item.product_name !== removeFilter
      );
    }

    if (
      this.modelpop?.business_type &&
      this.modelpop?.business_type?.length > 0
    ) {
      this.modelpop.business_type = this.modelpop.business_type.filter(
        (item) => item.value !== removeFilter
      );
      this.businessTypeName = this.businessTypeName.filter(
        (name) => name !== removeFilter
      );
    }

    if (
      this.modelpop.quotestatus != undefined &&
      this.modelpop.quotestatus?.length !== 0
    ) {
      this.quotes_stat = this.quotes_stat.filter(
        (item) => item.itemName !== removeFilter
      );
      this.modelpop.quotestatus = this.modelpop.quotestatus.filter(
        (item) => item.itemName !== removeFilter
      );
    }

    if (
      this.modelpop?.gcchannel != undefined &&
      this.modelpop.gcchannel?.length !== 0
    ) {
      this.modelpop.gcchannel = this.modelpop.gcchannel.filter(
        (item) => item.channel_type !== removeFilter
      );
    }

    if (
      this.modelpop?.gclocation != undefined &&
      this.modelpop?.gclocation?.length !== 0
    ) {
      this.modelpop.gclocation = this.modelpop.gclocation.filter(
        (item) => item.name !== removeFilter
      );
    }

    if (this.modelpop.sort === removeFilter) {
      this.modelpop.sort = "";
      this.sortOrder = "";
    }
    if (this.modelpop.member_is_employee === removeFilter) {
      this.memberisEmployee = "";
      this.modelpop.member_is_employee = "";
    }
    if (this.modelpop.plan_type === removeFilter) {
      this.planType = "";
      this.modelpop.plan_type = null;
    }

    if (this.modelpop.tenure === removeFilter) {
      this.tenure = "";
      this.modelpop.tenure = null;
    }
    if (this.modelpop.varient === removeFilter) {
      this.varient = "";
      this.modelpop.varient = null;
    }

    if (this.modelpop.location === removeFilter) {
      this.filterLocation = "";
      this.modelpop.location = "";
    }

    if (this.modelpop.location === removeFilter) {
      this.filterLocation = "";
      this.modelpop.location = "";
    }

    if (this.modelpop.subTransactionType === removeFilter) {
      this.subtractionType = "";
      this.modelpop.subTransactionType = "";
    }

    if (this.modelpop.netpremium === removeFilter) {
      this.netPremium = "";
      this.modelpop.netpremium = "";
    }

    if (this.modelpop.sum_insured_from === removeFilter) {
      this.sumInsuredFrom = "";
      this.modelpop.sum_insured_from = "";
    }

    if (this.modelpop.sum_insured_to === removeFilter) {
      this.sumInsuredTo = "";
      this.modelpop.sum_insured_to = "";
    }

    if (this.modelpop.lta_bundle_type === removeFilter) {
      this.ltaBundleType = "";
      this.modelpop.lta_bundle_type = "";
    }

    if (this.modelpop.doeFilter === removeFilter) {
      this.doeFilter = "";
      this.modelpop.doeFilter = null;
    }

    if (this.modelpop.lta_plan_type === removeFilter) {
      this.ltaPlanType = "";
      this.modelpop.lta_plan_type = "";
    }

    if (this.modelpop.motor_bundle_type === removeFilter) {
      this.motorBundleType = "";
      this.modelpop.motor_bundle_type = null;
    }

    if (this.modelpop.motor_vehicle_status === removeFilter) {
      this.motorvehicleType = "";
      this.modelpop.motor_vehicle_status = null;
    }

    if (this.modelpop.customer_type === removeFilter) {
      this.customerType = "";
      this.modelpop.customer_type = "";
    }

    if (this.modelpop.ins_dec_from === removeFilter) {
      this.insDesFrom = "";
      this.modelpop.ins_dec_from = "";
    }

    if (this.modelpop.ins_dec_to === removeFilter) {
      this.insDecTo = "";
      this.modelpop.ins_dec_to = "";
    }

    if (this.modelpop.bta_plan_type === removeFilter) {
      this.btaPlanType = "";
      this.modelpop.bta_plan_type = "";
    }

    if (this.modelpop.smestatus === removeFilter) {
      this.smeStatus = "";
      this.modelpop.smestatus = "";
    }
    this.filter();
  }

  subTransactionTypeDropdown(lob) {
    return new Promise((resolve, reject) => {
      if (this.subTransactionTypes?.length == 0) {
        this.service.subTransctionType(lob).subscribe((response) => {
          this.subTransactionTypes = response?.data;
        });
      }
      resolve(this.subTransactionTypes);
    });
  }

  addedToCartCheck(tt) {
    if(tt.stage) {
      return tt.cart_id !==  '' && tt.stage.toLowerCase() == "payment";
    }
    
    return false;
  }

  nextPage() {
    this.pageIndex += 50;
    this.search(this.pageIndex, false);
  }
  
  previousPage() {
    if (this.pageIndex >= 50) {
      this.pageIndex -= 50;
      this.search(this.pageIndex, false);
    }
  }
}
