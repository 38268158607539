<div class="theme-container">
  <div class="page-title">
    <div class="nav-btn" (click)="back()">
      <svg>
        <use xlink:href="#ICON_Arrow" />
      </svg>
    </div>
    <div class="w-100">
      <div class="page-heading-container">
        <h3 class="head-lg heading text-uppercase">
          {{ "combo.lob.label" | translate }}
        </h3>
      </div>
      <div class="breadcrum-container">
        <ul class="breadcrum">
          <li class="head-sm" (click)="globals.goBackToAllApplication()">
            {{ "combo.allProducts.label" | translate }}
          </li>
          <li class="head-sm">{{ "combo.lob.label" | translate }}</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="flex-box">
    <div class="row">
      <div class="col-12">
        <div class="section-heading text-uppercase">
          {{ "combo.choose.label" | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="flex-box">
    <div class="theme-card">
      <form class="theme-form" [formGroup]="comboForm">
        <div class="row">
          <div class="col-md-6 col-lg-4 col-xl-3 mb-5">
            <div class="card-content">
              <div class="row">
                <div class="form-field col-12">
                  <label for="landlineNumber" class="head-sm required-field">
                    {{ "combo.selectLob.label" | translate }}
                  </label>
                  <div role="listbox">
                    <ng-select
                      formControlName="lob1"
                      [(ngModel)]="selectedLob1"
                      class="ngSelectOption"
                      placeholder="{{ 'combo.select.placeholder' | translate }}"
                      (change)="onLobChange($event, 'card1')"
                    >
                      <ng-option
                        *ngFor="let data of lobData1; let i = index"
                        [value]="data?.lob_value"
                      >
                        {{ data?.lob }}</ng-option
                      >
                    </ng-select>
                  </div>
                  <span
                    class="error-msg"
                    *ngIf="
                      comboForm.controls['lob1'].invalid &&
                      (comboForm.controls['lob1'].dirty ||
                        comboForm.controls['lob1'].touched)
                    "
                  >
                    {{ "combo.lob.required" | translate }}
                  </span>
                </div>
                <div class="form-field col-12">
                  <label for="landlineNumber" class="head-sm required-field">
                    {{ "combo.selectProduct.label" | translate }}
                  </label>
                  <div role="listbox">
                    <ng-select
                      formControlName="product1"
                      [(ngModel)]="selectedproduct1"
                      class="ngSelectOption"
                      placeholder="{{ 'combo.select.placeholder' | translate }}"
                    >
                      <ng-option
                        *ngFor="let data of product1; let i = index"
                        [value]="data?.value"
                      >
                        {{ data?.name }}</ng-option
                      >
                    </ng-select>
                  </div>
                  <span
                    class="error-msg"
                    *ngIf="
                      comboForm.controls['product1'].invalid &&
                      (comboForm.controls['product1'].dirty ||
                        comboForm.controls['product1'].touched)
                    "
                  >
                    {{ "combo.product.required" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 col-xl-3 mb-5">
            <div class="card-content">
              <div class="row">
                <div class="form-field col-12">
                  <label for="landlineNumber" class="head-sm required-field">
                    {{ "combo.selectLob.label" | translate }}
                  </label>
                  <div role="listbox">
                    <ng-select
                      class="ngSelectOption"
                      [(ngModel)]="selectedLob2"
                      placeholder="{{ 'combo.select.placeholder' | translate }}"
                      formControlName="lob2"
                      (change)="onLobChange($event, 'card2')"
                    >
                      <ng-option
                        *ngFor="let data of lobData2; let i = index"
                        [value]="data?.lob_value"
                      >
                        {{ data?.lob }}</ng-option
                      >
                    </ng-select>
                  </div>
                  <span
                    class="error-msg"
                    *ngIf="
                      comboForm.controls['lob2'].invalid &&
                      (comboForm.controls['lob2'].dirty ||
                        comboForm.controls['lob2'].touched)
                    "
                  >
                    {{ "combo.lob.required" | translate }}
                  </span>
                </div>
                <div class="form-field col-12">
                  <label for="landlineNumber" class="head-sm required-field">
                    {{ "combo.selectProduct.label" | translate }}
                  </label>
                  <div role="listbox">
                    <ng-select
                      class="ngSelectOption"
                      [(ngModel)]="selectedproduct2"
                      formControlName="product2"
                      placeholder="{{ 'combo.select.placeholder' | translate }}"
                    >
                      <ng-option
                        *ngFor="let data of product2; let i = index"
                        [value]="data?.value"
                      >
                        {{ data?.name }}</ng-option
                      >
                    </ng-select>
                  </div>
                  <span
                    class="error-msg"
                    *ngIf="
                      comboForm.controls['product2'].invalid &&
                      (comboForm.controls['product2'].dirty ||
                        comboForm.controls['product2'].touched)
                    "
                  >
                    {{ "combo.product.required" | translate }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="">
    <button
      type="submit"
      class="btn primary-btn theme-lg-btn active"
      (click)="createQuote()"
    >
      {{ "combo.proceed.btn" | translate }}
    </button>
  </div>
</div>
