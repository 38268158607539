import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { Router } from "@angular/router";
@Injectable()
export class CanActivateGuardService implements CanActivate {
  constructor(public router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const authToken = sessionStorage.getItem("auth-token");
    const inAuthToken = sessionStorage.getItem("in-auth-token");

    if (authToken?.length > 0 || inAuthToken?.length > 0) {
      return true;
    } else if (route.queryParams.ctoken) {
      return true;
    } else {
      sessionStorage.clear();
      const { origin } = window.location;
      const href = origin.indexOf("localhost") === -1 ? "/ipdsv2/login" : "/";
      if (href == "/") {
        window.location.href = `http://localhost:4201/#/login`;
      } else {
        window.location.href = `${origin}${href}#login`;
      }
      return false;
    }
  }
}
